import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";

export const UploadNews = () => {
  const [nameV, setNameV] = useState("");
  const [disV, setDisV] = useState("");
  const [rdV, setRdV] = useState("");
  const [selectedtype, setSelectedtype] = useState("");
  const [selectedtype1, setSelectedtype1] = useState("");
  const [auV, setAuV] = useState("");
  const [linkV, setLinkV] = useState("");
  const [htV, setHtV] = useState("");
  const [imgV, setImgV] = useState("");
  const [content, setContent] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null); // New state for image preview
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [isRaw, setIsRaw] = useState(false);

  const handleRawContentChange = (e) => {
    setEditorContent(e.target.value); // Update raw HTML content
  };
  const uploadFile = async () => {
    if (!imageUpload) {
      alert("No file selected for upload.");
      return;
    }

    try {
      const imageRef = ref(storage, `News/${imageUpload.name}`);
      const snapshot = await uploadBytes(imageRef, imageUpload); // Upload the file

      const url = await getDownloadURL(snapshot.ref); // Get the download URL
      console.log("Uploaded Image URL:", url); // Log the URL for debugging
      setImgV(url); // Update the state with the uploaded image URL

      // Return the URL for further processing
      return url;
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload image. Please try again.");
      throw error;
    }
  };

  const dataRetriever = async () => {
    if (selectedtype === "none") {
      alert("Төрөл сонгоогүй байна!");
      return;
    }

    try {
      const uploadedImageUrl = await uploadFile(); // Wait for the upload to finish

      if (!uploadedImageUrl) {
        alert("Image upload failed. Please try again.");
        return;
      }

      const response = await axios({
        url: `https://tmull-be.vercel.app/news`,
        method: "POST",
        data: {
          au: auV,
          ti: nameV,
          pl: linkV,
          di: disV,
          rt: rdV,
          cou: selectedtype,
          ca: selectedtype1,
          ht: htV,
          img: uploadedImageUrl.split("News%2F")[1],
          con: `<div class="preview-content text-justify xl:text-lg w-full lg:w-4/5 xl:w-3/5">${editorContent}</div>`,
        },
      });

      // Handle the response
      if (response?.data === "successfully created new News") {
        alert("Successfully created new News");
        window.location.href = "/niitleluud";
      } else {
        alert("Error creating news.");
      }
    } catch (error) {
      console.error("Error in data retrieval or upload:", error);
      alert("There was an error processing your request.");
    }
  };

  const imagesListRef = ref(storage, "Events/");

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageUpload(file);
      setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
    }
  };

  const handleChange = (content, delta, source, editor) => {
    setEditorContent(content);
  };

  const toolbarOptions = [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "image", "blockquote", "code-block"],
    ["clean"], // Remove formatting button
  ];

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "link",
    "image",
    "blockquote",
    "code-block",
  ];

  const modules = {
    toolbar: {
      container: toolbarOptions,
    },
  };

  useEffect(() => {
    document.title = "Tmull ・ Upload";
  }, []);

  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="w-full text-center text-3xl h-16">
        Нийтлэл нэмэх талбар
      </div>

      <form>
        <div class="grid gap-6 mb-6 md:grid-cols-2 w-full">
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нийтлэлийн нэр
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="БНСУ-д ямар ямар их сургуулиуд байгаа вэ?"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нийтлэлийн тайлбар
            </div>
            <input
              onChange={(e) => setDisV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="БНСУ гэхэд ихэнх хүмүүс SKY буюу SNU, KAIST, Yonsei зэрэг сургуулиудыг л мэддэг. Гэхдээ Солонгос улсад SKY-аас өөр олон олон салбартаа тэргүүлдэг их сургуулиуд байдаг гэдгийг та мэдэх үү? ..."
              required
            />
          </div>

          <div>
            <div
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Зохиолч
            </div>
            <textarea
              type="text"
              onChange={(e) => setAuV(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="О.Анужин
О.Анужин"
              required
            ></textarea>
          </div>
          <div>
            <div
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Hashtag
            </div>
            <textarea
              onChange={(e) => setHtV(e.target.value.split("\n"))}
              type="text"
              className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="universities
koreanuniversity
applying
living
studyingabroad
..."
            ></textarea>
          </div>
          <div>
            <div
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Унших хугацаа
            </div>
            <input
              type="text"
              onChange={(e) => setRdV(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="3 мин"
              required
            />
          </div>
          <div>
            <div
              for="country"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Улс
            </div>
            <select
              name="type"
              id="type"
              value={selectedtype}
              required
              onChange={(e) => setSelectedtype(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option value="none">none</option>
              <option value="Монгол">Монгол</option>
              <option value="АНУ">АНУ</option>
              <option value="Хятад">Хятад</option>
              <option value="Япон">Япон</option>
              <option value="Солонгос">Солонгос</option>
              <option value="Орос">Орос</option>
              <option value="Герман">Герман</option>
              <option value="Австрали">Австрали</option>
              <option value="Канад">Канад</option>
              <option value="Их Британи">Их Британи</option>
              <option value="Франц">Франц</option>
              <option value="Энэтхэг">Энэтхэг</option>
              <option value="Итали">Итали</option>
              <option value="Турк">Турк</option>
              <option value="Сингапур">Сингапур</option>
              <option value="Шинэ Зеланд">Шинэ Зеланд</option>
              <option value="Малайз">Малайз</option>
              <option value="Швед">Швед</option>
              <option value="Швейцарь">Швейцарь</option>
              <option value="Финлянд">Финлянд</option>
              <option value="Нидерланд">Нидерланд</option>
              <option value="Дани">Дани</option>
              <option value="Норвеги">Норвеги</option>
              <option value="Бельги">Бельги</option>
              <option value="Австри">Австри</option>
              <option value="Ирланд">Ирланд</option>
              <option value="Испани">Испани</option>
              <option value="Бразил">Бразил</option>
              <option value="Өмнөд Африк">Өмнөд Африк</option>
              <option value="Арабын Нэгдсэн Эмирт Улс">
                Арабын Нэгдсэн Эмирт Улс
              </option>
              <option value="Саудын Араб">Саудын Араб</option>
              <option value="Тайланд">Тайланд</option>
              <option value="Индонез">Индонез</option>
              <option value="Вьетнам">Вьетнам</option>
              <option value="Филиппин">Филиппин</option>
              <option value="Пакистан">Пакистан</option>
              <option value="Бангладеш">Бангладеш</option>
              <option value="Шри Ланка">Шри Ланка</option>
              <option value="Египет">Египет</option>
              <option value="Кени">Кени</option>
              <option value="Нигери">Нигери</option>
              <option value="Мексик">Мексик</option>
              <option value="Аргентин">Аргентин</option>
              <option value="Чили">Чили</option>
              <option value="Колумб">Колумб</option>
              <option value="Перу">Перу</option>
              <option value="Венесуэл">Венесуэл</option>
              <option value="Украйн">Украйн</option>
              <option value="Польш">Польш</option>
              <option value="Чех">Чех</option>
              <option value="Унгар">Унгар</option>
              <option value="Португал">Португал</option>
              <option value="Грек">Грек</option>
              <option value="Израиль">Израиль</option>
              <option value="Иран">Иран</option>
              <option value="Ирак">Ирак</option>
              <option value="Афганистан">Афганистан</option>
              <option value="Казахстан">Казахстан</option>
              <option value="Узбекистан">Узбекистан</option>
              <option value="Киргизстан">Киргизстан</option>
              <option value="Тажикстан">Тажикстан</option>
              <option value="Армени">Армени</option>
              <option value="Гүрж">Гүрж</option>
              <option value="Азербайжан">Азербайжан</option>
              <option value="Беларусь">Беларусь</option>
              <option value="Латви">Латви</option>
              <option value="Литва">Литва</option>
              <option value="Эстони">Эстони</option>
              <option value="Румын">Румын</option>
              <option value="Болгар">Болгар</option>
              <option value="Серби">Серби</option>
              <option value="Хорват">Хорват</option>
              <option value="Босни ба Херцеговина">Босни ба Херцеговина</option>
              <option value="Словени">Словени</option>
              <option value="Словак">Словак</option>
              <option value="Север Македони">Север Македони</option>
              <option value="Албани">Албани</option>
              <option value="Кипр">Кипр</option>
              <option value="Исланд">Исланд</option>
              <option value="Мальта">Мальта</option>
              <option value="Люксембург">Люксембург</option>
              <option value="Лихтенштейн">Лихтенштейн</option>
              <option value="Монако">Монако</option>
              <option value="Андорра">Андорра</option>
              <option value="Сан-Марино">Сан-Марино</option>
              <option value="Ватикан">Ватикан</option>
              <option value="Катар">Катар</option>
              <option value="Кувейт">Кувейт</option>
              <option value="Оман">Оман</option>
              <option value="Бахрейн">Бахрейн</option>
              <option value="Ливан">Ливан</option>
            </select>
          </div>
          <div>
            <div
              for="country"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Категор
            </div>
            <select
              name="type"
              id="type"
              value={selectedtype1}
              required
              onChange={(e) => setSelectedtype1(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option value="none">none</option>
              <option value="Сургууль сонголт">Сургууль сонголт</option>
              <option value="Хувь хүний хөгжил">Хувь хүний хөгжил</option>
              <option value="Хэл сурах">Хэл сурах</option>
              <option value="Мэргэжлийн чиглэл">Мэргэжлийн чиглэл</option>
              <option value="Хичээллэх арга барилууд">
                Хичээллэх арга барилууд
              </option>
              <option value="Оюутны амьдрал">Оюутны амьдрал</option>
              <option value="Технологи">Технологи</option>
              <option value="Тэтгэлэг ба санхүүгийн дэмжлэг">
                Тэтгэлэг ба санхүүгийн дэмжлэг
              </option>
              <option value="Дадлага ба ажлын байрны боломжууд">
                Дадлага ба ажлын байрны боломжууд
              </option>
              <option value="Шалгалтанд бэлдэх">Шалгалтанд бэлдэх</option>
              <option value="Цагийн менежмент">Цагийн менежмент</option>
              <option value="Хичээлээс гадуурх үйл ажиллагаа">
                Хичээлээс гадуурх үйл ажиллагаа
              </option>
              <option value="Оюутны сэтгэцийн эрүүл мэнд">
                Сэтгэцийн эрүүл мэнд
              </option>
              <option value="Цахим сургалт">Цахим сургалт</option>
              <option value="Их, дээд сургуулийн үнэлгээ">
                Их, дээд сургуулийн үнэлгээ
              </option>
            </select>
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Холбоос
            </div>
            <input
              type="text"
              onChange={(e) => setLinkV(e.target.value)}
              class="bg-gray-50 disabled:opacity-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="kr-uni"
            />
          </div>
        </div>
        <div>
          <h2 class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Нийтлэл
          </h2>
          <div>
            <ReactQuill
              onChange={handleChange}
              modules={modules}
              formats={formats}
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              placeholder="Write something amazing..."
            />

            {/* Show raw HTML editor when isRaw is true */}
            <div
              onClick={() => {
                setIsRaw(!isRaw);
              }}
              class="block w-fit cursor-pointer text-sm text-white
       me-4 py-2 px-4
       rounded-lg border-0 font-semibold
   bg-gray-600 
        hover:bg-gray-700
        dark:bg-gray-600
        dark:hover:bg-gray-500
        my-4
      "
            >
              raw байдлаар засварлах
            </div>
            {isRaw ? (
              <div>
                <textarea
                  value={editorContent}
                  onChange={handleRawContentChange}
                  rows={10}
                  cols={80}
                  type="code"
                  className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* Live Preview Section */}
          <div className="w-full justify-center flex items-center flex-col">
            <h3 className="mt-4 p-2">Урьдчилж харах</h3>
            <div
              className="preview-content text-justify xl:text-lg w-full lg:w-4/5 xl:w-3/5"
              dangerouslySetInnerHTML={{ __html: editorContent }}
            />
          </div>

          {/* Live Preview Section */}
        </div>
        <div class="max-w-sm">
          <form>
            <div class="block">
              <input
                type="file"
                onChange={handleImageChange}
                class="block w-full text-sm text-gray-500
        file:me-4 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-semibold
        file:bg-green-600 file:text-white
        hover:file:bg-green-700
        file:disabled:opacity-50 file:disabled:pointer-events-none
        dark:text-neutral-500
        dark:file:bg-green-600
        dark:hover:file:bg-green-500
      "
              />
            </div>
          </form>
        </div>
        <p class="text-xs text- text-gray-700 dark:text-gray-300 mt-2">
          SVG, PNG, JPG or GIF (MIN. 1920x1080px)
        </p>
        <div>
          Preview
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Image Preview"
              className="w-full h-72 md:h-96 lg:w-[50%] lg:h-96 object-cover rounded-lg"
            />
          )}
        </div>
        <button
          class="block w-full text-sm text-white
       me-4 py-2 px-4
       rounded-lg border-0 font-semibold
   bg-green-600 
        hover:bg-green-700
        dark:bg-green-600
        dark:hover:bg-green-500
        mt-4
      "
          type={
            (nameV,
            imgV,
            disV,
            auV,
            linkV,
            rdV,
            htV,
            selectedtype,
            selectedtype1,
            editorContent) === ""
              ? "submit"
              : "button"
          }
          // type="submit"
          onClick={dataRetriever}
        >
          done
        </button>
      </form>
    </div>
  );
};
