import React from "react";
import { useState, useEffect } from "react";
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
import Goethe from "../assets/Goethe.png";
import jlpt from "../assets/jlpt.png";
import jlptdr from "../assets/jlptdr.png";
import hsk from "../assets/hskdr.png";
import hskdr from "../assets/hsk.png";
import availableb from "../assets/bolomjtoib.svg";
import unavailableb from "../assets/bolomjguib.svg";
import sbdbg from "../assets/sbd.png";
import sat from "../assets/sat.png";
import ielts from "../assets/ielts.png";
import topik from "../assets/topik.svg";
import topikdr from "../assets/topik-dr.png";
import ush from "../assets/ush.svg";
import esh from "../assets/esh.svg";
import oge from "../assets/oge.png";
import ege from "../assets/ege.png";
import tmuleh from "../assets/tmuleh.png";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
const Countdown = ({ targetDate }) => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const calculateDaysLeft = () => {
      const now = new Date();
      const target = new Date(targetDate);
      const difference = target - now; // Difference in milliseconds
      const days = Math.ceil(difference / (1000 * 60 * 60 * 24)); // Convert to days
      setDaysLeft(days);
    };

    calculateDaysLeft(); // Initial calculation

    const interval = setInterval(() => {
      calculateDaysLeft(); // Update daily
    }, 1000 * 60 * 60 * 24); // Recalculate every day

    return () => clearInterval(interval); // Cleanup on unmount
  }, [targetDate]);

  return <div>{daysLeft > 0 ? daysLeft : 0} хоног</div>;
};
export const Home = () => {
  const [qw, setQw] = useState(true);
  const [pos, setPos] = useState(0);
  const [acad, setAcad] = useState([]);
  const [news, setNews] = useState([]);
  const [prof, setProf] = useState([]);
  const [event, setEvent] = useState([]);
  const [school, setSchool] = useState([]);

  const [formattedDate, setFormattedDate] = useState("");
  const exams = [
    {
      img: "../assets/sat.png",
      n: "SAT",
      d: "SAT (Scholastic Assessment Test) бол АНУ-д коллеж болон их сургуульд элсэхэд өргөн хэрэглэгддэг стандартчилсан шалгалт юм. Зарим их дээд сургуулиуд бакалаврын элсэлт авахдаа шаарддаг.",
      c: [
        "SAT-ийн жишиг тестүүдийг байнга хийж дадлага хийх. Аль болох эртнээс бэлдэж эхлээрэй.",
        "Унших, бичих, математик дээр ижил анхаарал хандуулах.",
        "Өдөр бүр 1-ээс дээш шинэ үг цээжлээрэй.",
        "Шалгалтын цагийн менежментэд суралцах.",
        "Жил болгон Монголд 3,5,6,10,11,12 сард авдаг бөгөөд сарын сүүлээр маш хурдан суудал дүүрдэг учраас аль болох хавар өгчихвөл зүгээр.",
        "Та өөрийн мэдээлэл зөв эсэхийг давхар шалгах хэрэгтэй, эс бөгөөс таны нэрний нэг үсэг буруу байвал элсүүлэхгүй. Та мөнгөө буцааж авах боломжтой байж магадгүй юм.",
      ],
      w: [
        {
          n: "College Board",
          w: "https://www.collegeboard.org/",
          d: "College Board вэбсайт нь SAT болон AP тестийн бэлтгэл, их сургуулийн элсэлт, тэтгэлэг болон боловсролын нөөцийг санал болгодог. Bluebook аппликейшн (https://bluebook.app.collegeboard.org/) ашиглан оюутнууд SAT болон AP тестэд бэлтгэж, элсэлтийн тасалбар авч, тест өгч болно.",
        },
        {
          n: "Bluebook",
          w: "https://bluebook.app.collegeboard.org/",
          d: "Bluebook application-ыг ашиглан SAT болон AP-д бэлдэх, элсэлтийн тасалбар авах, тест өгөх боломжтой юм.",
        },
        {
          n: "Khan Academy",
          w: "https://www.khanacademy.org/test-prep/digital-sat",
          d: "Энэхүү веб хуудаснаас математик, дүрэм, шинжлэх ухаан, түүх, SAT, AP-ын гэх мэт мэдлэгийг үнэгүй авах боломжийг олгодог.",
        },
      ],
      t: [
        {
          Өдөр: "Жилд 7 удаа (3, 5, 6, 8, 10, 11, 12 сар)",
          Бүртгэл: "Шалгалтаас 1-2 сарын өмнө",
          Хариу: "Шалгалтаас 2 долоо хоногийн дараа",
        },
      ],
      v: [
        "https://www.youtube.com/user/SuperTutorTV",
        "https://youtu.be/pqW3j9IgvN8?si=Tf5KsmuiWT7syhE8",
        "https://youtube.com/playlist?list=PLXmJvdMwHZJ-UdQx7Y79LluJpYblQK9yV&si=K5kJ3rBY1j_C9oaX",
      ],
      m: [
        "https://thecollegepanda.com/categories/sat-math/",
        "https://www.cracksat.net/sat/reading/",
        "https://satsuite.collegeboard.org/sat/practice-preparation/practice-tests/linear#",
      ],
      s: "SAT нь 2 хэсэгтэй: Unscored Essay болон Optional Essay. Унших, бичих, математикийн хэсэг тус бүр 800 онооны үнэлгээтэй. Шалгалтын нийт хугацаа 3 цаг 45 минут бөгөөд маягт бөглөх, материал тараах, 10 минут ба түүнээс дээш завсарлага зэргээс шалтгаалан ерөнхийдөө 4 цагаас илүү байдаг.",
      p: "1600 оноо нийт оноо (800 унших ба бичих, 800 математик).",
      g: "College Board-оос бүртгүүлнэ, шалгалтын төв, өдрийг сонгож, төлбөрөө төлнө. Бүртгэлийн хураамж 68$, олон улсын төлбөр нь 43$, гадаад оюутнуудад нийт 111$ төлнө.",
      r: "https://www.collegeboard.org/",
      f: "Бүртгэлийн хураамж 68$, олон улсын төлбөр нь 43$, гадаад оюутнуудад нийт 111$ төлнө.",
      a: [
        "Сайн унтаж амрах.",
        "Шаардлагатай бичгийн хэрэгслээ (тооны машин, харандаа гэх мэт) авчрах. Харандаа (HB харандаа, үзүүрлэсэн байх ёстой), баллуур, харандаа үзүүрлэгч, тооны машиныг жижиг тунгалаг гялгар уутанд хийж, шалгахад хялбар болгоно. Эдгээр зүйлсийг объекттой холбоо тогтоохыг багасгахын тулд энэ жил зээлж авах боломжгүй.",
        "Шалгалтын төвдөө цагтаа очих.",
        "Бүх зууш/ундаа/усны савыг ширээн доороо хийх жижиг уутанд хий.",
        "Таны гар утас болон бусад төхөөрөмж(ухаалаг цаг)-ийг унтрааж, цүнхэндээ хийх ёстой. Шалгалтын өрөөнд орсны дараа та шалгалтаа дуусах хүртлээ цүнхээ ашиглах боломжгүй болно. Цүнхийг найдвартай газар байрлуулна. Та өрөөндөө орсны дараа л энгийн бичгийн хэрэгсэл, тооцоолуур, хөнгөн зууштай болно.",
        "Та элсэлтийн тасалбараа хэвлэсэн байх шаардлагатай (энэ нь зөв эсэхийг шалгаарай), зурагтай үнэмлэхээ авах шаардлагатай.",
      ],
      day: "Шалгалт өгснөөс хойш 13 хоногийн дараа online-аар хариугаа харж болно. Харин түүнээс 3 өдрийн дараа эсээний хариу, мөн түүнээс 12 өдрийн дараа таны үнэгүй сонголтоор сонгосон 4 сургуульд оноо тань очсон байх болно.",
      i: "Ахлах сургуулиа төгсөх эсвэл төгссөн сурагчид",
      wn: [
        "Гадаад улсад суралцах болон тэтгэлэг авхыг хүсэж байгаа хэн болгон энэ шалгалтыг өгөх хэрэгтэй.",
      ],
      l: [
        "Orkhon KhaSu School",
        "American School of Ulaanbaatar",
        "English Academy of Must",
        "INTL SCH of Ulaanbaatar",
        "Jet School of English",
        "Nest Education High School",
        "Orchlon International School",
        "Selbe School",
        "Ulaanbaatar Empathy School",
      ],
    },
    {
      img: "../assets/ielts.png",
      n: "IELTS",
      d: "IELTS (International English Language Testing System) нь Англи хэлний чадварыг үнэлдэг олон улсын түвшний шалгалт юм. Academic болон General Training гэсэн 2 төрөлтэй. Academic нь их, дээд сургуульд элсэн суралцахад чиглэгдсэн бол General Training нь ажлын байр, цагаачлалд зориулсан шалгалт юм.",
      c: [
        "Өдөр тутамд Англи хэл дээр уншиж, бичих дасгал хийж хэвшээрэй.",
        "Сонсох чадвараа сайжруулахын тулд подкаст, YouTube видео, эсвэл аудио ном сонсоорой.",
        "Шалгалтын бүтэц, асуултын төрлийг сайн судалж, жишиг тестүүдээр бэлтгэл хийх.",
      ],
      w: [
        {
          n: "IELTS Official Website",
          w: "https://ielts.org/",
          d: "IELTS-ийн албан ёсны вэбсайт нь тестийн мэдээлэл, бүртгэл, бэлтгэл сургалтыг санал болгодог.",
        },
        {
          n: "IELTS Liz",
          w: "https://ieltsliz.com/",
          d: "IELTS Liz вэбсайт нь IELTS тестийн бэлтгэл материал, зөвлөмжүүдийг өгдөг.",
        },
        {
          n: "British Council IELTS Preparation",
          w: "https://takeielts.britishcouncil.org/take-ielts/prepare",
          d: "British Council-ийн вэбсайт нь IELTS тестийн бэлтгэл хийхэд туслах үнэгүй сургалтуудыг санал болгодог.",
        },
      ],
      t: [
        {
          Өдөр: "Жилд олон удаа (сар бүр зохион байгуулна)",
          Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
          Цаг: "Өглөө 9:00 цагт эхэлдэг",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=6-VlNg3n7cg",
        "https://www.youtube.com/watch?v=gfKS-p7thDU",
        "https://www.youtube.com/watch?v=r5eiUU3EpHE",
      ],
      m: [
        "'The Official Cambridge Guide to IELTS'.",
        "'IELTS Trainer' (Cambridge).",
        "'Barron's IELTS Superpack'.",
        "'IELTS Liz' цахим сурах материал.",
      ],
      s: "Listening (30 минут), Reading (60 минут), Writing (60 минут), Speaking (11-14 минут).",
      p: "IELTS нь 0-9 хүртэл оноотой. Сонсгол, уншлага, бичих, ярих чадвар тус бүрт 0-9 хооронд оноо өгөгдөх бөгөөд эдгээр оноонуудын дундаж нь таны ерөнхий оноог тодорхойлно.",
      g: "IELTS.org руу орж, хаяг үүсгэнэ, шалгалтын төв, өдрөө сонгож, төлбөрөө төлнө.",
      r: "https://ielts.org/",
      f: "785,000 төгрөг (дундаж)",
      a: [
        "Сайн унтаж амарсан байх.",
        "Шалгалтын төвдөө цагтаа ирэх.",
        "Бичиг баримтаа бэлэн байлгах.",
      ],
      day: "Цаасан тест: 13 хоног, Компьютер тест: 3-5 хоног.",
      i: "Бүх насныхан.",
      wn: [
        "Их сургуульд элсэгчид.",
        "Ажилд орох өргөдөл гаргагчид.",
        "Цагаачлалын өргөдөл гаргагчид.",
      ],
      l: [
        "Орон нутгийн British Council эсвэл IDP IELTS төвүүдэд шалгалт авдаг.",
      ],
    },
    {
      img: "../assets/topik.svg",
      imgdr: "../assets/topik-dr.png",
      n: "TOPIK",
      d: "TOPIK (Test of Proficiency in Korean) бол Солонгос хэлний мэдлэгийг шалгах олон улсын шалгалт юм. TOPIK I: Анхан шат (1-2 түвшин). TOPIK II: Дунд ба ахисан шат (3-6 түвшин).",
      c: [
        "Солонгос хэлний дүрэм, үгийн санг өдөр бүр давтаж, дадлага хийх.",
        "Сонсох, унших, бичих чадвараа тэнцвэртэй хөгжүүлэх.",
        "Өмнөх шалгалтын жишиг даалгавруудыг ашиглан дадлага хийх.",
        "Солонгос хэл дээр кино үзэж, аудио материал сонсож, харилцан ярианы чадвараа хөгжүүлэх.",
      ],
      w: [
        {
          n: "TOPIK Guide",
          w: "https://www.topikguide.com/",
          d: "TOPIK Guide вэбсайт нь TOPIK (Test of Proficiency in Korean) тестийн бэлтгэл, мэдээлэл, сургалтын нөөцийг санал болгодог.",
        },
        {
          n: "Talk To Me In Korean",
          w: "https://talktomeinkorean.com/",
          d: "Talk To Me In Korean вэбсайт нь Солонгос хэлний сургалтууд болон үнэгүй материалуудыг санал болгодог.",
        },
        {
          n: "TOPIK Exam",
          w: "https://topikexam.com/en",
          d: "TOPIK Exam вэбсайт нь TOPIK тестийн бэлтгэл хийх, шалгалт авах боломжтой.",
        },
      ],
      t: [
        {
          Өдөр: "2025 оны 04.13 (99 дэх), 05.11(100 дахь), 10.19(102 дахь), 11.16(103 дахь) (Ням)",
          Бүртгэл: "Бүртгэл 3 сарын өмнө нээгдэж, 4-5 хоног үргэлжилнэ.",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=hLf2IVQ5PGU",
        "https://www.youtube.com/watch?v=3msmx3w5J7Y",
        "https://www.youtube.com/watch?v=RxnPTtP2SWo",
      ],
      m: [
        "'한국어능력시험 초급' (TOPIK I-д зориулсан ном).",
        "'한국어능력시험 중급 및 고급' (TOPIK II-д зориулсан ном).",
        "Өмнөх жилийн TOPIK-ийн жишиг тестүүд.",
        "Солонгосын соёлын төвөөс гаргасан сургалтын материал.",
      ],
      s: "TOPIK I: Сонсох (30 асуулт, 40 минут), Унших (40 асуулт, 60 минут). TOPIK II: Сонсох (50 асуулт, 60 минут), Унших (50 асуулт, 70 минут), Бичих (2 даалгавар, 50 минут).",
      p: "TOPIK I: 200 онооны дотор. TOPIK II: 300 онооны дотор.",
      g: "Албан ёсны вебсайтад бүртгүүлэх, шаардлагатай мэдээллийг бөглөж, төлбөрөө төлөх.",
      r: "https://topik.mn/",
      f: " TOPIK I: ойролцоогоор 70,000–80,000 төгрөг; TOPIK II: ойролцоогоор 100,000–120,000 төгрөг",
      a: [
        "Эрч хүчээ ухаалгаар хуваарилах.",
        "Иргэний үнэмлэх эсвэл паспортоо шалгах.",
        "Шаардлагатай бичгийн хэрэгслээ авчрах.",
      ],
      day: "Шалгалтын дараа 4-6 долоо хоногийн дотор албан ёсны вебсайтад байршина.",
      i: "Солонгос хэл суралцаж буй хэн бүхэн.",
      wn: [
        "Солонгост суралцах хүсэлтэй оюутнууд.",
        "Ажилд ороход хэлний батламж шаардагддаг хүмүүс.",
        "Хэлний түвшнээ тодорхойлохыг хүссэн хувь хүмүүс.",
      ],
      l: ["МУИС-ийн хичээлийн 2,4,5-р байр"],
    },
    {
      img: "../assets/hskdr.png",
      imgdr: "../assets/hsk.png",
      n: "HSK",
      d: "Хятад хэлний түвшин тогтоох шалгалт (HSK) нь CTI компаниас зохион байгуулдаг, олон улсын хятад хэлний түвшин тогтоох олон улсын стандартчилсан шалгалт юм. HSK нь Хятад хэл суралцагчдын өдөр тутмын, эрдэм шинжилгээний болон мэргэжлийн амьдралдаа Хятад хэлийг хоёр дахь хэл болгон ашиглах чадварыг стандарт нь шалгалтын нэгдсэн үнэлгээ, онооны системээр үнэлдэг.",
      c: [
        "Тогтмол шинэ үг цээжлэж, үгийн сангаа сайжруулаарай.",
        "Унших, бичих, сонсох дасгалаа бэлдэж, сайжруулаарай.",
        "Тууштай байж шалгалтын өдөр аль болох тайван байхыг хичээгээрэй!",
      ],
      w: [
        {
          n: "Duolingo",
          w: "https://www.duolingo.com",
          d: "Хятад хэлний үгийн сан, дүрэм, үндсэн хэллэгт зориулсан дасгалуудыг санал болгодог.",
        },
        {
          n: "HSK Online",
          w: "https://www.hskonline.com",
          d: "HSK-ийн бүх түвшинд үнэ төлбөргүй mock test өгөх, үгийн сангийн дадлага хийх боломжтой.",
        },
        {
          n: "HelloChinese",
          w: "https://www.hellochinese.cc/",
          d: "Хятад хүний яриа сонсох, сонирхолтой түүхүүд унших, яриагаа бичиж дуудлага сайжруулах, ханзыг зөв бичиж сурах зэрэг олон сонирхолтой байдлаар сурах боломжтой.",
        },
      ],
      l: [
        "Үй Цай сургуулийн CRI Күнзийн анги - HSK, BCT, YCT, HSKK",
        "Ховд их сургуулийн Күнзийн институт(IBT) - HSK, HSKK",
        "HSK Итгэл - HSK, BCT, YCT, HSKK",
        "Монгол Улсын Их Сургууль - HSK, HSKK",
      ],
      t: {
        Өдөр: "Сар бүр зохион байгуулна",
        Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
        Цаг: "HSK 2,4,6 өглөө 9:00, ярианы шалгалт 14:00, HSK 1,3,5 үдээс эхэлнэ.",
        Хариу: "Цаасан тест: 2-3 хоног, Компьютер тест: 7-10 хоног.",
      },
      v: [
        "https://youtu.be/FlaJ12tmtu4?si=FBvCrcjM9AjFHEIr",
        "https://youtu.be/WyehfFj72zY?si=OiWx93AhgLJnUv72",
        "https://youtube.com/playlist?list=PL2pHdzrcvbMjvwtYVWV8HEjpNzJyPCdoj&si=C_zLtTDZEi72GeuN",
      ],
      m: [
        "Mandarin Bean- https://mandarinbean.com/hsk-chinese-test-online/ - HSK1-6 түвшний сонсох болон унших дасгал хийж болно.",
      ],
      s: "Суурь шат: Сонсгол, хэл зүй, сонгох дасгал; Дунд шат: Сонсгол, хэл зүй, сонгох дасгал, хүснэгт бөглөх; Дээд шат: Сонсгол, хэл зүй, сонгох дасгал, хүснэгт бөглөх, зохиол бичих, аман яриа.",
      p: "HSK 1, 2-р зэргийн шалгалтын нийт оноо 200 байдаг бол 120 оноогоор тэнцэнэ. HSK 3-6-р зэргийн шалгалтын нийт оноо 300 байдаг бол 180 оноогоор тэнцдэг.",
      g: "ChineseTest website руу орно. Өөрийн хаягийг нээнэ. Шалгалт өгөх түвшин, цаас эсвэл интернетээр, болон өдрөө сонгоно. Хувийн мэдээллийг оруулна. (Иргэний үнэмлэхний дугаар, хүйс, төрсөн он/сар/өдөр гэх мэт). Цээж зургаа оруулна. Шалгалтны төлбөрийг төлнө. Шалгалтын зөвшөөрлийн бичиг авна. Та өөрийн багш болон сургалт, шалгалтын төвөөс тусламж авч, бүртгүүлж болно.",
      r: "https://www.chinesetest.cn/",
      f: "Түвшингээсээ хамааран 150¥-оос 650¥ байна.",
      a: [
        "Шалгалтын өдөр сайн амарч, тайван байх.",
        "Бичиг баримтаа бүрэн бэлдэж ирэх. (HSK зөвшөөрөл, Хүчинтэй иргэний үнэмлэх)",
        "Харандаа, баллуур",
      ],
      day: "Шалгалтын гэрчилгээг шалгалт өгснөөс хойш 1 сарын дараа олгодог ба 2 жилийн хугацаанд хүчинтэй.",
      i: "Нас, харьяалал, гарал үүсэл харгалзахгүйгээр хэн ч өгч болно.",
      wn: [
        "Хятад хэл суралцагчид.",
        "Мэргэжлийн болон эрдэм шинжилгээний зориулалттай хүмүүс.",
        "Цагаачлал болон ажил хайж буй хүмүүс.",
        "HSK Хятад улсын их дээд сургуулиудад суралцах болон Хятад улсад ажиллахаар ирэх гадаадын иргэдээс Хятад улс HSK шалгалтыг өгсөн байхыг шаарддаг.",
      ],
      l: [
        "Шалгалтын газар бүр өөр өөр байдаг тул бүртгүүлэх дээ харах боломжтой.",
      ],
    },
    {
      img: "../assets/jlpt.png",
      imgdr: "../assets/jlptdr.png",
      n: "JLPT",
      d: "JLPT (Japanese-Language Proficiency Test) нь Япон хэл сурагчдад зориулсан, Япон хэлний мэдлэгийг шалгах олон улсын шалгалт юм. Энэ шалгалт нь 5 түвшинд хуваагддаг: N1 (хамгийн өндөр) - N5 (хамгийн доод). Япон хэлийг унших, сонсох, ойлгох чадварыг шалгадаг.",
      l: ["ЯПОН САН, Монголын Япон Хэлний Багш нарын Холбоо"],
      c: [
        "Өдөр бүр тодорхой хугацаанд сурах",
        "Сонсгол дээр анхаарах",
        "Жишиг тестүүд сайн ажиллах",
        "7-р сарын шалгалт ЕЭШ(ЭШ)-тэй давхцах боломжтой",
      ],
      w: [
        {
          n: "Easy Japanese",
          w: "https://easyjapanese.net/jlpt-test/",
          d: "Жишиг шалгалтууд",
        },
        {
          n: "JLPT Sensei",
          w: "https://jlptsensei.com/",
          d: "Бүх дүрэм ба ханзнуудыг цогцлоосон",
        },
        {
          n: "Tuttle Publishing",
          w: "https://www.tuttlepublishing.com/jlpt-study-guide",
          d: "Жишиг дасгал даалгаврууд",
        },
      ],
      t: {
        Өдөр: "JLPT Тест нь жил бүр 2 удаа зохион байгуулагддаг. 7, 12-р сард зохион байгуулдаг",
        Бүртгэл: "Шалгалтын өдрөөс 2 сарын өмнө",
        Цаг: "HSK 2,4,6 өглөө 9:00, ярианы шалгалт 14:00, HSK 1,3,5 үдээс эхэлнэ.",
      },
      v: [
        "https://www.youtube.com/watch?v=47xmiuDBEG8",
        "https://www.youtube.com/watch?v=D21ZMN_B0hk",
        "https://www.youtube.com/watch?v=xYrOvyDa740",
      ],
      m: ["JLPT Япон хэлний ном", "The Official Practice Workbook Vol. 2"],
      s: "Шалгалтын бүтэц: Хэлний мэдлэг (Үгийн сан)- 20-30 минут, Дүрэм, Унших 40-70 минут, Сонсох 30-55 минут. Нийт: N1-165мин, N2-155мин, N3-140мин, N4-115мин, N5-90 мин.",
      p: "Онооны систем: Хэлний мэдлэг (Үгийн сан, Дүрэм)-60 оноо, Унших-60 оноо, Сонсох-60 оноо, Нийт: 180 оноо. Тэнцэх: N1-100 оноо, N2-90 оноо, N3-95 оноо, N4-90 оноо, N5-80 оноо.",
      g: "Бүртгэл: 2024-08-30 нээгдэж 2024-09-07 дуустал явагдана. jlpt.mn website дээр бүртгүүлнэ.",
      r: "https://jlpt.mn/",
      f: "Шалгалтын төлбөр: N1 ～ 70000 ₮, N2 ～ 70000 ₮, N3 ～ 65000 ₮, N4 ～ 55000 ₮, N5 ～ 55000 ₮.",
      day: "7 сарын шалгалт – 10 сарын эхээр, 12 сарын шалгалт – 3 сарын эхээр",
      a: [
        "Шалгалтын өдрийн өмнө сайн амраарай",
        "Хувийн материалаа бэлдэж аваарай",
        "Хамгийн чухал зүйл нь тайван байх",
      ],
      wn: "Японд их сургуульд суралцах болон ажиллах хүмүүс",
    },
    {
      img: "../assets/Goethe.png",
      n: "Goethe-Zertifikat",
      d: "Goethe-Zertifikat нь Герман хэлний түвшинг баталгаажуулдаг олон улсын түвшинд хүлээн зөвшөөрөгдсөн шалгалт юм. Энэ нь Европын хэлний түвшний (CEFR) A1-ээс C2 хүртэлх түвшнийг хамардаг.",
      c: [
        "Герман хэлний дүрэм, үгийн санг өдөр бүр давтах.",
        "Сонсгол, уншлагын дадлагыг аудио болон текст материал ашиглан хийх.",
        "Германаар ярих чадвараа сайжруулахын тулд тухайн хэлээр ярьдаг орчинд өөрийгөө идэвхтэй оруулах (соёлын арга хэмжээнд оролцох, хэлэлцүүлгийн бүлгүүдэд элсэх)",
      ],
      w: [
        {
          n: "Goethe Institute",
          w: "https://www.goethe.de/en/spr/prf/vor.html",
          d: "Шалгалт өгөх бэлдэлт",
        },
        {
          n: "DW Learn German",
          w: "https://learngerman.dw.com/en/learn-german/s-9528",
          d: "Герман хэл сурах",
        },
        {
          n: "Lingoda",
          w: "https://www.lingoda.com/en/",
          d: "Онлайн герман хэлний сургалт",
        },
      ],
      l: ["Гёте-Институт Монгол"],
      t: {
        Өдөр: "Одоогоор шалгалт өгөх хугацаа заагаагүй байна",
        Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
        Цаг: "Шалгалт болох цагийг шалгалтын хуваарийг шалгах",
      },
      v: [
        "https://www.youtube.com/watch?v=-Qt3llCTdkM&list=PLZ6nUCSTx9pKa8PEs0TBPMWTMeDa4gcsH",
        "https://www.youtube.com/watch?v=mLUTv35RigE&list=PLsyb8W0fpnBvLrntHk2TR8hQxddaTL-2V",
        "https://www.youtube.com/watch?v=dq1nFflRE94&list=PLsyb8W0fpnBvLrntHk2TR8hQxddaTL-2V&index=15",
      ],
      m: [
        "Menschen сурах бичгийн цуврал.",
        "Goethe-Zertifikat-ын дадлагын ном, гарын авлага.",
        "Герман хэлний дүрмийн гарын авлагууд.",
      ],
      s: "Шалгалтын бүтэц: Унших (Lesen): Олон сонголттой тест, текст ойлгох даалгавар. Сонсох (Hören): Аудио бичлэг сонсоод асуултад хариулах даалгавар. Бичих (Schreiben): Өгөгдсөн сэдвээр эссе эсвэл албан бичиг бичих даалгавар. Ярих (Sprechen): Хувийн танилцуулга, сэдэвчилсэн яриа.",
      p: "Онооны систем: Тус бүрийн чадвараас 100 оноо авах боломжтой. Нийт оноо 60%-аас дээш байвал тэнцэнэ.",
      g: "Goethe-Institut-ын албан ёсны вэбсайт руу орох. Шалгалтын хуваарийг шалгах. Онлайн бүртгэлийн маягтыг бөглөх.",
      r: "https://www.goethe.de/ins/mn/mn/spr/prf.html",
      f: "Шалгалтын төлбөр: Гёте-Сертификат A1: Fit in Deutsch 1 - 55,000 төгрөг, Гёте-Сертификат A1: Start Deutsch 1 - 180,000 төгрөг, Гёте-Сертификат A2: Fit in Deutsch - 70,000 төгрөг, Гёте-Сертификат A2 - 200,000 төгрөг, Гёте-Сертификат B1 (өсвөр үеийнхэнд зориулсан) - 100,000 төгрөг, 30,000 төгрөг (1 хэсэг), Гёте-Сертификат B1 - 240,000 төгрөг, 70,000 төгрөг (1 хэсэг), Гёте-Сертификат B2 - 280,000 төгрөг, 80,000 төгрөг (1 хэсэг), Гёте-Сертификат C1 - 320,000 төгрөг, 90,000 төгрөг (1 хэсэг), Гёте-Сертификат C2 - 320,000 төгрөг, 90,000 төгрөг (1 хэсэг), 360,000 төгрөг, 100,000 төгрөг (1 хэсэг).",
      a: [
        "Шалгалтаас өмнө хангалттай амрах.",
        "Шалгалт эхлэхээс 30 минутын өмнө ирэх.",
        "Сэтгэл санаагаа тайван байлгах",
      ],
      i: "Герман хэлний мэдлэгээ баталгаажуулахыг хүссэн хэн бүхэн өгч болно.",
      wn: "Герман улсад суралцах хүсэлтэй оюутнууд. Ажилд ороход хэлний батламж шаардагддаг хүмүүс. Хэлний түвшнээ баталгаажуулахыг хүссэн хувь хүмүүс.",
      day: "Шалгалтын дараа 2-4 долоо хоногийн дотор хариу гарна.",
    },
    {
      n: "yesh",
      d: "ЭЕШ (Элсэлтийн Ерөнхий Шалгалт) нь Монгол улсын их, дээд сургуулиудад элсэхэд нь стандартчилсан шалгалт юм.",
      c: [
        "Шалгалтандаа эртнээс бэлдэж эхлээрэй",
        "Шалгалтын өмнөх өдрүүдэд өөрийгөө болон тархиа сайн амраагаарай",
        "Өөрийн дотоод мэдрэмждээ итгээрэй",
        "Тэжээллэг хоол хүнс хэрэглээрэй",
        "Шалгалтаа хэрхэн яаж өгөх талаар төлөвлөгөө гаргаарай",
      ],
      w: [
        {
          n: "ЭЕШ шалгалтын сайт",
          w: "http://eyeshitems.eec.mn/",
          d: "ЭЕШ-д бэлтгэх мэдээлэл",
        },
        {
          n: "iMath",
          w: "https://www.imath.mn",
          d: "Математикийн онлайн сургалт",
        },
      ],
      l: [
        "БҮТ-ийн албан ёсны вэбсайт (eec.mn)-аас шалгалтын байр, цагийн хуваарийг шалгана.",
      ],
      t: {
        Өдөр: "Шалгалт болох өдөр, цагийг шалгалтын хуваарийг шалгах",
        Бүртгэл:
          "Бүртгэлийн хугацаа тодорхой, суудлын хуваарийн мэдээлэл шалгалтын бүртгэлд байна",
        Цаг: "Шалгалтын цагийг шалгалтын хуваарийг шалгах",
      },
      v: [
        "https://youtu.be/KsXQg6KfOxM?si=i3nB-SxutUAi7Nsl - 14 өдрийн дотор БИЕ ДААН ЭЕШ-д бэлдээд 750+ оноо авсан СТРАТЕГИ",
        "https://youtu.be/ZsSLgjkNCMY?si=AR0GV38Lg1uLMqvK - ЭЕШ 800 оноо авах 45 арга",
        "https://youtu.be/Mao62cvvHPA?si=DlKi6aRNRfcAI_cK - Хэрхэн үр дүнтэй хичээлээ хийх вэ?",
      ],
      m: [
        "9,10,11,12-р ангийн сурах бичгүүд",
        "ЭЕШ-д бэлтгэгчдэд зориулсан тестийн ном",
        "Шинэ жишиг даалгаврын эмхэтгэл",
        "1001 тест",
      ],
      s: "Тухайн хичээлээс хамааран өөр, өөр байна.",
      p: "Онооны систем: 200-800 оноо",
      g: "eyesh.eec.mn сайт руу орж бүртгэл цэс рүү орно. Регистрийн дугаар оруулах. Хувийн мэдээллээ оруулах. Сүүлийн 3 сард авхуулсан цээж зураг оруулах. Бүртгэлийн дугаар, нууц үг авах. Асуулга бөглөх. МХБШ-д бүртгүүлэх. ЭШ-ийн хичээлээ сонгох. Тухайн хичээл заадаг багш сонгох. Төлбөрөө төлөх. Бүртгэлийн хуудсаа хэвлэн авах.",
      r: "https://eyesh.eec.mn/",
      f: "ЭЕШ-ын нэг хичээлийн бүртгэлийн хураамж 5800 төгрөг, батламж хуудасны хураамж 700 төгрөг байна.",
      a: [
        "ДҮРМЭЭ ОЙЛГО: Тестийн материал хэрхэн зөв бөглөх талаар сайн ойлгох нь таныг өндөр оноо танд туслах хамгийн эхний сана юм.",
        "ТӨВЛӨР: Анхаарлаа зөвхөн шалгалтдаа дээрээс, өөр зүйлд сатаарах хэрэггүй.",
        "ХӨНГӨНӨӨС ХҮНД РҮҮ: Шалгалтыг заавал дарааллаар нь нэг бүрчлэн хийх хэрэггүй. Эхлээд хийж чадахаасаа эхлээд дараа нь хэцүү асуултдаа хариулаарай. үүнийг ингэхдээ яарч сандраад тестээ буруу бөглөхөөс сэргийлээрэй.",
        "ДУУСТАЛ УНШ: Цагаа хэмнэж байна гээд даалгавраа алгасан яарч сандарч унших хэрэггүй. Анхааралтай байж зөв уншаарай.",
        "НЭГ ЗҮЙЛД АНХААР: Шинэ даалгавар орсон л бол өмнө нь хийсэн бүх даалгавруудаа март. Зөвхөн одоо байгаа дээрээ л анхаар.",
        "ШУУД ХАС: Олон хариулт дунд сонголт хийх шаардлагагүй гэж үзэж байна.",
        "ШАЛГАХ ХУГАЦАА ҮЛДЭЭ: Хамгийн сүүлд бүх даалгавраа бөглөсөн байна уу үүнийг хориглох ёстой үлдээгээрэй.",
        "ШАНАЛАХ ХЭРЭГГҮЙ: Бүх даалгаврыг хийж амжихгүй нь гэж шаналах хэрэггүй. Чанаргүй олноос чанартай цөөн нь дээр шүү дээ.",
        "ЗӨВ ХУВААРИЛАЛТ: Цагаа зөв жолоодох хэрэгтэй. Нэг хүнд даалгавар дээр хамаг цагаа үрэх хэрэггүй шүү.",
      ],
      day: "Шалгалт өгсний дараа хариу гарах хугацаа зарлагдана.",
      i: "Бүрэн дунд боловсрол эзэмшсэн болон МХБШ-д тэнцсэн сурагчид",
      wn: "Их сургуульд сурах хүсэлтэй сурагчид",
    },
    {
      img: "../assets/duo.webp",
      imgdr: "../assets/duo-dr.png",
      n: "DUOLINGO",
      d: "Duolingo English Test нь АНУ болон дэлхийн олон их, дээд сургуулиуд болон байгууллагуудын шаардлагад нийцсэн англи хэлний түвшинг тодорхойлох цахим шалгалт юм. Энэ шалгалт нь вэб дээр өргөн хэрэглэгддэг, хурдан, хямд, хялбар шалгалт бөгөөд дэлхийн аль ч улс орноос, интернэт холболттой газраас өгч болно.",
      c: [
        "Бэлтгэл хийх: Duolingo цаанаасаа бэлтгэл материал болон жишиг тестүүдийг санал болгодог.",
        "Практик хийх: Duolingo хэлний сургалтын аппыг ашиглан үгийн сан, хэлзүй, сонсох, унших чадваруудыг сайжруулах.",
        "Цагийн менежмент барих",
      ],
      w: [
        {
          n: "Duolingo",
          w: "https://www.duolingo.com/",
          d: "Duolingo-ийн үндсэн вэбсайт",
        },
        {
          n: "Duolingo English Test",
          w: "https://englishtest.duolingo.com/applicants",
          d: "Duolingo English Test-ийн вэбсайт",
        },
      ],
      l: [
        "Шалгалт нь онлайнаар тогтмол бүртгэгдэх ба, шалгалтаа хүссэн үедээ, хүссэн газартаа онлайнаар өгч болно.",
      ],
      v: [
        "https://www.youtube.com/watch?v=VrrsnFa_K3I",
        "https://www.youtube.com/watch?v=X76TcmE1rvw",
        "https://www.youtube.com/watch?v=avhEAqbccus",
      ],
      m: ["Duolingo App", "TED Talks", "BBC Learning English"],
      s: "Сонсох (Listening): 15 минут. Унших (Reading): 15 минут. Бичих (Writing): 10 минут. Яриа (Speaking): 10 минут.",
      p: "Онооны систем: 10-160 онооны хооронд шалгалт авна. 120+ оноо нь ихэнх их сургуулиудад шаардлагатай.",
      g: "Duolingo English Test-ийн албан ёсны вэбсайтаар орж, бүртгэлээ хийнэ. Бүртгэлд шаардлагатай мэдээллээ бөглөх, төлбөрөө хийх. Шалгалт өгөхөд бэлэн болсны дараа шалгалтаа эхлүүлнэ.",
      r: "https://englishtest.duolingo.com/register",
      f: "$65.00 (220k)",
      a: ["Шалгалтын өмнө интернэт холболтоо шалгах, камерыг зөв байрлуулах."],
      day: "Өдөртөө 39$ эсвэл 2 өдрийн дараа",
      i: "Англи хэлний түвшнээ тодорхойлох шаардлагатай хүмүүс.",
      wn: "Их сургуулиуд болон боловсролын байгууллагуудад элсэн суралцах хүсэлтэй оюутнуудад. Ажилд орохыг хүссэн хүмүүс, ажлын виз авах шаардлагатай хүмүүс.",
    },
    {
      img: "../assets/toefl.png",
      n: "TOEFL",
      d: "Test of English as a Foreign Language (TOEFL) нь англи хэлээр ярьдаг их дээд сургуульд элсэн суралцах хүсэлтэй төрөлх бус хүмүүсийн англи хэлний чадварыг хэмжих стандартчилсан шалгалт юм. TOEFL iBT шалгалт нь шалгуулагчийн сонсох, унших, ярих, бичих чадварыг хамгийн чухал зүйл болох анги дотор хослуулан ашиглах чадварыг шалгадаг.",
      c: [
        "Шалгалтын бүтцийг ажиглаад, цагийг зөв зохицуулах",
        "Academic Word List (AWL) гэх мэт үгсийн жагсаалтыг судалж, шинэ үгсийг контекстээр нь сурах",
        "Barron’s 500 Words for TOEFL, Quizlet зэргийг ашиглан үгийн сангаа баяжуулах",
      ],
      w: [
        {
          n: "Educational Testing Service",
          w: "https://www.ets.org/toefl.html",
          d: "Албан ёсны вэб сайт нь дадлагын тест, түүвэр асуулт, зааварчилгааг агуулдаг.",
        },
        {
          n: "Magoosh TOEFL",
          w: "https://magoosh.com/toefl",
          d: "Видео хичээл, дадлага хийх асуулт, үгсийн сангийн жагсаалт, шалгалт өгөх зөвлөмжийг санал болгодог.",
        },
        {
          n: "Exam English",
          w: "https://www.examenglish.com/toefl",
          d: "TOEFL-ын сонсох, унших, бичих, ба ярих бэлтгэл хийх шалгалтыг үнэгүй хийж болно.",
        },
      ],
      l: [
        "E-Pen TOEFL Testing Center",
        "English Language Academy MUST",
        "EARC Center",
        "Absolute School of English Language",
        "Yeti School of English Language",
        "Bilingual School of Language",
      ],
      t: [
        {
          Өдөр: "Жилд олон удаа (сар бүр зохион байгуулна)",
          Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
        },
      ],
      v: [
        "https://youtu.be/woo8EJn42wQ?si=aJWm1toe-8I1x7Cc",
        "https://youtu.be/0cOnR24vLKw?si=BY0pdNEOVpMcI3aq",
        "https://youtu.be/q15K9ByEchw?si=OvrKr1RqJVU2Akfg",
      ],
      m: [
        "Barron’s TOEFL IBT",
        "Cambridge Preparation for the TOEFL IBT",
        "Kaplan’s TOEFL IBT Prep",
      ],
      s: "Шалгалтын бүтэц: Хэсэг: Унших - 35 минут - 20 асуулт, Сонсох - 36 минут - 28 асуулт, Ярих - 16 минут - 4 даалгавар, Бичих - 29 минут - 2 даалгавар.",
      p: "Онооны систем: Нийт 0-120 оноо. 4 хэсэг тус бүр 0-30 оноо.",
      g: "Албан ёсны вебсайт болох ETS-рүү орж хаягаа үүсгэнэ. Нэвтрээд “Шалгалтанд бүртгүүлэх” буюу “Register for a test” хэсгийг сонгоно. TOEFL IBT-г сонгож, өөрийн хаяг (жишээ нь: Улаанбаатар) болон шалгалтын өдрийг сонгоно.",
      r: "https://www.ets.org/toefl",
      f: "$235-$285 (880k)",
      a: [
        "Шалгалтын амралтын өдөр тархиа сайн амраасан байх нь чухал",
        "Аль болох өөртөө тухтай хувцас өмссөн байвал сайн",
        "Шалгалт өгөх газраа дор хаяж 30 минутын өмнө ирсэн байх",
        "Өөртөө итгэлтэй, тайван байж, хэсэг бүр дээр алхам алхамаар анхаарлаа төвлөрүүлээрэй",
        "Нэг асуултанд хэт их цаг зарцуулахаас зайлсхий. Хэрэв та итгэлгүй байвал дараагийн хэсэг рүү шилжээрэй.",
      ],
      day: "Ойролцоогоор 4-8 өдрийн дараа email хаяг руу очно.",
      i: "Гадаадад суралцах хүсэлтэй, Англи хэлний түвшнээ шалгах зорилготой хүмүүс.",
      wn: "TOEFL® нь төрөлх бус англи хэлтэй хүмүүсийн Хойд Америкийн англи хэлийг коллеж, их сургуулийн орчинд ярьж, бичиж, сонсох байдлаар ашиглаж, ойлгох чадварыг шалгадаг.",
    },
    {
      img: "../assets/act.png",
      n: "ACT",
      d: "ACT (American College Testing) нь АНУ-ын их, дээд сургуулиудад элсэхэд шаардагддаг стандарчилсан шалгалт юм. Энэ нь сурагчдын академик чадварыг үнэлж, уншлага, математик, шинжлэх ухаан, англи хэлний ур чадвар дээр суурилдаг. Суралцагчид 1-36 онооны хооронд үнэлгээ авдаг ба дундаж оноо нь сургуулийн элсэлтийн шаардлагыг хангах гол шалгуур болдог.",
      c: [
        "Тогтмол тууштай бэлдэх нь чухал.",
        "Шалгалтын бүтэц, цагийн хуваарийг сайн судлах",
        "Жишиг тестүүд сайн ажиллах",
      ],
      w: [
        {
          n: "ACT Test Preparation",
          w: "https://www.act.org/content/act/en/products-and-services/the-act/test-preparation.html",
          d: "ACT-ийн албан ёсны бэлдэх материалыг агуулдаг.",
        },
        {
          n: "Khan Academy",
          w: "https://www.khanacademy.org/",
          d: "Үнэгүй бэлдэх сургалт.",
        },
        {
          n: "PrepScholar",
          w: "https://www.prepscholar.com/act-test-prep/",
          d: "Бэлдэх төлөвлөгөө, суралцах материал санал болгодог.",
        },
        {
          n: "CrackAB",
          w: "https://www.crackab.com/",
          d: "ACT тестийн бэлтгэлийн хэрэгсэл.",
        },
      ],
      t: [
        {
          Бүртгэл:
            "Шалгалтын өдрөөс 5-6 долоо хоногийн өмнө бүртгүүлэх шаардлагатай.",
          Өдөр: " Жилд 7 удаа (2, 4, 6, 7, 9, 10, 12 сард) зохион байгуулагддаг.",
          Цаг: "Шалгалт нийт 3 цаг 35 минут үргэлжилдэг (Writing хэсэгтэй бол).",
        },
      ],
      l: [
        "ACT шалгалт нь олон улсын болон АНУ-ын итгэмжлэгдсэн шалгалтын төвүүдэд зохион байгуулагддаг.",
      ],
      v: [
        "https://www.youtube.com/watch?v=f2DnOdLBG0k&t=80s",
        "https://www.youtube.com/watch?v=8euL7Z8FVL4",
        "https://www.youtube.com/watch?v=Z5f3tVAekNs",
      ],
      m: [
        "The Official ACT Prep Guide",
        "Princeton Review ACT Premium",
        "Kaplan ACT Prep Plus",
        "Barron's ACT",
        "Khan Academy, UWorld",
      ],
      s: "Шалгалтын бүтэц: English - 45 минут - 75 асуулт, Math - 60 минут - 60 асуулт, Reading - 35 минут - 40 асуулт, Science - 35 минут - 40 асуулт, Writing - эссэ бичлэг.",
      p: "Онооны систем: Хэсэг бүрийг 1-36 хооронд үнэлдэг. 4 хэсгийн дундаж нь таны ерөнхий оноо (Composite Score) болно. Writing хэсэг нь 2-12 оноотой, нийт оноонд нөлөөлөхгүй.",
      g: "ACT.org руу орж, хэрэглэгчийн бүртгэл үүсгэнэ. Шалгалтын өдөр, байршлаа сонгоно. Хувийн мэдээллээ оруулж, шалгалтын төлбөрөө төлнө.",
      r: "https://www.act.org/",
      f: "Writing хэсэггүй: $186.5, Writing хэсэгтэй: $211",
      a: [
        "Сайн унтаж амарч, эрч хүчтэй ирээрэй.",
        "Шалгалтын бичиг баримт, үзэг, харандаа, тооны машин зэргээ авчрах.",
        "Тайван байж, бүх асуултад анхааралтай хариулахыг хичээгээрэй.",
      ],
      day: "Хариу шалгалтын дараа 2-8 долоо хоногийн дотор гарна. Writing хэсгийн хариу 10-12 долоо хоногт гардаг.",
      i: "АНУ болон олон улсын их сургуульд элсэхээр бэлтгэж буй 10-12 дугаар ангийн сурагчид (16-18 настнууд).",
      wn: "АНУ-ын их, дээд сургуульд элсэх хүсэлтэй сурагчид, сургуулийн тэтгэлэгт өргөдөл гаргагчид, SAT-ийн оронд ACT шалгалтыг өгөхийг илүүд үзсэн сурагчид.",
    },
    {
      n: "A-Level",
      d: "A-Level (Advanced Level) нь Их Британи болон зарим улсад дунд сургуулийн төгсөгчдөд зориулсан ахисан түвшний шалгалт бөгөөд оюутнуудын их, дээд сургуульд элсэх эрхийг баталгаажуулдаг. Энэ шалгалт нь үндсэн 2 жилийн хөтөлбөрөөс бүрдэнэ.",
      c: [
        "Өөрийн судалж буй хичээлүүдийн үндсэн агуулгыг сайн ойлгох.",
        "Өдөр бүр тогтмол цаг гаргаж, давтлага хийх.",
        "Өмнөх жилийн шалгалтын материал ажиллах.",
        "Хувийн цагийн хуваарь гаргаж, төлөвлөгөөтэй суралцвал үр дүнтэй.",
      ],
      w: [
        {
          n: "Physics and Maths Tutor",
          w: "https://www.physicsandmathstutor.com/",
          d: "Ахисан түвшний хичээлийн хөтөлбөрт зориулсан сургалт.",
        },
        {
          n: "BBC Bitesize",
          w: "https://www.bbc.co.uk/bitesize",
          d: "Англи хэл дээр ахисан түвшний сургалтын эх сурвалж.",
        },
        {
          n: "Save My Exams",
          w: "https://www.savemyexams.com/a-level/",
          d: "Өмнөх шалгалтын материал, дасгал, түүхүүд.",
        },
      ],
      t: {
        Бүртгэл:
          "Шалгалтын төвөөс хамаарна (ихэвчлэн 9-10 сарын хооронд). Шалгалт ихэвчлэн 5-6 сарын хооронд зохион байгуулагддаг шалгалтын төвөөс тодорхой болно.",
      },
      v: [
        "https://www.youtube.com/watch?v=rNN2qvcvH_A",
        "https://www.youtube.com/watch?v=8kSrjG7aSz0",
        "https://www.youtube.com/watch?v=lNwaqDCEjzQ&list=PLOpc1nicRAAOf4s7n04LnDOewe-bUl9x4",
      ],
      m: [
        "Cambridge, Edexcel, OCR хичээлийн ном, гарын авлагууд.",
        "Өмнөх жилийн A-Level шалгалтын материалууд.",
        "Khan Academy, Seneca Learning, ZNotes.",
      ],
      s: "Шалгалтын бүтэц: Мэдлэг шалгах хэсэг, олон сонголттой асуулт, богино хариулт. Кейстэй холбоотой асуудал шийдвэрлэх. Лабораторийн туршилт эсвэл хээрийн судалгааны дүн шинжилгээ (байгалийн шинжлэх ухаан, газарзүйн чиглэл).",
      p: "Шалгалтын дүнг A - E* хүртэлх үнэлгээгээр тогтооно. A* хамгийн өндөр оноо, E хамгийн доод оноо.",
      g: "Шалгалтын төв эсвэл олон улсын сургуулийн A-Level шалгалтын албатай холбогдох. Бүртгэлийн маягтыг бөглөх, шаардлагатай баримтуудыг бүрдүүлж төлбөрөө төлөх.",
      r: "https://www.britishcouncil.pk/exam/school/register/school-students",
      f: "Шалгалтын төлбөр нь хичээлээс хамаарч өөрчлөгдөх ба ойролцоогоор $100-$150 хооронд байна.",
      a: [
        "Шалгалтын өмнөх өдөр хангалттай амрах.",
        "Хүчинтэй баримт бичиг, шалгалтад шаардлагатай хэрэгслүүдээ шалгах.",
        "Өөртөө итгэлтэй бай. Итгэлтэй байдал таныг стресст автуулахгүй, илүү төвлөрөхөд тусална.",
      ],
      day: "Шалгалтын дүнг ихэвчлэн 8-р сарын дунд үед зарладаг.",
      i: "A-Level хөтөлбөрт суралцаж буй ахлах ангийн сурагчид. Дахин шалгалт өгөхийг хүссэн оюутнууд.",
      wn: "Их Британи, Австрали, Канад болон бусад орны их сургуульд элсэн суралцахыг хүсэгчдэд. Мэргэжлийн чиг баримжаагаа тодорхойлохыг зорьж буй сурагчдад.",
    },
    {
      img: "../assets/igcse.png",
      n: "IGCSE",
      d: "IGCSE (International General Certificate of Secondary Education) нь 14-16 насныханд зориулсан олон улсын түвшний хичээлийн хөтөлбөр бөгөөд ихэвчлэн ахлах сургуулийн сүүлийн хоёр жилд судалдаг. Энэ нь Английн сургалтын хөтөлбөр дээр суурилсан, дэлхийн хэмжээнд хүлээн зөвшөөрөгдсөн шалгалт бөгөөд сурагчдыг академик мэдлэг, ур чадвараар үнэлдэг.",
      c: [
        "Хичээл бүрийн сурах бичгийг судалж, сургалтын төлөвлөгөө гаргах нь үр дүнтэй.",
        "Жишиг шалгалтын материалууд ажиллаж өөрийн мэдлэгээ бататгах.",
        "Шалгалтын үед цагийн менежментдээ анхаарах хэрэгтэй.",
      ],
      l: [
        "Cambridge-ийн хөтөлбөртэй сургуулиуд болон сургалтын төвүүдэд шалгалт авдаг.",
      ],
      w: [
        {
          n: "Save My Exams",
          w: "https://www.savemyexams.com/igcse/",
          d: "IGCSE шалгалтанд зориулсан материалууд.",
        },
        {
          n: "IGCSE Past Papers",
          w: "https://pastpapers.co/cie/?dir=IGCSE",
          d: "Өмнөх шалгалтын материалууд.",
        },
        {
          n: "BBC Bitesize",
          w: "https://www.bbc.co.uk/bitesize/levels/z98jmp3",
          d: "IGCSE хичээлийн агуулга.",
        },
      ],
      t: {
        Бүртгэл:
          "Шалгалтын бүртгэл нь шалгалтын өдрөөс 3-4 сарын өмнө эхэлдэг.",
        Өдөр: "IGCSE шалгалт жилд хоёр удаа (зун болон намар) зохион байгуулагддаг. Хичээл бүр өөр хуваарьтай",
        Цаг: "Хичээл тус бүрийн шалгалт 1-2 цаг үргэлжилдэг.",
      },
      v: [
        "https://www.youtube.com/watch?v=yeqboqRfE2U",
        "https://www.youtube.com/watch?v=VM8CSYby9SQ",
        "https://www.youtube.com/watch?v=QGE-heDhIMw",
      ],
      m: [
        "Cambridge IGCSE Revision Guide",
        "Collins IGCSE English Language Workbook",
        "Oxford IGCSE Mathematics Textbook",
        "Cambridge IGCSE Science Textbooks",
        "Khan Academy, Save My Exams",
      ],
      s: "Шалгалтын бүтэц: Mathematics (Математик), English Language (Англи хэл), Science (Шинжлэх ухаан), History (Түүх), Geography (Газарзүй), ICT (Мэдээллийн технологи), Economics (Эдийн засаг) зэрэг хичээлээс сонгон шалгалт өгч болно.",
      p: "IGCSE шалгалт нь *A (хамгийн өндөр)**-ээс G (хамгийн бага) хүртэл үнэлэгддэг. C болон түүнээс дээш үнэлгээ нь их сургуулийн элсэлтийн шаардлага хангадаг.",
      g: "Суралцаж буй сургуулиараа дамжуулан эсвэл бие даан Cambridge-ийн албан ёсны сайтаар бүртгүүлнэ. Шалгалт өгөх хичээлээ сонгож, төлбөрийг төлнө.",
      r: "https://www.cambridgeinternational.org/why-choose-us/find-a-cambridge-school/",
      f: "Хичээл тус бүрийн төлбөр ойролцоогоор 500,000 - 800,000 төгрөг.",
      a: [
        "Бүх шаардлагатай бичиг баримтаа шалгалтын өмнө бэлэн байлгаарай.",
        "Сэтгэлзүйгээ тайван байж, битгий сандар.",
        "Цагийн менежмент сайн хийж, бүх асуултадаа хариулахыг хичээгээрэй.",
      ],
      day: "8 сарын 15 олон улс даяар.",
      i: "14-16 насны сурагчид, олон улсын ахлах сургуулийн хөтөлбөрт хамрагдсан сурагчид өгөх боломжтой.",
      wn: "Дэлхийн түвшний академик үнэлгээнд хамрагдахыг хүссэн сурагчид. Гадаадад их сургуулиудад суралцах зорилготой сурагчид, элсэгчид.",
    },
    {
      n: "O-Level",
      d: "O-Level шалгалт нь дэлхий даяар олон улсын болон үндэсний боловсролын тогтолцоонд хүлээн зөвшөөрөгдсөн, 14-16 насныханд зориулагдсан шалгалт юм. Энэ нь ихэнх оронд ахлах ангийн сургалтын түвшинг шалгах зорилготой бөгөөд ихэнх суралцагчид О-Level шалгалтыг математикийн болон шинжлэх ухааны чиглэлээр, мөн гадаад хэл, түүх, газарзүй зэрэг олон салбарын хичээлээр өгдөг.",
      c: [
        "Хичээлээ тогтмол давтах: О-Level шалгалт нь өргөн хүрээтэй, олон сэдвийг хамардаг тул хичээлээ давтах, нэмэлт материал унших, багш болон найзуудтайгаа хэлэлцэх нь чухал.",
        "Шалгалтын хуваарийг урьдчилан судлах: Өмнөх жилүүдийн шалгалтын асуултуудыг үзэж, шалгалт авах өдөр нь ямар сэдвүүдийг голлон асуухыг таамаглах.",
        "Бэлтгэлийн цагийн хуваарь: Шалгалтад бэлдэхдээ цагийн менежментийг анхаарч, бүх сэдвийг тэгш хувиар судлах.",
      ],
      w: [
        {
          n: "BBC Bitesize",
          w: "https://www.bbc.co.uk/bitesize",
          d: "О-Level шалгалтын материалууд.",
        },
        {
          n: "Cambridge International",
          w: "https://www.cambridgeinternational.org/",
          d: "О-Level шалгалт болон бэлтгэлийн материалууд.",
        },
        {
          n: "Save My Exams",
          w: "https://www.savemyexams.com/o-level/",
          d: "О-Level шалгалтанд зориулсан материалууд.",
        },
      ],
      l: [
        "Шалгалтын төвүүд буюу сургуулиуд. Олон улсын шалгалтанд хамрагдах боломжтой.",
      ],
      t: {
        Бүртгэл:
          "Шалгалтын бүртгэл нь жилд 2 удаа байдаг, нэг удаа 10-р сард, нөгөө удаа 4-р сард.",
        Өдөр: "Эдгээр шалгалтууд нь ерөнхийдөө 5-6 сарын хугацаанд болдог, ерөнхий хуваарийн дагуу.",
      },
      v: [
        "https://www.youtube.com/watch?v=5iHS0s5ROfM&list=PLL4hZ1kpl1foDh0XuzFepJ-NSz90jKmpe",
        "https://www.youtube.com/watch?v=4YpJaVyLzFo",
        "https://www.youtube.com/watch?v=S_TdmHME730&list=PLLyqGZba3Xv0wWd7EifQ4lrGviljHgGzA",
      ],
      m: [
        "Cambridge O-Level сурах гарын авлага",
        "Өмнөх шалгалтууд",
        "Текст ном ба дасгал ажлын ном",
      ],
      s: "Шалгалтын бүтэц: Хичээл тус бүрийн шалгалт: Жишээ нь, Математик, Хими, Биологи, Газарзүй, Англи хэл зэрэг. Зургийн материал, график, карт дээр ажиллах (Зарим хичээлд). Теорем, дүрэм, шалгалтын жишиг даалгавар, эссе бичих (Түүх, хэл зүй, англи хэл гэх мэт).",
      p: "Шалгалтын дүнг A - E* хүртэлх үнэлгээгээр тогтооно. A* хамгийн өндөр оноо, E хамгийн доод оноо.",
      g: "Сургуулиуд эсвэл шалгалтын төвд бүртгүүлнэ.",
      r: "https://www.cambridgeinternational.org/",
      f: "50-150$-ийн хооронд төлбөртэй байдаг, хичээлийн тоо болон шалгалтын төрөлтэй холбоотойгоор ялгаатай байж болно.",
      a: [
        "Тестийн өдрөөс өмнө сайн унтаж амрах.",
        "Бүртгэлийн хуудас, бичгийн хэрэгсэл, зураг төсөл болон шаардлагатай материалуудыг нягтлах.",
        "Өөртөө итгэлтэй бай. Итгэлтэй байдал таныг стресст автуулахгүй, илүү төвлөрөхөд тусална.",
      ],
      day: "Шалгалтын дараа 2-3 сарын дараа хариу гардаг бөгөөд үүний дараа таны оноог албан ёсны бичигт хүргүүлнэ.",
      i: "14-16 насныхан (ихэнхдээ 10-12 ангийн сурагчид).",
      wn: "Дэлхийн түвшний академик үнэлгээнд хамрагдахыг хүссэн сурагчид. Англи, Канад, АНУ, Австрали, Сингапур гэх мэт улсуудын их сургуулиудад өргөдөл илгээх сурагчид.",
    },
    {
      n: "DELF",
      d: "DELF (Diplôme d'Études en Langue Française) Гадаад иргэдийн франц хэлний мэдлэгийг баталгаажуулах зорилгоор Францын Боловсролын яамнаас олгодог албан ёсны диплом. Энэхүү шалгалт нь түвшинээс хамааран өөр байх бөгөөд насан туршид хүчин төгөлдөр байдаг. A1: Анхан шатны харилцааны чадвар; энгийн үг хэллэг, өдөр тутмын үйл ажиллагааны талаар ойлгох, хариулах чадвар. A2: Өдөр тутмын байнгын нөхцөл байдалд амжилттай харилцах чадвар. B1: Бие даасан байдлаар хэл ашиглах чадвар, санаа бодлоо илэрхийлэх, хялбар текст ойлгох чадвар. B2: Нарийн мэргэжлийн болон академик түвшинд Франц хэлийг хэрэглэх чадвар, цогц мэдээлэл ойлгох болон илэрхийлэх чадвар.",
      c: [
        "Түвшнээ тодорхойлох: CEFR түвшний тест өгч, өөрийн мэдлэгийн түвшинг тодорхойлох.",
        "Хичээлийн материал ашиглах: DELF-ийн гарын авлага, онлайн тест, аудио, видео материал ашиглах.",
        "Суралцах стратеги: Сонсох, унших, бичих, ярих 4 чадвараа жигд сайжруулах.",
        "Мэргэжлийн багшаар хичээллэх: Зөвлөгөө авах, дутагдалтай талдаа анхаарах. Улс болгоны Alliance Française төвүүд мэрэгжилийн зөвөлгөө болон хэрэгтэй сурах бичгээр хангаж өгдөг.",
      ],
      w: [
        {
          n: "TV5Monde",
          w: "https://langue-francaise.tv5monde.com",
          d: "Франц хэлийг сурах, шалгалтад бэлдэх материал.",
        },
        {
          n: "Français Facile",
          w: "https://francaisfacile.rfi.fr/fr/",
          d: "Франц хэлний шалгалтын бэлтгэл.",
        },
        {
          n: "Bonjour de France",
          w: "https://bonjourdefrance.com",
          d: "Франц хэлний сургалт.",
        },
        {
          n: "Prep My Future",
          w: "https://prepmyfuture.com/en",
          d: "DELF шалгалтанд зориулсан бэлтгэл.",
        },
        {
          n: "Global Exam",
          w: "https://global-exam.com/en",
          d: "DELF шалгалтын тест.",
        },
        {
          n: "Quizlet",
          w: "https://quizlet.com/latest",
          d: "Франц хэлийг сурах материал.",
        },
        {
          n: "Conjuguemos",
          w: "https://conjuguemos.com",
          d: "Франц хэлний дасгал.",
        },
      ],
      l: [
        "Alliance Française d’Oulan-Bator / Монгол - Францын төв дээр шалгалт өгдөг. Тухайн газраас хуваарь гаргаж өгдөг.",
      ],
      v: ["https://youtu.be/JTCzrSvm_p0", "https://youtu.be/ARbOwNPBGM4"],
      f: "200-300'000₮",
      m: ["DELF activités", "ABC DELF", "Edito"],
      s: "Шалгалтын бүтэц: Сонсох (Compréhension de l'oral): Тодорхой бичлэг эсвэл ярилцлагыг сонсоод асуултад хариулах. Унших (Compréhension des écrits): Текст уншаад ойлгох чадварыг шалгах. Бичих (Production écrite): Тодорхой сэдвээр эссэ, захиа эсвэл тайлбар бичих. Ярих (Production orale): Өөрийгөө танилцуулах, санал бодлоо хуваалцах, эсвэл тодорхой нөхцөл байдлын талаар ярих.",
      p: "Нийт оноо: 100. Тэнцэх оноо: 50 (хэдий ч 4 хэсгийн аль нэгээс 25-аас доош оноо авбал тэнцэхгүй.)",
      g: "Монгол-Франц төвтэй холбогдож хэрэгтэй бичиг баримт, шалгалтын төлбөрийг авчирна.",
      r: "https://www.facebook.com/afobmongolie",
      a: [
        "Бичиг баримтуудаа шалгаж, харандаа баллуур зэрэг шаардлагатай бичиг хэргийн зүйлээ бэлднэ.",
        "Шалгалт авах газраа хоцролгүй, эхлэхээс 30 минутын өмнө ирнэ.",
        "Сайн унтаж амраж, хөнгөн хооллоод ирээрэй.",
        "Зохион байгуулагчийн зааврыг маш сайн сонс.",
        "Аль болох тайван байхыг хичээгээрэй.",
      ],
      day: "Шалгалтын дүнг тухайн газраас мэдэгдэх бөгөөд дипломыг франц улсын элчин сайдаас хүлээн авдаг.",
      i: "Франц болон Канад улс луу явахаар төлөвлөсөн гадаад улсын иргэд.",
      wn: "Франц эсвэл Канад улс руу сурахаар болон цагаачлахаар явах гэж буй хүн бүхэнд.",
    },
    {
      n: "DALF",
      d: "DALF (Diplôme Approfondi de Langue Française) шалгалт нь Франц хэлний ахисан түвшний шалгалт бөгөөд CEFR (Common European Framework of Reference for Languages) системийн B2 түвшнээс дээшхийг баталгаажуулдаг. DELF шалгалтаас ялгаатай нь DALF нь зөвхөн C1 ба C2 түвшний шалгалтуудыг хамардаг. Энэ шалгалтыг амжилттай өгснөөр франц хэлээр ажиллах, суралцах эсвэл амьдрах орчинд ахисан түвшний чадвараа батлах боломжтой. C1: Франц хэлний мэргэжлийн түвшний хэрэглээг батлахад зориулагдсан. Францад бакалаврын дараах түвшний (магистр, доктор) сургалтад элсэн суралцахад шаардлагатай. C2: Франц хэлний хамгийн өндөр түвшний хэрэглээг батлах.",
      c: [
        "Түвшнээ тодорхойлох: CEFR түвшний тест өгч, өөрийн мэдлэгийн түвшинг тодорхойлох.",
        "Хичээлийн материал ашиглах: DELF-ийн гарын авлага, онлайн тест, аудио, видео материал ашиглах.",
        "Суралцах стратеги: Сонсох, унших, бичих, ярих 4 чадвараа жигд сайжруулах.",
        "Мэргэжлийн багшаар хичээллэх: Зөвлөгөө авах, дутагдалтай талдаа анхаарах. Улс болгоны Alliance Française төвүүд мэргэжилийн зөвөлгөө болон хэрэгтэй сурах бичгээр хангаж өгдөг.",
      ],
      w: [
        {
          n: "France Education International",
          w: "https://www.france-education-international.fr",
          d: "Франц хэлний боловсролын шалгалттай холбоотой мэдээлэл.",
        },
        {
          n: "GlobalExam",
          w: "https://global-exam.com",
          d: "DALF шалгалтанд зориулсан онлайн бэлтгэл.",
        },
        {
          n: "TV5Monde",
          w: "https://langue-francaise.tv5monde.com",
          d: "Франц хэлний тест болон аудио материал.",
        },
      ],
      l: [
        "Alliance Française d’Oulan-Bator / Монгол - Францын төв дээр шалгалт өгдөг. Тухайн газраас хуваарь гаргаж өгдөг.",
      ],
      v: ["https://youtu.be/KBP-hnA-9AI", "https://youtu.be/CmX8Xgu4nuo"],
      m: [
        "DALF C1/C2 250 Activités",
        "Objectif DALF C1-C2",
        "Réussir le DALF C1 et C2",
      ],
      s: "Шалгалтын бүтэц: 1. Унших (Compréhension écrite): Текст уншиж, ойлголтыг шалгана. 2. Сонсох (Compréhension orale): Аудио бичлэг сонсоод түүнийг ойлгосон эсэхийг шалгана. 3. Бичих (Production écrite): Зохиомж, санал шүүмжлэл бичих эсвэл гүнзгий анализ хийх. 4. Ярих (Production orale): Сэдэвт анализ хийж ярилцах эсвэл сэдэвчилсэн илтгэл тавих.",
      p: "Шалгалт нь 100 оноо авч болох бөгөөд 50 оноо хүрсэн тохиолдолд тэнцэнэ. Шалгалтын тус бүрийн хэсэгт дор хаяж 5 оноо авах шаардлагатай.",
      g: "Монгол-Франц төвтэй холбогдож хэрэгтэй бичиг баримт, шалгалтын төлбөрийг авчирна.",
      r: "https://www.facebook.com/afobmongolie",
      p: "Түвшин болон авж буй улсаас хамааран шалгалтын төлбөр өөр байж болно.",
      f: "300-400'000₮",
      a: [
        "Бичиг баримтуудаа шалгаж, харандаа баллуур зэрэг шаардлагатай бичиг хэргийн зүйлээ бэлднэ.",
        "Шалгалт авах газраа хоцролгүй, эхлэхээс 30 минутын өмнө ирнэ.",
        "Сайн унтаж амраж, хөнгөн хооллоод ирээрэй.",
        "Зохион байгуулагчийн зааврыг маш сайн сонс.",
        "Аль болох тайван байхыг хичээгээрэй.",
      ],
      day: "Шалгалтын хуваарийг Alliance Française d’Oulan-Bator / Монгол - Францын төвөөс гаргана, шалгалтын дүнг мөн тэндээс мэдэгдэх бөгөөд дипломыг франц улсын элчин сайдаас хүлээн авдаг.",
      i: "Франц хэлний өндөр түвшний мэдлэгтэй хүмүүс. Францын их дээд сургуульд элсэн суралцахыг хүссэн хүмүүс (C1 ба түвшнээс дээш). Францын мэргэжлийн болон академик орчинд ажиллах хүмүүс.",
      wn: "Франц эсвэл албан ёсны хэл нь франц байдаг улс руу сурахаар болон ажиллахаар явах гэж буй хүн бүхэнд.",
    },
    {
      n: "hel-bichgiin-shalgalt",
      d: "Монгол хэл бичгийн шалгалт нь Монгол Улсад их, дээд сургуульд элсэхийн өмнө өгдөг зайлшгүй шаардлагатай шалгалт юм. Энэхүү шалгалт нь сурагчдын эх хэлний мэдлэг, хэрэглээ, унших, бичих чадвар, зөв бичгийн дүрмийн мэдлэгийг шалгах зорилготой. Шалгалт нь үнэ төлбөргүй бөгөөд элсэлтийн ерөнхий шалгалтад бүртгүүлсэн бүх сурагчид үүнийг өгөх ёстой.",
      c: [
        "Үндэсний Бичиг: Үсэгнүүдээ нүдлэх, өдөр бүр тогтмол унших дасгал хийх.",
        "Сурах бичгээс онцгой бичигддэг үгнүүдийг цээжлэх.",
        "Эссэ: Зөв бичих дүрэм дээр анхаарах, жишээ эссэнүүдийг уншиж санаа аваарай.",
        "Эссэ тань өгөддсөн сэдэвтэй таарч байна уу эсэхэд анхаарах.",
        "Эшлэл, баримт дурьдах.",
        "Бичгийн хэлбэр. Төлбөр таарч байна уу эсэхийг шалгах.",
        "Бүтэц найруулга дээрээ анхаараарай.",
        "Үгийн тоондоо болон бичгийн соёл дээрээ мөн анхаараарай.",
        "Хэрэв та бүртгэлээ амжилттай хийсэн эсэхийг шалгахгүй бол цаг хугацаагаа алдах боломжтой.",
      ],
      w: [
        {
          n: "Scribd",
          w: "https://www.scribd.com/document/472926120/эх-хэлний-шалгалтын-жишиг-хэвлэх",
          d: "Эх хэлний шалгалтын жишиг хэвлэх материал.",
        },
      ],
      l: [
        "Бүртгэл хийсний дараа шалгалт өгөх газрыг мэдэгдэнэ. Өөрийн сургууль дээр өгөхгүй харьяа дүүргийн сургууль дээр очиж өгнө.",
      ],
      t: {
        Бүртгэл:
          "Боловсролын үнэлгээний газар мэдээлэл өгнө. ЭЕШ-ын бүртгэлтэй зэрэг болдог.",
        Өдөр: "Боловсролын үнэлгээний газар мэдэгдэнэ.",
        Давтан:
          "8 сарын эхээр явагддаг. Шалгалтын хуваарийг Боловсролын үнэлгээний газар мэдэгдэнэ.",
      },
      v: "https://youtu.be/zQlkrRZAxVc?si=-VT_Q_GzTYPCd24q",
      m: [
        "Монгол хэл бичгийн шалгалтын Туслах багш",
        "Эх хэл бичгийн шалгалтын даалгаварын эмхэтгэл",
      ],
      s: "Шалгалтын бүтэц: 1. Унших чадвар: Өгөгдсөн эхийг уншиж ойлгон асуултанд хариулах. 2. Бичих чадвар: Зөв бичих дүрмийн алдаатай үгийг олж залруулах, үндэсний бичгээс кирилл рүү хөврүүлэн бичих, эссэ бичих.",
      p: "Унших чадвар (20 оноо), Бичих чадвар (30 оноо)",
      g: "Одоогийн төгсөгчид автоматаар бүртгэгддэг, өмнөх оны төгсөгчид өөрсдөө бүртгүүлэх шаардлагатай.",
      r: "www.eec.mn",
      f: "Шалгалтын төлбөр байхгүй.",
      a: [
        "Өөрийн бичиг баримтыг бүрэн эсэхийг шалгаарай.",
        "Шалгалт эхлэхээс цагийн өмнө сургууль дээрээ очиж суудлаа эзлэнэ.",
        "Шалгалтын өмнө сайн амарч, хөнгөн хооллосон байна.",
        "Аль болон шалгалтыг сандарч түгшилгүйгээр тайван өгөөрэй.",
      ],
      day: "Хариу хэзээ гарах: Тухайн жилийн шалгалтын хариуг Боловсролын үнэлгээний газар мэдэгдэнэ. Ойролцоогоор сарын дараа гардаг.",
      i: "ЭЕШ-ын өгөхөөр төлөвлөж буй хүн бүр.",
      wn: "Монголд их сургуульд элсэхээр горилж буй бүх хүмүүс.",
    },
    {
      img: "../assets/gmat.png",
      n: "GMAT",
      d: "GMAT (Graduate Management Admission Test) нь бизнесийн удирдлагын чиглэлээр магистрын зэрэгт суралцах хүсэлтэй хүмүүсийн тоон дүн шинжилгээ, аналитик, уншиж ойлгох, бичих чадварыг үнэлдэг олон улсын шалгалт юм.",
      c: [
        "GMAT нь олон төрлийн чадварыг сорих шалгалт учир гүйцэтгэлийн хэсгүүдийг ялгаж сурах хэрэгтэй.",
        "Бэлдэх төлөвлөгөө гаргахдаа өдөр бүр өөр өөр сэдвээр бэлдэх ба өөрийн сул хэсгийг тодорхойлон тухайн хэсэг дээрээ бага зэрэг илүү цаг гаргаж байх.",
        "Цахим тестүүдийг ашиглах.",
        "Албан ёсны сурах бичиг хэрэглэх.",
        "Ижил зорилготой хүмүүстэй холбоо тогтоох.",
        "Цагийн менежмент маш сайн байх менежментдээ тааруулж тогтмол жишиг даалгавар хийх.",
        "Сурсан мэдлэгээ гүнзгийрүүлж судлах.",
        "Аль болох эртнээс тогтмол хуваарь, зөв системтэйгээр бэлдэж эхлэх.",
        "Монгол улсад шалгалт авах газар ганцхан байдаг тул танхимаар өгөх бол суудлаа эрт захиалах (1-2 сарын өмнө).",
        "Хэрэв сургалтад суух бодолтой байвал Монгол улсын хэмжээнд GMAT шалгалтад бэлддэг сургалт тун цөөхөн тул өөр улсын цахим сургалтуудыг судалж үзэх.",
      ],
      w: [
        {
          n: "MBA.com (албан ёсны хуудас)",
          w: "https://www.mba.com",
          d: "Албан ёсны сайт",
        },
        {
          n: "GMATCLUB.com",
          w: "https://www.gmatclub.com",
          d: "GMAT клубын вэбсайт",
        },
        {
          n: "Manhattan prep",
          w: "https://www.manhattanprep.com",
          d: "Манхэттен бэлдүүлэг",
        },
        {
          n: "Kaplan GMAT",
          w: "https://www.kaptest.com/gmat",
          d: "Каплан GMAT бэлдүүлэг",
        },
        {
          n: "Target Test Prep",
          w: "https://www.targettestprep.com",
          d: "Target Test Prep бэлдүүлэг",
        },
        {
          n: "GMAT experience",
          w: "https://www.gmatexperience.com",
          d: "GMAT туршлага",
        },
      ],
      l: ["Байршил: English Academy of MUST, шалгалт өгөх тухтай газар."],
      t: {
        Бүртгэл: "MBA.com вэбсайтаар бүртгүүлнэ.",
        Төрөл: "Танхимын болон цахим шалгалт.",
        Өдөр: "Өдөр бүр.",
      },
      v: [
        "https://youtu.be/xO_qm_iC3VU",
        "https://youtu.be/J_h0evJvN2g",
        "https://youtu.be/WYur92NI7YA",
      ],
      m: [
        "The official guide for GMAT review",
        "Manhattan prep GMAT strategy",
        "Kaplan GMAT prep plus (2021)",
      ],
      p: "Шалгалтын нийт үнэлгээ нь 200–800 оноо бөгөөд Quantitative болон Verbal хэсгийн хариултанд үндэслэнэ. AWA болон IR хэсгүүд нь тус тусад нь үнэлэгддэг.",
      s: "Analytical Writing Assessment (AWA): Цаг: 30 минут. Зорилго: Тухайн өгөгдсөн санаа эсвэл үзэл бодлыг шүүмжлэх буюу батлах шаардлагатай. Логик болон үндэслэлтэй аргументуудыг хэрэглэж, бичгийн чадвараа харуулна. Integrated Reasoning (IR): Цаг: 30 минут. 12 асуулттай бөгөөд эдгээр нь дүрслэл, график болон мэдээллийн хамт анализ хийх чадварыг шалгадаг. Quantitative Reasoning (QR): Цаг: 62 минут. 31 тооцоолон бодох асуулттай. Төрөл бүрийн тооцоолол, алгебр болон геометрийн асуудлууд багтдаг. Verbal Reasoning (VR): Цаг: 65 минут. 36 асуулттай бөгөөд уншиж ойлгох, логик шүүлт хийх болон өгүүлбэрийн бүтэц, утга учрыг тодорхойлох чадварыг шалгадаг.",
      p: "Цахим шалгалт-275$, Танхимын шалгалт-250$",
      g: "MBA.com вебсайтыг ашиглан хэрэглэгчийн бүртгэл нээх. Иргэний үнэмлэх дээрх мэдээлэлтэй заавал таарах шаардлагатай. Шалгалт өгөх огноо болон байршлыг сонгох. Шалгалтын төлбөрийг олон улсын карт, дансаар төлөх.",
      r: "https://www.mba.com",
      a: [
        "Шалгалт эхлэхээс 30 минутын өмнө зөөврийн компьютер, цаас харандаа зэргийг бэлдсэн байх.",
        "Цагийн менежмент хэрэгжүүлэх.",
        "Танхимаар шалгалт өгч байгаа тохиолдолд өөрийн иргэний үнэмлэхийг авч очих.",
        "Математик болон үнэлгээний хэсгүүдэд цаасан дээр тэмдэглэл хөтлөх.",
      ],
      day: [
        "Verbal болон Quantitative хэсгүүдийн оноо нь 7-10 хоногийн дотор гарна.",
        "AWA болон IR хэсгүүдийн оноо нь тухайн шалгалт өгсөн өдөртөө гарах болно.",
        "Цахим шалгалтын хариу 7 хоногийн дотор гарна.",
      ],
      i: "Баклаврын зэрэгтэй эсвэл их сургуульд суралцаж буй магистрын түвшинд суралцах хүсэлтэй хүмүүс.",
      wn: "Гадаадад бизнесийн болон менежментийн чиглэлээр элсэн суралцах хүсэлтэй хүмүүс.",
    },
  ];
  const mergejil = [
    {
      id: 1,
      ner: "./career/careers_people_man_accountant_finance_skin3.svg",
      ajil: "нягтлан бодогч",
    },
    {
      id: 2,
      ner: "./career/careers_people_man_dentist_skin3.svg",
      ajil: "шүдний эмч",
    },
    {
      id: 3,
      ner: "./career/careers_people_man_lab_tech_laboratory_technician_skin3.svg",
      ajil: "лабораторийн техникч",
    },
    {
      id: 4,
      ner: "./career/careers_people_man_marketing_manager_skin3.svg",
      ajil: "маркетинг",
    },
    {
      id: 5,
      ner: "./career/careers_people_man_medical_nurse_skin3.svg",
      ajil: "сувилагч",
    },
    {
      id: 6,
      ner: "./career/careers_people_man_paramedic_skin3.svg",
      ajil: "түргэн тусламж",
    },
    {
      id: 7,
      ner: "./career/careers_people_man_sales_finance_manager_skin3.svg",
      ajil: "Санхүүгийн менежер",
    },
    {
      id: 8,
      ner: "./career/careers_people_man_senior_librarian_skin3.svg",
      ajil: "номын санч",
    },
    {
      id: 9,
      ner: "./career/careers_people_man_software_developer_skin3.svg",
      ajil: "хөгжүүлэгч",
    },
    {
      id: 10,
      ner: "./career/careers_people_man_teacher_professor_skin3.svg",
      ajil: "багш",
    },
    {
      id: 11,
      ner: "./career/careers_people_woman_art_director_skin3.svg",
      ajil: "Урлан бүтээгч",
    },
    {
      id: 12,
      ner: "./career/careers_people_woman_engineer_architect_skin3.svg",
      ajil: "архитекторч",
    },
    {
      id: 13,
      ner: "./career/careers_people_woman_executive_director_manager_skin3.svg",
      ajil: "менежер",
    },
    {
      id: 14,
      ner: "./career/careers_people_woman_hr_human_resources_manager_skin3.svg",
      ajil: "Хүний нөөцийн менежер",
    },
    {
      id: 15,
      ner: "./career/careers_people_woman_lawyer_skin3.svg",
      ajil: "хуульч",
    },
    {
      id: 16,
      ner: "./career/careers_people_woman_medical_doctor_skin3.svg",
      ajil: "эмч",
    },
    {
      id: 17,
      ner: "./career/careers_people_woman_professor_teacher_skin3.svg",
      ajil: "профессор",
    },
    {
      id: 18,
      ner: "./career/careers_people_woman_public_relations_specialist_skin3.svg",
      ajil: "олон нийтийн харилцааны мэргэжилтэн",
    },
    {
      id: 19,
      ner: "./career/careers_people_woman_senior_psychologist_skin3.svg",
      ajil: "сэтгэл зүйч",
    },
    {
      id: 20,
      ner: "./career/careers_people_woman_vet_veterinarian_skin3.svg",
      ajil: "малын эмч",
    },
    {
      id: 21,
      ner: "./career/und.svg",
      ajil: "шийдэгдээгүй",
    },
  ];
  const colors = [
    "#D9864A",
    "#56B097",
    "#6CA9D8",
    "#85B494",
    "#BE89E5",
    "#E699A6",
    "#D9553E",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const adviceList = [
    "Тэнцсэн сургуулиудаасаа хангалттай тэтгэлэг авч, гартаа 10000-15000$ бэлэн байлгаарай.",
    "IELTS-ийн 4 хэсгүүд (Сонсох, Унших, Бичих, Ярих) болон тэдгээрийн форматын талаар мэдэж аваарай.",
    "Хүссэн тэтгэлэгт хамрагдахдаа шаардагдах оноогоо тодорхойлж, бэлтгэлээ түүндээ төвлөрүүлээрэй.",
    "Хичээлийн дүн нь олон тэтгэлэгт хамрагдах гол шалгуур болдог тул өндөр оноо авахад сайн анхаараарай.",
    "Та өөрийн манлайлал, багаар ажиллах чадвараа харуулсан үйл ажиллагаануудад оролцож, өөрийгөө харуулаарай.",
    "Тэтгэлэгт хамрагдах ярилцлагын нийтлэг асуултуудад дадлага хийж, өөртөө итгэх итгэлийг нэмэгдүүлж, харилцааны ур чадвараа сайжруулахын тулд бусадтай ярилцаарай.",
    "Өргөдөл гаргах эцсийн хугацаа, шаардлагатай бичиг баримт, илгээх огноог төлөвлөгч эсвэл дижитал хуанли ашиглан зохион байгуулалттай байгаарай.",
    "Өөрт тохирсон мэргэжлийг олохын тулд манай мэргэжлүүдийн буланг сонирхож, цааш сайн судлаад үзээрэй!",
    "Өргөдөл шалгагч таны хичээлээс гадуурх үйл ажиллагааны эхний 3-5-ыг анхааралтай хардаг учраас өөрт тань хамгийн чухал зүйлсээ эхэнд бичээрэй. (нийт 10 slot байгаа жүү.)",
    "Таныг бусад өргөдөл гаргагчдаас ялгарах сайн дурын ажил, дадлага, тусгай төсөл гэх мэт өвөрмөц туршлагуудыг онцлон тэмдэглээрэй.",
    "Хэрвээ та 12 анги бол танд зүгээр суух цаг байхгүй шүү. Их сургуулиудын судалгаагаа сайн хийж, хувийн бэлтгэлээ хангаж, эсээнүүдээ чанартай бичээрэй.",
    "Өөрийнхөө ахиц дэвшилд дүн шинжилгээ хийж, илүү их дадлага хийх шаардлагатай газруудыг тодорхойлоорой.",
    "Татгалзсан хариу авсан ч гэсэн цоглог, зорилгодоо тууштай байгаарай. Өргөдлөө сайжруулахын тулд туршлагаа ихэсгээрэй.",
    "Өнөөдөртөө сайн амраад авбал яаж байна? Өөрийгөө хайрлаад, хийхийг хүссэн бүхнээ хийдэг өдөр шүү! Өөртөө сайн цаг гаргаарай.",
    "Төлөвлөгөөгөө сайн гаргаж, түүнийгээ сайн биелүүлээрэй!",
    "Хаана сурахаа сураагүй байгаа бол битгий санаа зовоорой! Бүгдэнг нь л туршаад үзэх хэрэгтэй!",
    "Эсээн дээрээ өөрийгөө хэрхэн сайн харуулахыг мэдэхгүй байвал өдрийн тэмдэглэл хөтлөөд үзээрэй.",
    "Таны сонгосон мэргэжлийг хамгийн сайн заадаг их сургуулиудыг улс хамаарахгүй сонгоорой.",
    "Өнөөдөр бодсон төлөвлөсөн ажлынхаа ард амжилттай гараад инээгээрэй!",
    "Сонгосон мэргэжил тань ирээдүйд эрэлттэй эсэхийг сайн судлаарай.",
    "Америкийн их сургуулиуд руу өргөдлөө илгээхийг төлөвлөж байвал Common App дээр ажиллаж, сураарай.",
    "SAT шалгалтад бэлдэхийг хүсэж байвал Khan Academy дээр дасгал ажлуудыг сайн ажиллаж, Bluebook-ийн mock тестүүдийг өгөөд үзэх хэрэгтэй.",
    "Collegeboard сайт дээр сайн ажиллаж, зорилгуудаа бататгаарай.",
    "Стандартчлагдсан шалгалтууддаа өдөр бүр тодорхой хугацаанд бие даан бэлдэж байгаарай!",
    "Хамтарч бэлдэх ханьтай болвол таны идэвхтэй байх түвшин ихэснэ шүү!",
    "Чамайг аз жаргалтай болгодог зүйлсийг илүү их хийгээрэй, юу мэдэрч байгаагаа бусдад хэлж байгаарай, аливааг эхлүүлэхдээ төгс эхлүүлэх гэж бүү хүлээ, шууд хийж эхлээрэй, бас аль болох өөрийгөө эрхлүүлээрэй, яагаад гэвэл амьдрал маш хурдан бас завгүй.",
    "Хэлний түвшингээ сайн нэмэгдүүлж, өөрийн хүрээллээ тэр хэлээр дүүргэж, сайн дадлага хийгээрэй.",
    "Дэлхийн амжилттай явж байгаа хүмүүсийн 88% нь их дээд сургуульд сурж байсан тул та ч гэсэн сураад амжилттай болвол ямар вэ?",
    "Сурахаар болсон улсын онцлог болон уламжлалыг эртнээс судлаж, сурах нь чухал шүү!",
    "Өргөдлөө хэзээ хаагдахыг сайн судлаж, цагаасаа хоцрохгүй байх нь чухал шүү!",
    "Recommendation Letters-ийг таныг хамгийн сайн мэддэг багшуудаараа бичүүлээрэй.",
    "Зориж буй их сургуулийнхаа веб хуудсыг тултал нь ухаж уншаарай.",
    "Чамайг урамшуулах, дэмжих найз нөхөдтэй болох нь чухал шүү.",
    "Хичээлээс гадна өөрийгөө тайвшруулах хоббигоо хөгжүүлээрэй.",
    "Өөрийн суралцах хэв маягт тохирсон стратеги боловсруулж, хэрэгжүүлээрэй.",
    "Хийх ажлуудаа жагсааж, хугацаанд нь хийхэд анхаарал хандуулаарай.",
    "Бүх зүйл амархан бүтэхгүй ч, тэвчээртэй байж, зорилгодоо хүрээрэй.",
    "Хичээлд сайн оролцож, багш нараасаа зөвлөгөө авахад цаг гаргаарай.",
    "Хичээлийнхээ хажуугаар эрүүл хооллох, дасгал хөдөлгөөн хийхээ мартаж болохгүй шүү.",
    "Өөрийн санхүүгээ зөв зохицуулж хуримтлал үүсгээрэй, хэрэг болно шүү.",
    "Суралцах болон амралтын цагаа зөв зохицуулаарай.",
    "Эссе бичихдээ өөрийн түүх, хүсэл тэмүүллийг тодорхой гаргаарай.",
    "CV дээрээ амжилт, туршлагаа тодорхой бичихэд анхаараарай.",
    "Зорилгодоо хүрэхийн тулд өөрийгөө урамшуулж, шаргуу хөдөлмөрлөөрэй.",
    "Мэргэжлийн салбарт туршлага хуримтлуулах дадлагад хамрагдаж, CV-гээ баяжуул.",
    "Нийгэмд эерэг нөлөө үзүүлэх сайн дурын ажилд идэвхтэй оролцсоноор эсээ тань баяжна.",
    "Хичээлээс гадуур шинэлэг төслүүдэд оролцож, шинэ зүйлс суралцаж дуртай зүйлээ олоорой.",
    "Дурлаж хийх зүйл чинь хаа нэгтээ байгаа, олоод ав.",
  ];
  useEffect(() => {
    // Retrieve the saved index from localStorage or start from the beginning
    const savedIndex = localStorage.getItem("adviceIndex");
    const today = new Date().toLocaleDateString();

    if (savedIndex) {
      const { index, date } = JSON.parse(savedIndex);
      if (date === today) {
        setCurrentIndex(index);
      } else {
        showNextAdvice(index);
      }
    } else {
      showNextAdvice(0);
    }
  }, []);

  const showNextAdvice = (index) => {
    const nextIndex = (index + 1) % adviceList.length;
    setCurrentIndex(nextIndex);

    // Save the new index and date in localStorage
    const today = new Date().toLocaleDateString();
    localStorage.setItem(
      "adviceIndex",
      JSON.stringify({ index: nextIndex, date: today })
    );
  };
  useEffect(() => {
    document.title = "Tmull ・ Эхлэл";
    const today = new Date();
    setFormattedDate(today.toLocaleDateString());
    const academy = async () => {
      await axios
        .get(`https://tmull-be.vercel.app/news?page=1&limit=5`)
        .then((response) => {
          console.log(response?.data?.data);
          setNews(response?.data?.data);
        });
      await axios.get(`https://tmull-be.vercel.app/acad`).then((response) => {
        console.log(response?.data);
        setAcad(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/event`).then((response) => {
        console.log(response?.data);
        setEvent(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/Sch`).then((response) => {
        console.log(response?.data);
        setSchool(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/prof`).then((response) => {
        console.log(response?.data);
        setProf(response?.data);
      });
    };
    academy();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const exam = [{ name: "IELTS", date: [{ year: "2024", date: [{}] }] }];
  const right = () => {
    document.getElementById("content").scrollBy(600, 0);
  };
  const left = () => {
    document.getElementById("content").scrollBy(-600, 0);
  };
  const rightt = () => {
    if (pos < 9) {
      document.getElementById("contentt").scrollBy(400, 0);
      setQw(!qw);
      setPos(pos + 1);
    }
  };
  const leftt = () => {
    if (pos !== 0) {
      document.getElementById("contentt").scrollBy(-400, 0);
      setQw(!qw);
      setPos(pos - 1);
    }
  };
  return (
    <div className="mt-24 lg:mt-28">
      <div className="w-full overflow-hidden relative bg-[#d1ead250] dark:bg-[#181818] border-2 border-gray-200 dark:border-neutral-700 dark:text-[#eeffee] bg-repeat rounded-3xl flex flex-col justify-center items-center py-3 sm:py-4 md:py-8 lg:py-12 xl:py-16 2xl:py-20 text-center duration-300 ease-in-out font-semibold">
        <div className="absolute w-full h-[150%]">
          <div class="snowflake">❅</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❄</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❄</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❄</div>
        </div>
        <div className="px-2 bg-white dark:bg-black text-[#4BAB4D] rounded md:rounded-md text-xs sm:md:text-lg xl:text-xl 2xl:text-2xl duration-300 ease-in-out">
          #4 Чанартай боловсролыг дэмжих
        </div>
        <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl duration-300 ease-in-out">
          <div className="">Илүү дээшээ тэмүүлж</div>
          <div className="text-[#4BAB4D]">Ирээдүйгээ бүтээцгээе!</div>
        </div>
        <div className="font-bold w-8/12 sm:w-6/12 md:w-10/12 uppercase md:text-lg xl:text-xl mt-1 2xl:text-2xl lg:mt-4 duration-300 ease-in-out">
          Хүн бүр өөрийн хүссэн <br className="md:hidden" /> бүх зүйлийг сурч
          чадна.
        </div>
        <div className="w-[90%] sm:w-[85%] md:w-[80%] lg:w-[75%] xl:w-[70%] 2xl:w-[65%] text-sm mt-1 lg:text-md xl:text-base text-justify lg:mt-4 font-medium duration-300 ease-in-out">
          “Tmull” веб хуудасны гол зорилго нь тогтвортой хөгжлийн 4, 8, 9, 10,
          17-р зорилт болох “Чанартай боловсролыг дэмжих”, “Эдийн засгийн өсөлт,
          баталгаат ажлын байрыг бий болгох”, “Инновац болон дэд бүтцийн
          нэмэгдүүлэх”, “Тэгш бус байдлыг бууруулах”, “Хөгжлийн төлөөх
          түншлэлийг бэхжүүлэх“ гэх 5-н зорилтуудыг хэрэгжүүлэн их дээд
          сургуулиудад сурах хүсэл тэмүүлэлтэй болон ирээдүйн мэргэжлээ
          гүнзгийрүүлэн судлах хүсэлтэй сурагч, оюутан, залуусыг хэрэгтэй
          мэдлэг, мэдээллээр хангаж, туслахад оршино. Үүгээр дамжуулан
          боловсролын тэгш бус байдлыг бууруулах, мэдээллийн хүртээмжийг
          нэмэгдүүлэх тухай олон нийтэд нөлөөлөх зорилготой.
        </div>
      </div>
      <div className="my-6 transition-all ease-in-out duration-300 delay-0 transform">
        <div className="w-full flex justify-center items-center lg:py-4 py-3 overflow-hidden relative">
          <div className="w-full flex flex-col justify-between items-center h-full absolute opacity-75">
            <div class="relative flex flex-row z-0">
              <div class="animate-marquee whitespace-nowrap flex flex-row">
                <span className="xl:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>

              <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex flex-row">
                <span className="xl:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>
            </div>
            <div class="relative flex flex-row">
              <div class="animate-marquee whitespace-nowrap flex flex-row">
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>

              <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex flex-row">
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>
            </div>
          </div>
          <div className="h-full animate-marquee3 whitespace-nowrap dark:text-white justify-center items-center  z-10  my-4 md:my-6 lg:my-8 xl:my-10 2xl:my-14 w-full text-center flex flex-row text-md md:text-lg lg:text-xl 2xl:text-2xl transition-all ease-in-out duration-300 delay-0 transform">
            {adviceList[currentIndex]}
          </div>
        </div>
        <div className=" w-full text-sm lg:text-xl md:text-md transition-all ease-in-out duration-300 delay-0 transform">
          <div className="right-0 absolute dark:text-white">
            Энэ өдрийн зөвлөгөө
          </div>
        </div>
      </div>
      <div className="text-center flex justify-center items-center flex-col lg:flex-row dark:text-white">
        <div>
          <div className="text-[#4BAB4D] text-3xl lg:text-5xl xl:text-7xl capitalize font-black">
            Бидний нэг болох
          </div>
          <div className=" font-light">Хамтдаа илүү дээшээ тэмүүлцгээе</div>
          <Link
            to="https://forms.gle/E28fRpaEunKLHxtHA"
            target="_blank"
            className="mt-4 hover:text-[#4BAB4D] cursor-pointer"
          >
            👉🏻 ЯГ ОДОО БҮРТГҮҮЛЭХ 👈🏻
          </Link>
        </div>
        <div>
          <img className="h-80" src={tmuleh} alt="" />
        </div>
      </div>
      <div className="flex flex-col items-start justify-center my-16 ">
        <div className=" flex flex-row items-center justify-between mb-4 w-full relative">
          <div className=" flex flex-row items-center justify-center">
            <div className="text-sm md:text-base rounded-md bg-[#4BAB4D] text-white px-1 mr-1">
              ГАЛ
            </div>
            <Link
              to="niitleluud"
              className="cursor-pointer text-lg md:text-xl lg:text-2xl transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
            >
              Мэдээ мэдээлэл
            </Link>
          </div>
          <Link
            to="niitleluud"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <div className="flex flex-row gap-4 overflow-x-auto w-full dark:text-white">
          {news.length > 0 ? (
            <>
              {news.map((el) => (
                <Link to={"niitleluud/" + el.pl}>
                  <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative overflow-hidden group/item cursor-pointer">
                    <img
                      className="w-80 h-80 lg:w-96 lg:h-96 object-cover absolute"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                        el.img
                      }
                      alt=""
                    />
                    <div className="m-2 flex flex-row right-0 absolute text-sm text-white gap-0.5">
                      <div className=" flex flex-row w-min items-center justify-center gap-1 backdrop-blur-sm rounded-l px-1 bg-[#4BAB4D]">
                        <svg
                          className="h-3.5 fill-white"
                          viewBox="0 0 512 512"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                          />
                        </svg>
                        {el.s}
                      </div>
                      <div className="backdrop-blur-sm rounded-r px-1 bg-black/40">
                        {el.rt}
                      </div>
                    </div>

                    <div className="from-[#000000] to-[#00000000] w-full bg-gradient-to-t h-[90%] group-hover/item:h-full group-hover/item:opacity-100 opacity-90 absolute bottom-0 transition-all ease-in-out duration-500 delay-0 transform"></div>
                    <div className="absolute bottom-0 p-2 w-full text-white">
                      <div className="font-bold text-lg md:text-xl">
                        {el.ti}
                      </div>
                      <div className="group-hover/item:h-10 overflow-hidden h-5 group-hover/item:whitespace-normal whitespace-nowrap font-normal text-[#cecece] w-full transition-all ease-in-out duration-700 delay-0 transform text-ellipsis group-hover/item:text-clip group-hover/item:overflow-auto text-xs lg:text-sm">
                        {el.di}
                      </div>
                      <div className="font-normal text-[#7c7c7c] truncate group-hover/item:h-5 h-0 lg:group-hover/item:h-6 group-hover/item:opacity-100 opacity-0 transition-all ease-in-out duration-300 delay-0 transform">
                        {formatDistanceToNow(new Date(el.cAt), {
                          addSuffix: true,
                        })
                          .replace(
                            "less than a minute ago",
                            "минут хүрэхгүй хугацааны өмнө"
                          )
                          .replace("about ", "")
                          .replace("days", "өдрийн")
                          .replace("hours", "цагийн")
                          .replace("hour", "цагийн")
                          .replace("minutes", "минутын")
                          .replace("minute", "минутын")
                          .replace("months", "сарын")
                          .replace("month", "сарын")
                          .replace("ago", "өмнө")}{" "}
                        | {el.au.map((el) => el).join(", ")}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </>
          ) : (
            <div className="flex flex-row gap-4 w-full">
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full my-16">
        <div className="font-semibold flex flex-row items-center justify-center">
          <div className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 transform dark:text-white">
            Ойртож буй шалгалтууд
          </div>
        </div>
        <div class="output"></div>
        <div className="relative w-full flex justify-center">
          <div className="hidden lg:flex w-full h-full absolute bg-gradient-to-r from-[#FFFFFF] dark:from-[#000000] via-[#ffffff00] dark:via-[#00000000] to-[#FFFFFF] dark:to-[#000000] z-20 pointer-events-none"></div>
          <div class="hidden lg:flex lg:w-[43%] xl:w-[38%] 2xl:w-[33%] pointer-events-none flex-row justify-between absolute items-center z-30 h-full">
            <button onClick={leftt}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 90C128.761 90 131 87.7614 131 85C131 82.2386 128.761 80 126 80V90ZM40.4645 81.4645C38.5118 83.4171 38.5118 86.5829 40.4645 88.5355L72.2843 120.355C74.2369 122.308 77.4027 122.308 79.3553 120.355C81.308 118.403 81.308 115.237 79.3553 113.284L51.0711 85L79.3553 56.7157C81.308 54.7631 81.308 51.5973 79.3553 49.6447C77.4027 47.692 74.2369 47.692 72.2843 49.6447L40.4645 81.4645ZM126 80L44 80V90L126 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
            <button onClick={rightt}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44 90C41.2386 90 39 87.7614 39 85C39 82.2386 41.2386 80 44 80V90ZM129.536 81.4645C131.488 83.4171 131.488 86.5829 129.536 88.5355L97.7157 120.355C95.7631 122.308 92.5973 122.308 90.6447 120.355C88.692 118.403 88.692 115.237 90.6447 113.284L118.929 85L90.6447 56.7157C88.692 54.7631 88.692 51.5973 90.6447 49.6447C92.5973 47.692 95.7631 47.692 97.7157 49.6447L129.536 81.4645ZM44 80L126 80V90L44 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="contentt"
            className="scroll-smooth dark:text-white lg:no-scrollbar flex flex-row gap-8 lg:gap-16 xl:gap-36 font-bold overflow-x-auto py-4 pb-6 w-full overflow-y-hidden snap-x transition-all ease-in-out duration-300 delay-0 transform"
          >
            <div className="snap-center sm:ml-[28%] md:ml-[36%] lg:ml-[41%] transition-all ease-in-out duration-500 delay-0 transform">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 transform delay-150"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500  delay-150 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#4b76ab] to-[#d1deea7d] via-[#00000000] from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img src={sat} alt="" className="h-full object-cover" />
                    </div>

                    <div className="text-xl transition-all ease-in-out duration-150 delay-0 transform xl:text-2xl 2xl:text-2xl 3xl:text-3xl flex justify-center flex-col items-center">
                      <div className=" flex flex-row items-center gap-2 scale-75">
                        12-р сарын 7
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="pt-2 flex flex-row items-center gap-2">
                        3-р сарын 8
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="pt-2 flex flex-row items-center gap-2 scale-75">
                        5-р сарын 3
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="pt-2 flex flex-row items-center gap-2 scale-75">
                        6-р сарын 7
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 transition-all ease-in-out duration-150 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2025-03-08" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back dark:to-[#4b76ab] to-[#d1deea7d] via-[#00000000] from-[#00000000] bg-gradient-to-t h-[400px] lg:h-[450px] absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img src={sat} alt="" className="h-full object-cover" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      SAT (Scholastic Assessment Test) бол АНУ-д коллежид
                      элсэхэд өргөн хэрэглэгддэг стандартчилсан шалгалт юм.
                      Зарим их дээд сургуулиуд бакалаврын элсэлт авахдаа үүнийг
                      шаарддаг.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] relative overflow-hidden dark:to-[#9e161697] to-[#e37c7c42] via-transparent from-[#00000000] bg-gradient-to-t lg:h-[450px] dark:border-neutral-700 dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={ielts}
                        alt=""
                        className="h-full object-cover p-1 bottom-0"
                      />
                    </div>
                    <div className="text-base transition-all ease-in-out duration-150 transform w-full p-4 gap-2 flex flex-col">
                      <div className="lg:block hidden">
                        <div className="w-full flex justify-end">12-р сар</div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            4
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            5
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            6
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            7
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            13
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            14
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            15
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            18
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            20
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            21
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            25
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            27
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            28
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end">1-р сар</div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            3
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            10
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            11
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            15
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            16
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            17
                          </div>
                          <div className="text-center bg-black dark:bg-white/20 rounded px-1 opacity-75">
                            18
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            22
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            24
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            25
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            29
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            31
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end">2-р сар</div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            1
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            7
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            8
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            12
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            13
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            14
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            15
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            19
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            21
                          </div>
                          <div className="text-center bg-black dark:bg-[#4BAB4D] rounded px-1 opacity-75">
                            22
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 transition-all ease-in-out duration-150 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2025-01-22" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>

                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#9e161697] to-[#e37c7c42] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={ielts}
                        alt=""
                        className="h-full object-cover p-1 bottom-0"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      IELTS (International English Language Testing System) нь
                      англи хэлний чадварын шалгалт бөгөөд гадаадад суралцах бас
                      ажиллаж амьдрах хүсэлтэй хүмүүст хамааралтай шалгалт юм.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#9e6c16b8] to-[#e29d255b] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex  justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={topik}
                        alt=""
                        className="dark:hidden object-cover h-full"
                      />
                      <img
                        src={topikdr}
                        alt=""
                        className="hidden dark:block object-cover h-full"
                      />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-150 transform xl:text-2xl 2xl:text-2xl 3xl:text-3xl flex justify-center flex-col items-center">
                      <div className="pt-2 flex flex-row items-center gap-2">
                        4-р сарын 13
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        5-р сарын 11
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        10-р сарын 19
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        11-р сарын 16
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 transition-all ease-in-out duration-150 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2025-04-13" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#9e6c16b8] to-[#e29d255b] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={topik}
                        alt=""
                        className="dark:hidden object-cover h-full"
                      />
                      <img
                        src={topikdr}
                        alt=""
                        className="hidden dark:block object-cover h-full"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      TEST OF PROFIENCY IN KOREA буюу TOPIK-ийн шалгалт нь
                      Солонгос хэлний төвшин тогтоох шалгалт юм. Солонгос улсын
                      боловсролын явцыг үнэлэх академиас хариуцан зохион
                      байгуулдаг гэрчилгээ олгодог шалгалт юм.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#244523bc] to-[#3d733b4c] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={hsk}
                        alt=""
                        className="dark:hidden object-cover h-full"
                      />
                      <img
                        src={hskdr}
                        alt=""
                        className="hidden dark:block object-cover h-full"
                      />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-150 transform xl:text-2xl 2xl:text-3xl 3xl:text-4xl flex flex-col justify-between items-center">
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        1-р сарын 12
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2">
                        3-р сарын 9
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        4-р сарын 12
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        5-р сарын 17
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 transition-all ease-in-out duration-150 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2025-03-09" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] dark:to-[#244523bc] to-[#3d733b4c] via-transparent from-[#00000000] bg-gradient-to-t lg:h-[450px] absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={hsk}
                        alt=""
                        className="dark:hidden object-cover h-full"
                      />
                      <img
                        src={hskdr}
                        alt=""
                        className="hidden dark:block object-cover h-full"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      HSK буюу Hanyu Shuiping Kaoshi нь нээлттэй хятад хэлний
                      анхан шатнаас ахисан түвшин хүртэл стандартыг тогтоодог
                      албан ёсны шалгалт юм. HSK нь анхан, дунд, гүнзгий гэсэн
                      гурван шатнаас бүрдэнэ.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#ffffff4b] to-[#00000043] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={jlpt}
                        alt=""
                        className="dark:hidden object-cover h-full"
                      />
                      <img
                        src={jlptdr}
                        alt=""
                        className="hidden dark:block object-cover h-full"
                      />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-150 transform xl:text-2xl 2xl:text-3xl 3xl:text-4xl flex flex-col justify-between items-center">
                      <div className="py-2 flex flex-row items-center gap-2">
                        7-р сард
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 opacity-40 scale-75">
                        12-р сард
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 transition-all ease-in-out duration-150 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2025-07-01" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#ffffff4b] to-[#00000043] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={jlpt}
                        alt=""
                        className="dark:hidden object-cover h-full"
                      />
                      <img
                        src={jlptdr}
                        alt=""
                        className="hidden dark:block object-cover h-full"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      JLPT (Japanese-Language Proficiency Test) Япон хэлний
                      түвшин тогтоох шалгалтыг Япон болон гадаадад эх хэлээр
                      ярьдаггүй хүмүүсийн япон хэлний мэдлэгийг үнэлж,
                      баталгаажуулах зорилгоор зохион байгуулдаг.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#6b88267b] to-[#b3ff0062] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={Goethe}
                        alt=""
                        className=" object-cover h-full"
                      />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-150 transform xl:text-2xl 2xl:text-2xl 3xl:text-3xl flex flex-col justify-between items-center">
                      <div className="py-2 flex flex-row items-center gap-2">
                        A1:2-р сарын 4
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2">
                        A2:2-р сарын 11
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2">
                        B1:1-р сарын 21
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2">
                        B2:1-р сарын 28
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-150 transform dark:invert"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 transition-all ease-in-out duration-150 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2025-1-21" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#6b88267b] to-[#b3ff0062] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={Goethe}
                        alt=""
                        className=" object-cover h-full"
                      />
                    </div>{" "}
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      Гёте-Институтийн шалгалтууд нь сонирхсон бүх хүнд нээлттэй
                      бөгөөд насанд хүрсэн эсэх, мөн ХБНГУ-ын иргэншилтэй
                      байхаас үл хамааран шалгалт өгөх боломжтой.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#115c13a3] to-[#b9ffbb] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-4 h-16 lg:h-20">
                      <img src={ush} alt="" className=" object-cover h-full" />
                    </div>
                    <div className=" text-xs xl:text-sm transition-all ease-in-out duration-150 transform w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          5-р анги
                        </div>
                        <div className="text-white flex flex-col gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Монгол хэл – 5.22
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Математик – 5.24
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Байгаль шинж. – 5.26
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          9-р анги
                        </div>
                        <div className="text-white flex flex-col gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Монгол хэл – 5.25
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Математик – 5.26
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Байгалийн у. – 5.29
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Нийгмийн у. – 5.30
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12-р анги
                        </div>
                        <div className="text-white flex flex-col gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Монгол хэл – 5.24
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Математик – 5.25
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Сонгосон агу. – 5.26
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded px-1">
                            Гадаад хэл – 5.29
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap p-4 text-center text-lg transition-all ease-in-out duration-150 transform xl:text-xl 2xl:text-2xl 3xl:text-3xl gap-x-1 xl:gap-x-2 text-black">
											<div className="flex items-center">Дараагийн</div>
											<div className="flex items-center">шалгалт</div>
											<div className="flex items-center">болоход</div>
											<div>
												<div className="text-xl lg:text-2xl xl:text-3xl transition-all ease-in-out duration-150 transform py-1 px-1 2xl:px-2 bg-black rounded-lg text-[#FFFFFF]">
													47 өдөр
												</div>
											</div>
											<div className="flex items-center">үлдлээ</div>
										</div> */}
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#115c13a3] to-[#b9ffbb] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-4 h-16 lg:h-20">
                      <img src={ush} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      Бага, суурь, бүрэн дунд боловсролын сургалтын хөтөлбөрийн
                      хэрэгжилтийн түвшинг тогтоох, төгсөх ангийн суралцагчдын
                      мэдлэг, чадварыг бодитойгоор үнэлэх зорилготой.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#115c13a3] to-[#b9ffbb] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-3 h-16 lg:h-20">
                      <img src={esh} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="xl:text-lg transition-all ease-in-out duration-150 transform w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.05
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Түүх
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Англи Хэл
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.06
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Газар Зүй
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Нийгэм
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Хими
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.07
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Биологи
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Математик
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Орос хэл
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.08
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Физик
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Монгол Хэл
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#115c13a3] to-[#b9ffbb] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-4 h-20 lg:h-24">
                      <img src={esh} alt="" className=" object-cover h-full" />
                    </div>{" "}
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      ЭЕШ (Элсэлтийн Ерөнхий Шалгалт) нь Монгол улсын их, дээд
                      сургуулиудад элсэхэд ашиглагддаг стандартчилсан шалгалт юм
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-150 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-150 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#1f4763a3] to-[#b9daff] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={oge} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="xl:text-lg transition-all ease-in-out duration-150 transform w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.21-22
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Иностр. языки
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.27
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Биология
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Химия
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Инфо.
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.30
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            География
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Физика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.03
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Русский язык
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.06
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Математика
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#1f4763a3] to-[#b9daff] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={oge} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      ОГЭ бол 9-р ангийн сурагчид өгдөг Оросын ерөнхий шалгалт
                      юм. ОГЭ-д тэнцэх нь ерөнхий боловсролын гэрчилгээ авах
                      эрхийг нээлттэй болгодог ба нийт дөрвөн хичээлийг давах
                      шаардлагатай: Орос хэл, математик болон үлдсэн хоёр нь
                      сурагчийн сонголт байдаг.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center mr-[28%] sm:mr-[30%] md:mr-[36%] lg:mr-[41%]">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front dark:card-front h-[400px] lg:h-[450px] dark:to-[#1f4763a3] to-[#b9daff] via-transparent from-[#00000000] bg-gradient-to-t dark:border-neutral-700 relative overflow-hidden dark:bg-[#181818] border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full  flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={ege} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="xl:text-lg transition-all ease-in-out duration-150 transform w-full px-4 pb-4 xl:gap-1.5 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.23
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Геогр.
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Литер.
                          </div>
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Химия
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.28
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Русский язык
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.31
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Математика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.04
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Физика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.07-08
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Информатика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.11
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-[#4BAB4D] rounded-lg px-2">
                            Иностр. языки
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back dark:card-back h-[400px] lg:h-[450px] dark:to-[#1f4763a3] to-[#b9daff] via-transparent from-[#00000000] bg-gradient-to-t absolute top-0 w-56 overflow-hidden xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    <div className="w-full  flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={ege} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg  transition-all ease-in-out duration-150 transform text-justify">
                      ЕГЭ бол 11-р ангийн сурагчид өгдөг Оросын улсын шалгалт
                      юм. ЕГЭ-д тэнцэхэд математик болон орос хэлний
                      шалгалтуудыг давснаар гэрчилгээгээ авах юм.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-semibold flex flex-row items-center justify-center">
        <div className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 transform dark:text-white">
          Шалгалтаа илүү сайн мэдэж авцгаая
        </div>
      </div>
      <div className="mt-8 gap-8 flex overflow-x-auto w-full dark:text-white">
        {exams.map((el, index) => (
          <div>
            <Link
              to={"/exam/" + el.n.toLowerCase()}
              className="flex flex-col justify-center items-center group/item transition-all ease-in-out duration-300 delay-150 gap-4 lg:h-96 lg:w-96 w-80 h-80 border-2 rounded-lg border-gray-200 dark:border-neutral-700 p-4 relative overflow-hidden"
            >
              {el.img ? (
                <>
                  {el.imgdr ? (
                    <>
                      <img
                        src={el.img}
                        className="absolute -z-10 group-hover/item:opacity-0 opacity-100 object-contain h-full w-full transition-all ease-in-out duration-300 p-4 dark:hidden"
                        alt=""
                      />
                      <img
                        src={el.imgdr}
                        className="absolute -z-10 group-hover/item:opacity-0 opacity-100 object-contain h-full w-full transition-all ease-in-out duration-300 p-4 hidden dark:block"
                        alt=""
                      />
                    </>
                  ) : (
                    <img
                      src={el.img}
                      className="absolute -z-10 group-hover/item:opacity-0 opacity-100 object-contain h-full w-full transition-all ease-in-out duration-300 p-4"
                      alt=""
                    />
                  )}
                  {el.imgdr ? (
                    <img
                      src={el.imgdr}
                      className="object-fill h-full blur-3xl group-hover/item:blur-2xl opacity-30 absolute transition-all ease-in-out duration-300"
                      alt=""
                    />
                  ) : (
                    <img
                      src={el.img}
                      className="object-fill h-full blur-3xl group-hover/item:blur-2xl opacity-30 absolute transition-all ease-in-out duration-300"
                      alt=""
                    />
                  )}
                </>
              ) : (
                <>
                  <div className="object-fill blur-3xl group-hover/item:blur-2xl opacity-30 absolute bg-black/15 dark:bg-white/15 h-full w-full transition-all ease-in-out duration-300"></div>
                  <div className="absolute -z-10 text-5xl dark:text-white text-black text-center items-center flex justify-center group-hover/item:opacity-0 opacity-100 object-contain h-full w-full p-4 ">
                    {el.n === "hel-bichgiin-shalgalt"
                      ? "Хэл Бичгийн Шалгалт"
                      : el.n === "yesh"
                      ? "ЭЕШ"
                      : el.n}
                  </div>
                </>
              )}
              <div className="absolute -z-10 group-hover/item:opacity-100 opacity-30 object-contain h-full w-full transition-all ease-in-out duration-300 p-4">
                {el.n === "hel-bichgiin-shalgalt"
                  ? "Хэл Бичгийн Шалгалт"
                  : el.n === "yesh"
                  ? "ЭЕШ"
                  : el.n}
              </div>
              <div className=" text-justify group-hover/item:block group-hover/item:opacity-100 opacity-0 mt-[150%] group-hover/item:mt-0 transition-all ease-in-out duration-300 relative">
                {/* <div className="text-center opacity-30 group-hover/item:opacity-100 transition-all ease-in-out duration-300">
                {el.n}
              </div> */}
                <div className="max-h-48 overflow-hidden">{el.d}</div>
                <button className="rounded-full border-2 hover:border-[#4BAB4D] dark:hover:border-[#4BAB4D] hover:text-[#4BAB4D] border-gray-200 dark:border-neutral-700 transition-all ease-in-out duration-300 text-center mt-2 px-2">
                  See more →
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center justify-center my-14 w-full relative">
        <div className=" flex flex-row items-center justify-center mb-4 relative w-full">
          <div className="absolute">
            <svg
              width="268"
              height="102"
              viewBox="0 0 268 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M226.911 42.2033C227.325 42.354 227.783 42.1406 227.934 41.7267C228.084 41.3128 227.871 40.8551 227.457 40.7045C224.639 39.6787 223.186 36.5625 224.211 33.7442L224.231 33.6906C224.381 33.2767 224.168 32.8191 223.754 32.6684C223.34 32.5178 222.883 32.7312 222.732 33.1451C221.695 35.993 218.502 37.4451 215.654 36.4086C215.24 36.2579 214.782 36.4713 214.632 36.8852C214.481 37.2991 214.695 37.7568 215.109 37.9074L215.187 37.936C218.016 38.9657 219.475 42.0939 218.445 44.923C218.295 45.3369 218.508 45.7946 218.922 45.9452C219.336 46.0959 219.793 45.8824 219.944 45.4685C220.97 42.6503 224.093 41.1776 226.911 42.2033Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.9715 25.7442C72.4591 25.4849 72.6442 24.8794 72.385 24.3918C72.1257 23.9042 71.5202 23.719 71.0325 23.9783C67.7122 25.7438 63.5894 24.4833 61.824 21.163L61.7904 21.0998C61.5311 20.6122 60.9256 20.427 60.438 20.6863C59.9504 20.9456 59.7652 21.5511 60.0245 22.0387C61.8085 25.3939 60.4821 29.5881 57.1269 31.3721C56.6392 31.6314 56.4541 32.2369 56.7134 32.7245C56.9727 33.2122 57.5782 33.3973 58.0658 33.138L58.1584 33.0887C61.4916 31.3165 65.6304 32.5818 67.4026 35.915C67.6619 36.4026 68.2674 36.5877 68.7551 36.3285C69.2427 36.0692 69.4278 35.4637 69.1685 34.976C67.4031 31.6558 68.6512 27.5097 71.9715 25.7442Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.2909 84.1501C44.5777 84.983 45.4854 85.4258 46.3183 85.139C47.1512 84.8522 47.594 83.9444 47.3072 83.1115L46.3758 80.4066C44.9373 76.229 47.1578 71.6764 51.3354 70.2379L54.1481 69.2694C54.981 68.9826 55.4237 68.0749 55.1369 67.242C54.8501 66.4091 53.9424 65.9663 53.1095 66.2531L50.2968 67.2216C46.1192 68.6601 41.5666 66.4396 40.1281 62.262L39.1287 59.3594C38.8419 58.5265 37.9341 58.0837 37.1012 58.3705C36.2683 58.6573 35.8255 59.5651 36.1123 60.398L37.1118 63.3006C38.5502 67.4782 36.3298 72.0308 32.1522 73.4693L29.4076 74.4143C28.5747 74.7011 28.1319 75.6089 28.4187 76.4418C28.7055 77.2747 29.6133 77.7174 30.4462 77.4306L33.1908 76.4856C37.3684 75.0472 41.921 77.2676 43.3595 81.4452L44.2909 84.1501Z"
                fill="#4BAB4D"
              />
            </svg>
          </div>
          <Link
            to="noots/hiih_zuils"
            className="text-xl font-semibold md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 cursor-pointer transform dark:text-white"
          >
            To Do List
          </Link>
          <Link
            to="noots/hiih_zuils"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <Link
          to="noots/hiih_zuils"
          className="sm:text-lg text-center items-center md:hidden flex  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
        >
          Дэлгэрэнгүй{" "}
          <svg
            className="h-4"
            viewBox="0 0 366 359"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1639_2733)">
              <path
                d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
              />
            </g>
            <defs>
              <clipPath id="clip0_1639_2733">
                <rect width="366" height="359" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <div className="flex flex-wrap w-full lg:flex-row justify-evenly m-4 transition-all ease-in-out duration-300 delay-0 transform">
          <Link
            to="noots/hiih_zuils/0"
            className="text-md lg:text-xl font-bold xl:text-2xl text text-center shadow-lg-bt hover:drop-shadow-lg dark:drop-shadow-lg w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-0 duration-500 ease-in-out bg-repeat todo1 hover:text-white dark:hover:text-white  hover:bg-[#4BAB4D] dark:text-white cursor-pointer dark:border-neutral-700 my-2"
          >
            10 ба түүнээс доош
          </Link>
          <Link
            to="noots/hiih_zuils/1"
            className="text-md lg:text-xl font-bold xl:text-2xl text text-center shadow-lg-bt hover:drop-shadow-lg dark:drop-shadow-lg w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-0 duration-500 ease-in-out bg-repeat todo1 hover:text-white dark:hover:text-white  hover:bg-[#4BAB4D] dark:text-white cursor-pointer dark:border-neutral-700 my-2"
          >
            11-р анги
          </Link>
          <Link
            to="noots/hiih_zuils/2"
            className="text-md lg:text-xl font-bold xl:text-2xl text text-center shadow-lg-bt hover:drop-shadow-lg dark:drop-shadow-lg w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-0 duration-500 ease-in-out bg-repeat todo hover:text-white dark:hover:text-white  hover:bg-[#4BAB4D] dark:text-white cursor-pointer dark:border-neutral-700 my-2"
          >
            12-р анги
          </Link>
          <Link
            to="noots/hiih_zuils/3"
            className="text-md lg:text-xl font-bold xl:text-2xl text text-center shadow-lg-bt hover:drop-shadow-lg dark:drop-shadow-lg w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-0 duration-500 ease-in-out bg-repeat todo1 hover:text-white dark:hover:text-white  hover:bg-[#4BAB4D] dark:text-white cursor-pointer dark:border-neutral-700 my-2"
          >
            Завсар жил
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-16 dark:text-white">
        <div className=" flex flex-col items-center justify-center mb-4 w-full relative">
          <Link
            to="noots/surgalt"
            className="text-xl font-semibold md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform"
          >
            Бэлдэхэд туслах <br /> сургалтууд
          </Link>
          <Link
            to="noots/surgalt"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <Link
            to="noots/surgalt"
            className="sm:text-lg text-center items-center md:hidden flex  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <div className=" flex flex-row  overflow-x-auto w-full gap-4">
          {news.length > 0 ? (
            <>
              {acad.map((ell, index) => (
                <div
                  onClick={() => {
                    window.location.pathname = `/noots/surgalt/${ell._id}`;
                  }}
                >
                  <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform">
                    <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                      <img
                        className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:border-neutral-700 border-2"
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Acad%2F" +
                          ell.img
                        }
                        alt=""
                      />
                      <div className="text-[#656366] dark:text-[#9f9da0] dark:border-neutral-700 border-l-2 border-t-2 p-2 absolute  rounded-tl-lg bg-white dark:bg-black bottom-0 right-0">
                        {ell.ca.join(", ")}
                      </div>
                    </div>

                    <div className="w-full rounded-lg">
                      <div className="font-bold text-xl lg:text-2xl truncate">
                        {ell.na}
                      </div>
                      <div className="font-normal text-md lg:text-lg text-[#7c7c7c] truncate">
                        {ell.di.replace(/\s*\/N\s*/g, "\n")}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className=" flex flex-row  w-full gap-4">
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-16 ">
        <div className=" flex flex-col items-center justify-center mb-4 w-full relative">
          <Link
            to="sain_duriin_ajil"
            className="text-xl font-semibold md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
          >
            Хичээлээс гадуурх <br />
            үйл ажиллагаа
          </Link>
          <div className="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] transition-all ease-in-out duration-300 delay-0 transform">
            Чиглэлээ сонгон уу
          </div>
          <Link
            to="sain_duriin_ajil"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <Link
            to="sain_duriin_ajil"
            className="sm:text-lg text-center items-center md:hidden flex  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>

        <div className="flex flex-row w-full xl:flex-wrap xl:justify-center items-center my-4 overflow-y-auto relative xl:h-[50vh] 2xl:h-full dark:text-white">
          {/* {mergejil.map((el, key) => {
            // console.log(el);
            return (
              <a
                href="/sain_duriin_ajil/emch"
                className="flex justify-center items-center group/item cursor-pointer p-8"
              >
                <div
                  key={key}
                  className="rounded-2xl w-32 h-32 ring-4 ring-[#656366] rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#4BAB4D] transition delay-150 duration-500 ease-in-out"
                ></div>
                <div className="h-32 w-32 flex items-end justify-end capitalize  transition-all ease-in-out duration-500 absolute opacity-25 group-hover/item:rotate-0 rotate-45 z-0 delay-150 group-hover/item:z-10 text-right group-hover/item:opacity-100 drop-shadow">
                  <div class="rounded-2xl px-2 backdrop-blur-sm bg-[#ffffff60] dark:bg-[#00000060] w-full">
                    {el.ajil}
                  </div>
                </div>
                <img
                  src={el.ner}
                  className="w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out grayscale "
                  alt=""
                />
              </a>
            );
          })} */}
          {prof.map((el, key) => {
            // console.log(el);
            return (
              <div
                onClick={() => {
                  window.location.pathname = `/sain_duriin_ajil/${el._id}`;
                }}
                className="flex justify-center items-center group/item cursor-pointer p-8"
              >
                <div
                  key={key}
                  className="rounded-2xl w-32 h-32 ring-4 ring-[#656366] rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#4BAB4D] transition delay-150 duration-500 ease-in-out"
                ></div>
                <div className="h-32 w-32 flex items-end justify-end capitalize  transition-all ease-in-out duration-500 absolute opacity-25 group-hover/item:rotate-0 rotate-45 z-0 delay-150 group-hover/item:z-10 text-right group-hover/item:opacity-100 drop-shadow">
                  <div class="rounded-2xl px-2 backdrop-blur-sm bg-[#ffffff60] dark:bg-[#00000060] w-full">
                    {el.na}
                  </div>
                </div>
                <img
                  src={el.img}
                  className="w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out grayscale "
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
      <div class="flex flex-col items-center justify-center my-8 lg:my-16">
        <div class=" flex flex-col items-center justify-center mb-4 overflow-visible relative w-full">
          <Link
            to="noots/uil_ajillagaa"
            class="text-xl font-semibold md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
          >
            Ойртож буй арга хэмжээ болон <br /> сайн дурын үйл ажиллагаанууд
          </Link>
          <div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform">
            Эдгээр үйл ажиллагаанд идэвхтэй оролцоорой
          </div>
          <Link
            to="noots/uil_ajillagaa"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <Link
            to="sain_duriin_ajil"
            className="sm:text-lg text-center items-center md:hidden flex  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <div class="w-full flex items-center justify-center relative">
          <div class="hidden lg:flex w-[95%] 2xl:w-[85%] pointer-events-none flex-row justify-between absolute items-center z-30 h-full">
            <button onClick={left}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 90C128.761 90 131 87.7614 131 85C131 82.2386 128.761 80 126 80V90ZM40.4645 81.4645C38.5118 83.4171 38.5118 86.5829 40.4645 88.5355L72.2843 120.355C74.2369 122.308 77.4027 122.308 79.3553 120.355C81.308 118.403 81.308 115.237 79.3553 113.284L51.0711 85L79.3553 56.7157C81.308 54.7631 81.308 51.5973 79.3553 49.6447C77.4027 47.692 74.2369 47.692 72.2843 49.6447L40.4645 81.4645ZM126 80L44 80V90L126 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
            <button onClick={right}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44 90C41.2386 90 39 87.7614 39 85C39 82.2386 41.2386 80 44 80V90ZM129.536 81.4645C131.488 83.4171 131.488 86.5829 129.536 88.5355L97.7157 120.355C95.7631 122.308 92.5973 122.308 90.6447 120.355C88.692 118.403 88.692 115.237 90.6447 113.284L118.929 85L90.6447 56.7157C88.692 54.7631 88.692 51.5973 90.6447 49.6447C92.5973 47.692 95.7631 47.692 97.7157 49.6447L129.536 81.4645ZM44 80L126 80V90L44 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="content"
            class="relative w-full flex gap-4 snap-x snap-mandatory overflow-x-auto scroll-smooth md:items-center dark:text-white"
          >
            {event.length > 0 ? (
              <>
                {event.map((el) => (
                  <Link
                    to={"noots/uil_ajillagaa/" + el._id}
                    class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]"
                  >
                    <div>
                      <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                        <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                          <div class="flex flex-col items-center justify-between">
                            <div class="flex flex-col xl:flex-row items-center ">
                              <div class="w-80 lg:w-72 2xl:w-80">
                                <img
                                  class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg"
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Events%2F" +
                                    el.i
                                  }
                                  alt=""
                                />
                              </div>
                              <div class="lg:ml-2 xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                                <div class="text-lg xl:text-xl lg:hidden xl:block">
                                  {el.n}
                                </div>
                                <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex lg:hidden xl:flex">
                                  <div>
                                    {new Date(el.sT).getUTCDay() === 1
                                      ? "Дав, "
                                      : new Date(el.sT).getUTCDay() === 2
                                      ? "Мяг, "
                                      : new Date(el.sT).getUTCDay() === 3
                                      ? "Лха, "
                                      : new Date(el.sT).getUTCDay() === 4
                                      ? "Пүр, "
                                      : new Date(el.sT).getUTCDay() === 5
                                      ? "Баа, "
                                      : new Date(el.sT).getUTCDay() === 6
                                      ? "Бям, "
                                      : new Date(el.sT).getUTCDay() === 7
                                      ? "Ням, "
                                      : ""}
                                    {new Date(el.sT).getUTCMonth() +
                                      1 +
                                      "-р сарын " +
                                      new Date(el.sT).getUTCDate() +
                                      ", " +
                                      new Date(el.sT).getUTCFullYear()}
                                  </div>
                                  <div>
                                    {new Date(el.sT).getUTCHours() +
                                      ":" +
                                      (new Date(el.sT).getUTCMinutes() === 0
                                        ? "00"
                                        : new Date(el.sT).getUTCMinutes())}
                                  </div>
                                </div>
                                <div class="xl:text-md h-40 overflow-hidden relative">
                                  <div className="bg-gradient-to-t from-white dark:from-black via-transparent to-transparent h-full w-full absolute"></div>
                                  {el.nf}
                                </div>
                              </div>
                            </div>
                            <div class="mt-4 w-full hidden xl:block ">
                              <button
                                onClick={() => {
                                  if (el.r === "n") {
                                    return 0;
                                  } else {
                                    window.open(el.r);
                                  }
                                }}
                                class={
                                  el.r === "n"
                                    ? "p-2 text-[#4BAB4D] rounded-lg w-full bg-[#027f0018] cursor-not-allowed"
                                    : "p-2 bg-[#4BAB4D] rounded-lg w-full text-white cursor-pointer"
                                }
                              >
                                Бүртгүүлэх{" "}
                                {el.r === "n" ? " шаардлагагүй " : ""}
                              </button>
                            </div>
                          </div>
                          <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                            <div class="hidden lg:block xl:hidden ">
                              <div class="text-md lg:text-lg xl:text-xl">
                                {el.n}
                              </div>
                              <div class="text-[#656366] text-sm 2xl:text-base flex flex-row justify-between">
                                <div>
                                  {new Date(el.sT).getUTCDay() === 1
                                    ? "Дав, "
                                    : new Date(el.sT).getUTCDay() === 2
                                    ? "Мяг, "
                                    : new Date(el.sT).getUTCDay() === 3
                                    ? "Лха, "
                                    : new Date(el.sT).getUTCDay() === 4
                                    ? "Пүр, "
                                    : new Date(el.sT).getUTCDay() === 5
                                    ? "Баа, "
                                    : new Date(el.sT).getUTCDay() === 6
                                    ? "Бям, "
                                    : new Date(el.sT).getUTCDay() === 7
                                    ? "Ням, "
                                    : ""}
                                  {new Date(el.sT).getUTCMonth() +
                                    1 +
                                    "-р сарын " +
                                    new Date(el.sT).getUTCDate() +
                                    ", " +
                                    new Date(el.sT).getUTCFullYear()}
                                </div>
                                <div>
                                  {new Date(el.sT).getUTCHours() +
                                    ":" +
                                    (new Date(el.sT).getUTCMinutes() === 0
                                      ? "00"
                                      : new Date(el.sT).getUTCMinutes())}
                                </div>
                              </div>
                            </div>
                            {el.d.map((ell, index) => (
                              <div class=" flex flex-wrap justify-between  w-full gap-y-1">
                                <div class="">{Object.keys(ell)}:</div>
                                <div
                                  style={{
                                    backgroundColor:
                                      colors[index % colors.length],
                                  }}
                                  class="text-sm px-1 xl:p-1 rounded-lg text-white flex justify-center items-center"
                                >
                                  {ell[Object.keys(ell)[0]]}
                                </div>
                              </div>
                            ))}
                            <button
                              onClick={() => {
                                if (el.r === "n") {
                                  return 0;
                                } else {
                                  window.open(el.r);
                                }
                              }}
                              class={
                                el.r === "n"
                                  ? "block xl:hidden p-2 text-[#4BAB4D] rounded-lg w-full bg-[#027f0018] cursor-not-allowed"
                                  : "block xl:hidden p-2 bg-[#4BAB4D] rounded-lg w-full text-white cursor-pointer"
                              }
                            >
                              Бүртгүүлэх {el.r === "n" ? " шаардлагагүй " : ""}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center ">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block ">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center ">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block ">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center ">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block ">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center ">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block ">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center ">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block ">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-16 ">
        <div className=" flex flex-col items-center justify-center mb-4 w-full relative">
          <Link
            to="noots/sur_dataset"
            className="text-xl md:text-2xl font-semibold lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
          >
            Топ Сургуулиудын <br /> Мэдээлэл
          </Link>
          <Link
            to="noots/sur_dataset"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <Link
            to="noots/sur_dataset"
            className="sm:text-lg text-center items-center md:hidden flex  md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <div className=" flex flex-row scroll-smooth overflow-x-auto w-full gap-4">
          {school.map((el) => (
            <Link to={"noots/sur_dataset/" + el._id} className="group/item ">
              <div className="group-hover/item:rounded-3xl rounded-full overflow-hidden transition-all ease-in-out duration-700 delay-0 lg:group-hover/item:mx-8">
                <div className="w-80 lg:w-96 flex flex-col cursor-pointer justify-center items-center">
                  <div className="relative w-80 h-80 lg:w-96 lg:h-96 flex ">
                    <img
                      className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover brightness-75 group-hover/item:brightness-100 transition-all ease-in-out duration-1000 delay-0"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F" +
                        el.img
                      }
                      alt=""
                    />
                    <div className="bg-gradient-to-t from-[#000000] to-[#00000000] group-hover/item:h-[50%] h-[70%] w-full absolute bottom-0 transition-all ease-in-out duration-1000 delay-0 transform"></div>
                    <div className="absolute text-white  pt-10 text-lg lg:text-xl w-full justify-center items-center flex group-hover/item:drop-shadow-lg group-hover/item:pt-4 transition-all ease-in-out duration-1000 delay-0 transform">
                      <div className="backdrop-blur-sm max-w-[50%] group-hover/item:bg-[#0000001f] group-hover/item:px-2 group-hover/item:rounded-lg transition-all ease-in-out duration-1000 delay-0 transform">
                        RD: {el.appl[3].RD[0].deadline}
                      </div>
                    </div>
                    <div className="text-white  p-8 text-xl md:text-2xl lg:text-3xl flex flex-row w-full h-full justify-between transition-all items-center ease-in-out duration-1000 delay-0 transform group-hover/item:mt-[30%] ">
                      <div className="w-[70%]">{el.Uname}</div>
                      <div className="text-sm lg:text-base flex items-center flex-col right-0 justify-center">
                        <div class="text-yellow-400">
                          <svg
                            class="flex-shrink-0 size-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </div>
                        <div>{el.averageRating}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
