import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import axios from "axios";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";

export const UploadUni = () => {
  const mergejil = [];
  const [nameV, setNameV] = useState("");
  const [disV, setDisV] = useState([]);
  const [locV, setLocV] = useState("");
  const [selectedtype, setSelectedtype] = useState("");
  const [reg, setReg] = useState("");
  const [reg2, setReg2] = useState("");
  const [reg3, setReg3] = useState("");
  const [linkV, setLinkV] = useState("");
  const [dayV, setDayV] = useState("");
  const [day1V, setDay1V] = useState("");
  const [timeV, setTimeV] = useState("");
  const [imgV, setImgV] = useState("");
  const handleRadioChange = (event) => {
    setReg(event.target.value);
  };
  const handleRadioChange2 = (event) => {
    setReg2(event.target.value);
  };
  const handleRadioChange3 = (event) => {
    setReg3(event.target.value);
  };
  console.log(reg2);

  const [fields, setFields] = useState([
    { "Зохион байгуулагч": "Tmull" }, // Initial key-value pair
  ]);
  console.log(fields);

  const handleAddField = () => {
    setFields([...fields, { "": "" }]); // Add an empty object for new key-value pair
  };

  const handleRemoveField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index); // Remove field by index
    setFields(updatedFields);
  };

  const handleKeyChange = (index, newKey) => {
    const updatedFields = fields.map((field, i) => {
      if (i === index) {
        const value = Object.values(field)[0]; // Get the current value
        return { [newKey]: value }; // Update the key with the newKey
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleValueChange = (index, newValue) => {
    const updatedFields = fields.map((field, i) => {
      if (i === index) {
        const key = Object.keys(field)[0]; // Get the current key
        return { [key]: newValue }; // Update the value
      }
      return field;
    });
    setFields(updatedFields);
  };
  ///
  const [fields1, setFields1] = useState([
    { Instagram: "@tmull" }, // Initial key-value pair
  ]);
  console.log(fields1);

  const handleAddField1 = () => {
    setFields1([...fields1, { "": "" }]); // Add an empty object for new key-value pair
  };

  const handleRemoveField1 = (index) => {
    const updatedFields1 = fields1.filter((_, i) => i !== index); // Remove field by index
    setFields1(updatedFields1);
  };

  const handleKeyChange1 = (index, newKey) => {
    const updatedFields1 = fields1.map((field1, i) => {
      if (i === index) {
        const value = Object.values(field1)[0]; // Get the current value
        return { [newKey]: value }; // Update the key with the newKey
      }
      return field1;
    });
    setFields1(updatedFields1);
  };

  const handleValueChange1 = (index, newValue) => {
    const updatedFields1 = fields1.map((field1, i) => {
      if (i === index) {
        const key = Object.keys(field1)[0]; // Get the current key
        return { [key]: newValue }; // Update the value
      }
      return field1;
    });
    setFields1(updatedFields1);
  };
  ///
  const [fields2, setFields2] = useState([
    { Name: "Тэтгэлэг", type: 1, t: "14:00 - 15:00" },
  ]);

  const handleAddField2 = () => {
    setFields2([...fields2, { Name: "", type: 0, t: "" }]);
  };

  const handleRemoveField2 = (index) => {
    const updatedFields2 = fields2.filter((_, i) => i !== index);
    setFields2(updatedFields2);
  };

  const handleInputChange2 = (index, event) => {
    const { name, value } = event.target;
    const updatedFields2 = fields2.map((field2, i) => {
      if (i === index) {
        return {
          ...field2,
          [name]: name === "type" ? parseInt(value, 10) : value, // Convert type to number
        };
      }
      return field2;
    });
    setFields2(updatedFields2);
  };

  const [previewUrl, setPreviewUrl] = useState(null); // New state for image preview
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "Events/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `Events/${imageUpload.name}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImgV(url);
        console.log("Uploaded Image URL:", url); // Log the URL
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageUpload(file);
      setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
    }
  };
  const dataRetriever = async () => {
    if (selectedtype === "none") {
      alert("Төрөл сонгоогүй байна!");
      return;
    } else {
      uploadFile();
      if (imgV !== "") {
        await axios({
          url: `https://tmull-be.vercel.app/event`,
          method: "POST",
          data: {
            Uname: nameV,
            i: imgV.split("Events%2F")[1],
            det: disV,
            l: reg3 === "y" ? "цахим" : locV,
            r: reg === "y" ? linkV : "n",
            sT: `${dayV}T${timeV}:00.000Z`,
            eT:
              day1V === ""
                ? `${dayV}T${timeV}:00.000Z`
                : `${day1V}T${timeV}:00.000Z`,
            t: selectedtype,
            d: fields,
            c: fields1,
            s: fields2,
          },
        }).then((response) => {
          alert(
            response?.data === "successfully created new Event"
              ? "successfully created new Event"
              : "Error"
          );
          if (response?.data === "successfully created new Event") {
            window.location.href = "/noots/uil_ajillagaa";
          }
        });
      }
    }
  };
  useEffect(() => {
    document.title = "Tmull ・ Upload";
  }, []);
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="w-full text-center text-3xl h-16">
        Их, дээд сургууль нэмэх талбар
      </div>

      <form>
        <div class="grid gap-6 mb-6 md:grid-cols-2 w-full">
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Сургуулийн нэр
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Washington University in St. Louis"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Танилцуулга
            </div>
            <textarea
              onChange={(e) => setDisV(e.target.value.split("\n"))}
              type="text"
              className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="WashU гэгддэг бөгөөд 1853 онд байгуулагдсан нэр хүндтэй хувийн судалгааны их сургууль юм. ..."
              required
            ></textarea>

            {/* <div>
              {disV.split("\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </div> */}
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Сургалтын зардал
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="62,982$"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Амжиргааны зардал (Жилийн)
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="20,494$"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Сургуулийн сайт
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="https://wustl.edu/"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Байршил
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Америкийн Нэгдсэн Улс, Missouri муж, St. Louis хот"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Оюутны тоо
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Америкийн Нэгдсэн Улс, Missouri муж, St. Louis хот"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Тэтгэлэг авсан хүний тоо
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Америкийн Нэгдсэн Улс, Missouri муж, St. Louis хот"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Төгсөгчдын хувь
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Америкийн Нэгдсэн Улс, Missouri муж, St. Louis хот"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Гадаад сурагчдын тоо
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Америкийн Нэгдсэн Улс, Missouri муж, St. Louis хот"
              required
            />
          </div>
          <div>
            <div
              for="website"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Байршил
            </div>
            <div className="flex flex-row gap-4 pt-3">
              <div class="flex items-center">
                <input
                  checked={reg3 === "n" || locV !== ""}
                  id="default-radio-6"
                  type="radio"
                  onChange={handleRadioChange3}
                  value="n"
                  name="default-radio-6"
                  className="w-4 h-4 accent-[#4BAB4D] bg-gray-100"
                  required={reg3}
                />
                <div
                  for="default-radio-6"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Танхим
                </div>
              </div>
              <div class="flex items-center">
                <input
                  id="default-radio-5"
                  checked={reg3 === "y"}
                  type="radio"
                  name="default-radio-5"
                  onChange={handleRadioChange3}
                  onClick={() => {
                    setLocV("");
                    setReg3("y");
                  }}
                  value="y"
                  className="w-4 h-4 accent-[#4BAB4D] bg-gray-100"
                  required={reg3}
                />
                <div
                  for="default-radio-5"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Цахим
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Байршлын холбоос
            </div>
            <input
              type="text"
              value={locV}
              onChange={(e) => {
                setLocV(e.target.value);
                setReg3("n");
              }}
              class="bg-gray-50 disabled:opacity-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="https://maps.app.goo.gl/..."
              required={reg3 === "n"}
              disabled={reg3 === "y"}
            />
          </div>

          <div>
            <div
              for="website"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Бүртгэлтэй эсэх
            </div>
            <div className="flex flex-row gap-4 pt-3">
              <div class="flex items-center">
                <input
                  id="default-radio-1"
                  checked={reg === "y" || linkV !== ""}
                  type="radio"
                  name="default-radio"
                  onChange={handleRadioChange}
                  value="y"
                  className="w-4 h-4 accent-[#4BAB4D] bg-gray-100"
                  required={!reg}
                />
                <div
                  for="default-radio-1"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Байгаа
                </div>
              </div>
              <div class="flex items-center">
                <input
                  checked={reg === "n"}
                  id="default-radio-2"
                  type="radio"
                  onChange={handleRadioChange}
                  onClick={() => {
                    setLinkV("");
                    setReg("n");
                  }}
                  value="n"
                  name="default-radio"
                  className="w-4 h-4 accent-[#4BAB4D] bg-gray-100"
                  required={!reg}
                />
                <div
                  for="default-radio-2"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Байхгүй
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Бүртгүүлэх холбоос
            </div>
            <input
              type="text"
              onChange={(e) => {
                setLinkV(e.target.value);
                setReg("y");
              }}
              value={linkV}
              class="bg-gray-50 disabled:opacity-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="https://forms.gle/..."
              required={reg === "y"}
              disabled={reg === "n"}
            />
          </div>
          <div>
            <div
              for="website"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Ганц өдрийн үйл ажиллагаа
            </div>
            <div className="flex flex-row gap-4 pt-3">
              <div class="flex items-center">
                <input
                  id="default-radio-3"
                  checked={reg2 === "y"}
                  type="radio"
                  name="default-radio-1"
                  onChange={handleRadioChange2}
                  value="y"
                  className="w-4 h-4 accent-[#4BAB4D] bg-gray-100"
                  required={!day1V}
                />
                <div
                  for="default-radio-3"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Тийм
                </div>
              </div>
              <div class="flex items-center">
                <input
                  checked={reg2 === "n"}
                  id="default-radio-4"
                  type="radio"
                  onChange={handleRadioChange2}
                  value="n"
                  name="default-radio-1"
                  className="w-4 h-4 accent-[#4BAB4D] bg-gray-100"
                  required={day1V}
                />
                <div
                  for="default-radio-4"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Үгүй
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              for="age"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {reg2 === "y" ? "Өдөр" : "Эхний Өдөр"}
            </div>
            <input
              type="date"
              id="age"
              onChange={(e) => setDayV(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
              required={!reg2}
            />
          </div>
          {reg2 === "y" ? (
            <></>
          ) : (
            <div>
              <div
                for="age"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Сүүлчийн Өдөр
              </div>
              <input
                type="date"
                onChange={(e) => {
                  setDay1V(e.target.value);
                  setReg2("n");
                }}
                class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
                required
              />
            </div>
          )}

          <div>
            <div
              for="age"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Эхлэх Цаг
            </div>
            <input
              type="time"
              onChange={(e) => setTimeV(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
              required
            />
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Төрөл
            </div>
            <select
              name="type"
              id="type"
              value={selectedtype}
              required
              onChange={(e) => setSelectedtype(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option disabled selected value={""}>
                -- сонголт сонгох --
              </option>
              <option value="event">Арга хэмжээ</option>
              <option value="program">Сургалт</option>
              <option value="volunteer">Сайн дурын үйл ажиллагаа</option>
            </select>
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Элсэлт (#-р сарын #)
            </div>

            <div className="grid gap-6 w-full">
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  EA
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  EI
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  EII
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  RD
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  TAI
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  TAII
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
              <div className="flex justify-between gap-2 items-center md:grid-cols-3 w-full">
                <div className="bg-green-600 text-white border-2 border-green-600 text-sm rounded-lg w-min p-2.5">
                  LA
                </div>

                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дуусах хугацаа"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Ярилцлага"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Хариу"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value=""
                  onChange={(e) => handleInputChange2(e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="CSS"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Bachelor</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields.map((field, index) => {
              const key = Object.keys(field)[0]; // Get key for this field
              const value = field[key]; // Get value for this field

              return (
                <div
                  key={index}
                  className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
                >
                  <input
                    type="text"
                    value={key}
                    onChange={(e) => handleKeyChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="Arts and Humanities"
                    required
                  />
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="30"
                    required
                  />
                  <div
                    className="hover:text-red-700 cursor-pointer"
                    onClick={() => handleRemoveField(index)}
                  >
                    (-)
                  </div>
                </div>
              );
            })}

            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Зохион байгуулагч"
              />
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Tmull"
              />
              <div
                onClick={handleAddField}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Magistrate</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields.map((field, index) => {
              const key = Object.keys(field)[0]; // Get key for this field
              const value = field[key]; // Get value for this field

              return (
                <div
                  key={index}
                  className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
                >
                  <input
                    type="text"
                    value={key}
                    onChange={(e) => handleKeyChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="Arts and Humanities"
                    required
                  />
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="30"
                    required
                  />
                  <div
                    className="hover:text-red-700 cursor-pointer"
                    onClick={() => handleRemoveField(index)}
                  >
                    (-)
                  </div>
                </div>
              );
            })}

            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Зохион байгуулагч"
              />
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Tmull"
              />
              <div
                onClick={handleAddField}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Рейтинг</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields.map((field, index) => {
              const key = Object.keys(field)[0]; // Get key for this field
              const value = field[key]; // Get value for this field

              return (
                <div
                  key={index}
                  className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
                >
                  <input
                    type="text"
                    value={key}
                    onChange={(e) => handleKeyChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="World University Rankings"
                    required
                  />
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="68"
                    required
                  />
                  <div
                    className="hover:text-red-700 cursor-pointer"
                    onClick={() => handleRemoveField(index)}
                  >
                    (-)
                  </div>
                </div>
              );
            })}

            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Зохион байгуулагч"
              />
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Tmull"
              />
              <div
                onClick={handleAddField}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Онцлог (Дугаарлах шаардлагагүй)</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields1.map((field1, index) => {
              const key1 = Object.keys(field1)[0]; // Get key for this field
              const value1 = field1[key1]; // Get value for this field

              return (
                <div
                  key={index}
                  className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
                >
                  <input
                    type="text"
                    value={key1}
                    onChange={(e) => handleKeyChange1(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="Их сургууль нь готик болон орчин үеийн архитектурыг хослуулсан үзэсгэлэнтэй, цэлгэр кампустай."
                    required
                  />

                  <div
                    className="hover:text-red-700 cursor-pointer"
                    onClick={() => handleRemoveField1(index)}
                  >
                    (-)
                  </div>
                </div>
              );
            })}
            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                id="school"
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Instagram"
              />
              <input
                type="text"
                id="school"
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="@tmull.mn"
              />
              <div
                onClick={handleAddField1}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Видео</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields.map((field, index) => {
              const key = Object.keys(field)[0]; // Get key for this field
              const value = field[key]; // Get value for this field

              return (
                <div
                  key={index}
                  className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
                >
                  <input
                    type="text"
                    value={key}
                    onChange={(e) => handleKeyChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="Сургуулийн танилцуулга
"
                    required
                  />
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="https://youtu.be/6oSQUaR1C1Q"
                    required
                  />
                  <div
                    className="hover:text-red-700 cursor-pointer"
                    onClick={() => handleRemoveField(index)}
                  >
                    (-)
                  </div>
                </div>
              );
            })}

            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Зохион байгуулагч"
              />
              <input
                type="text"
                onChange={(e) => setNameV(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Tmull"
              />
              <div
                onClick={handleAddField}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Шаардлага</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields2.map((field2, index) => (
              <div
                key={index}
                className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
              >
                <input
                  type="text"
                  name="Name"
                  value={field2.Name}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Төрөл"
                  required
                />

                <input
                  type="text"
                  name="t"
                  value={field2.t}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дундаж (Бакалаврын)"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value={field2.Name}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дундаж (Магистрын)"
                  required
                />
                <div
                  className="hover:text-red-700 cursor-pointer"
                  onClick={() => handleRemoveField2(index)}
                >
                  (-)
                </div>
              </div>
            ))}
            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                name="Name"
                className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Үйл ажиллагааны нэр"
              />
              <select
                name="type"
                className="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
              >
                <option value="none">(сонгох)</option>
                <option value="1">Нээлт</option>
                <option value="2">Гол илтгэл</option>
                <option value="3">Панел хэлэлцүүлэг</option>
                <option value="4">Харилцан яриа</option>
                <option value="5">Воркшоп</option>
                <option value="6">Семинар</option>
                <option value="7">Асуулт, хариулт</option>
                <option value="8">Нетворкынг</option>
                <option value="9">Үзэсгэлэн</option>
                <option value="10">Интерактив сесс</option>
                <option value="11">Энтертайнмент</option>
                <option value="12">Тусгай сесс</option>
                <option value="13">Завсарлага</option>
                <option value="14">Хаалт</option>
              </select>
              <input
                type="text"
                name="t"
                className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Цаг"
              />
              <div
                onClick={handleAddField2}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>Шаардлага</div>
          <div className="grid gap-6 mb-2 md:grid-cols-2 w-full">
            {fields2.map((field2, index) => (
              <div
                key={index}
                className="flex justify-between gap-2 items-center md:grid-cols-3 w-full"
              >
                <input
                  type="text"
                  name="Name"
                  value={field2.Name}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Улирал"
                  required
                />

                <input
                  type="text"
                  name="t"
                  value={field2.t}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Сар"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value={field2.Name}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Температур"
                  required
                />
                <input
                  type="text"
                  name="Name"
                  value={field2.Name}
                  onChange={(e) => handleInputChange2(index, e)}
                  className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                  placeholder="Дэлгэрэнгүй"
                  required
                />
                <div
                  className="hover:text-red-700 cursor-pointer"
                  onClick={() => handleRemoveField2(index)}
                >
                  (-)
                </div>
              </div>
            ))}
            <div
              className="flex justify-between gap-2 items-center md:grid-cols-3 w-full opacity-50 pointer-events-none"
              disabled
            >
              <input
                type="text"
                name="Name"
                className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Үйл ажиллагааны нэр"
              />
              <select
                name="type"
                className="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
              >
                <option value="none">(сонгох)</option>
                <option value="1">Нээлт</option>
                <option value="2">Гол илтгэл</option>
                <option value="3">Панел хэлэлцүүлэг</option>
                <option value="4">Харилцан яриа</option>
                <option value="5">Воркшоп</option>
                <option value="6">Семинар</option>
                <option value="7">Асуулт, хариулт</option>
                <option value="8">Нетворкынг</option>
                <option value="9">Үзэсгэлэн</option>
                <option value="10">Интерактив сесс</option>
                <option value="11">Энтертайнмент</option>
                <option value="12">Тусгай сесс</option>
                <option value="13">Завсарлага</option>
                <option value="14">Хаалт</option>
              </select>
              <input
                type="text"
                name="t"
                className="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Цаг"
              />
              <div
                onClick={handleAddField2}
                className="hover:dark:text-green-500 hover:text-green-800 opacity-40 hover:opacity-100 cursor-pointer pointer-events-auto"
              >
                (+)
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-sm">
          <form>
            <div class="block">
              <input
                type="file"
                onChange={handleImageChange}
                class="block w-full text-sm text-gray-500
        file:me-4 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-semibold
        file:bg-green-600 file:text-white
        hover:file:bg-green-700
        file:disabled:opacity-50 file:disabled:pointer-events-none
        dark:text-neutral-500
        dark:file:bg-green-600
        dark:hover:file:bg-green-500
      "
              />
            </div>
          </form>
        </div>
        <p class="text-xs text- text-gray-700 dark:text-gray-300 mt-2">
          SVG, PNG, JPG or GIF (MIN. 1920x1080px)
        </p>
        <div>
          Preview
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Image Preview"
              className="w-full h-72 md:h-96 lg:w-[50%] lg:h-96 object-cover rounded-lg"
            />
          )}
        </div>
        {/* <button
          class="block w-full text-sm text-white
       me-4 py-2 px-4
       rounded-lg border-0 font-semibold
   bg-green-600 
        hover:bg-green-700
        dark:bg-green-600
        dark:hover:bg-green-500
        mt-4
      "
          onClick={uploadFile}
          type="button"
        >
          {" "}
          Upload Image
        </button> */}
        {/* <img src={imgV} alt="" className="w-full lg:h-[80vh] object-cover" /> */}
        {/* <div className="flex flex-wrap gap-2">
        {imageUrls.map((url) => {
          return <img className="w-96 h-60 object-cover" src={url} />;
        })}
      </div> */}
        <button
          className="block w-full text-sm text-white me-4 py-2 px-4 rounded-lg border-0 font-semibold bg-green-600 hover:bg-green-700 dark:bg-green-600 dark:hover:bg-green-500 mt-4"
          type="button" // Set type to "button" to prevent default form submit behavior
          onClick={(e) => {
            e.preventDefault(); // Prevent form submission (reloading)
            console.log(
              nameV === "",
              previewUrl === "",
              disV === "",
              reg3,
              reg2,
              day1V === "",
              dayV === "",
              selectedtype === "",
              fields === "",
              fields1 === "",
              fields2 === ""
            );

            if (
              nameV === "" ||
              previewUrl === "" ||
              disV === "" ||
              reg3 === "" ||
              reg === "" ||
              day1V === "" ||
              dayV === "" ||
              selectedtype === "" ||
              fields === "" ||
              fields1 === "" ||
              fields2 === ""
            ) {
              alert("Please fill in all fields");
            } else {
              dataRetriever();
            }
          }}
        >
          Done
        </button>
      </form>
    </div>
  );
};
