import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
export const Watch = () => {
  const mergejil = [];

  useEffect(() => {
    document.title =
      "Tmull ・ Crypto investors should be prepared to lose all their money, BOE governor says";
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="mt-24 lg:mt-28 font-semibold dark:text-white">
      <div className="w-full flex lg:flex-row flex-col gap-4 justify-center">
        <iframe
          className="lg:w-[60%] w-full aspect-video"
          src="https://www.youtube-nocookie.com/embed/G5xSLbYMr-I?si=iNmtOxMcy7UmzR8-"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <div className="lg:w-[40%] w-full lg:overflow-y-auto aspect-video">
          <div className="flex flex-row justify-between items-center">
            <div className="md:text-lg lg:text-lg xl:text-xl 2xl:text-xl">
              Sunday, 9 May 2021
            </div>
            <div className="h-full flex flex-row items-center gap-2 lg:gap-4">
              <div className="flex flex-row gap-1 items-center">
                <div className="md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl text-lg">
                  2
                </div>

                <svg
                  className="lg:h-7 xl:h-9 h-6"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.66789 1.84887L1.66789 1.84887C3.22507 0.291692 5.74975 0.291692 7.30693 1.84887L7.66048 1.49532L7.30693 1.84887L8.21331 2.75525L8.56686 3.1088L8.92041 2.75526L10.0078 1.66789L10.0078 1.66788C11.5649 0.110707 14.0897 0.110707 15.6468 1.66788L15.6468 1.66789C17.204 3.22508 17.204 5.74974 15.6468 7.30693L15.6468 7.30694L9.1424 13.8114C9.1001 13.8537 9.05274 13.8864 9.00245 13.9102L8.92938 13.9448L8.87093 14.0006C8.67533 14.1875 8.36482 14.1848 8.17232 13.9924C8.17231 13.9924 8.1723 13.9924 8.17229 13.9924L1.66789 7.48792L1.31995 7.83586L1.66789 7.48792C0.110705 5.93074 0.110704 3.40605 1.66789 1.84887Z"
                    className="fill-white stroke-black dark:fill-[#00000000] dark:stroke-white"
                    stroke-width="1.3"
                  />
                </svg>
              </div>
              <svg
                className="lg:h-8 xl:h-10 2xl:h-10 h-6"
                viewBox="0 0 18 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 21.1429L9 15.7857L1.5 21.1429V4.00003C1.5 3.43171 1.72576 2.88667 2.12763 2.48481C2.52949 2.08294 3.07454 1.85718 3.64286 1.85718H14.3571C14.9255 1.85718 15.4705 2.08294 15.8724 2.48481C16.2742 2.88667 16.5 3.43171 16.5 4.00003V21.1429Z"
                  className="stroke-black dark:stroke-white fill-white dark:fill-[#00000000]"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-4xl mt-2">
            Crypto investors should be prepared to lose all their money, BOE
            governor says
          </div>
          <div>
            <div className="flex flex-row justify-between md:text-lg lg:text-lg xl:text-xl 2xl:text-xl mt-2 md:mt-4 lg:mt-4 xl:mt-6 2xl:mt-6">
              <div>Published by Ryan Browne</div>
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row items-center gap-2">
                  <div>2</div>
                  <div>
                    <svg
                      className="md:h-6 xl:h-8 h-5"
                      height="auto"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 10.25C14.5 10.6478 14.342 11.0294 14.0607 11.3107C13.7794 11.592 13.3978 11.75 13 11.75H4L1 14.75V2.75C1 2.35218 1.15804 1.97064 1.43934 1.68934C1.72064 1.40804 2.10218 1.25 2.5 1.25H13C13.3978 1.25 13.7794 1.40804 14.0607 1.68934C14.342 1.97064 14.5 2.35218 14.5 2.75V10.25Z"
                        className="fill-white stroke-black dark:fill-[#00000000] dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>12</div>
                  <div>
                    <svg
                      className="md:h-6 xl:h-8 h-5"
                      height="auto"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.66675 8V13.3333C2.66675 13.687 2.80722 14.0261 3.05727 14.2761C3.30732 14.5262 3.64646 14.6667 4.00008 14.6667H12.0001C12.3537 14.6667 12.6928 14.5262 12.9429 14.2761C13.1929 14.0261 13.3334 13.687 13.3334 13.3333V8"
                        className="stroke-black dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.6666 4.00004L7.99992 1.33337L5.33325 4.00004"
                        className="stroke-black dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 1.33337V10"
                        className="stroke-black dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div>
        <div className="mt-8 flex justify-center flex-col w-full items-center">
          <div className="font-semibold flex flex-row items-center justify-center mb-4">
            <div className="text-2xl lg:text-4xl">Сэтгэгдлийн хэсэг</div>
          </div>
          <div className="gap-4 flex flex-col lg:w-[70%] w-[95%]">
            <div className="w-full">
              <div className="relative div ">
                {/* <input
                type="text"
                placeholder=" "
                id="Feedback"
                className="rounded-md shadow-sm border-2 sm:text-sm outline-none focus:ring-2 ring-black"
              /> */}
                <textarea
                  id="Feedback"
                  rows="4"
                  placeholder=" "
                  className="rounded-md dark:text-black shadow-sm border-2 sm:text-sm outline-none focus:ring-2 ring-black dark:ring-white input w-full max-h-44 lg:max-h-72 min-h-16"
                ></textarea>
                <label
                  for="Feedback"
                  className="bg-white px-1 rounded-md dark:text-black"
                >
                  Сэтгэгдэл
                </label>
              </div>
              <div className="mt-3 w-full flex justify-end">
                <button class="transition ease-in-out duration-300 px-4 bg-black rounded-lg text-white py-2  hover:scale-110 hover:bg-gray-950">
                  Илгээх
                </button>
              </div>
            </div>
            <div className="w-full gap-4 flex flex-col overflow-y-auto p-4">
              <div className="ring-2 ring-white rounded-lg p-2 px-4">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    {" "}
                    <div>Нэр</div>
                    <div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
                      2 долоо хоногийн өмнө
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  ҮНЭЭН ҮНЭН <br />
                  ТҮМЭН ҮНЭН
                </div>
              </div>{" "}
              <div className="ring-2 ring-white rounded-lg p-2 px-4">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    {" "}
                    <div>Нэр</div>
                    <div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
                      2 долоо хоногийн өмнө
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  ҮНЭЭН ҮНЭН <br />
                  ТҮМЭН ҮНЭН
                </div>
              </div>{" "}
              <div className="ring-2 ring-white rounded-lg p-2 px-4">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    {" "}
                    <div>Нэр</div>
                    <div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
                      2 долоо хоногийн өмнө
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  ҮНЭЭН ҮНЭН <br />
                  ТҮМЭН ҮНЭН
                </div>
              </div>{" "}
              <div className="ring-2 ring-white rounded-lg p-2 px-4">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    {" "}
                    <div>Нэр</div>
                    <div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
                      2 долоо хоногийн өмнө
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  ҮНЭЭН ҮНЭН <br />
                  ТҮМЭН ҮНЭН
                </div>
              </div>{" "}
              <div className="ring-2 ring-white rounded-lg p-2 px-4">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    {" "}
                    <div>Нэр</div>
                    <div className="text-xs font-medium text-zinc-700 dark:text-zinc-300">
                      2 долоо хоногийн өмнө
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  ҮНЭЭН ҮНЭН <br />
                  ТҮМЭН ҮНЭН
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-semibold flex flex-row items-center justify-center lg:mt-6 mt-2">
        <div className="text-2xl lg:text-4xl"> Санал болгох</div>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-4 lg:mt-6 w-full mt-2">
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 h-64 lg:w-96 lg:h-72 rounded-lg relative ring-2 ring-black dark:ring-white m-2">
            <img
              className="w-80 h-44 lg:w-96 lg:h-52 object-cover rounded-lg absolute"
              src={gh}
              alt=""
            />
            <div className="absolute bottom-0 p-2 w-full  rounded-lg">
              <div className="font-bold text-xl md:text-2xl">Title/Heading</div>
              <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                Date | Author
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
