import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import todo from "../assets/todo.svg";
import { useContext } from "react";
import Comment from "./comment";
export const SugTodo = () => {
  const location = useLocation();
  const bairshil = Number(location.pathname.split("/")[3]);
  console.log(bairshil);
  if (bairshil !== 0 && bairshil !== 1 && bairshil !== 2 && bairshil !== 3) {
    window.location.pathname = "/noots/hiih_zuils";
  }
  useEffect(() => {
    document.title = "Tmull ・ To Do List";
    const favicon = document.getElementById("favicon");
    // Creating a function for the button GeeksforGeeks

    favicon.setAttribute("href", todo);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const sugested = [
    {
      Angilal: "10 ба түүнээс доош",
      Note: "Хэдий их сургуулийн өргөдлөө илгээх хугацаа хол мэт санагдаж байж болох ч энэ нь хэзээ ч эрт байдаггүй шүү! Тийм учир энэ үеэс эхэлж бэлдсэн та ирээдүйн өөрийгөө шагнаж байгаа л гэсэн үг учир одооноос сайн бэлдэж эхлээрэй.",
      Songoltuud: [
        {
          Ner: "Дүнгээ сайжруулах",
          Note: "Ихэнх их сургуулиудын өргөдөлд ахлах ангийн буюу 9-12-р ангиудын голч оноонуудыг явуулах хэрэгтэй байдаг. Тэгэхээр дүнгээ жигд сайн барихын тулд та:",
          Songoltuud: [
            "Хичээлдээ идэвхтэй хамрагдаж, сайн тэмдэглэх.",
            "Дутуу хичээлүүдээ нөхөх.",
            "Сайн ойлгодоггүй хичээлүүд дээрээ анхаарах.",
            "Хичээлээ тогтмол давтахад туслах цахим платформ олох.",
            "Өдөр бүрийн to-do лист бөглөж, хэвших.",
          ],
        },
        {
          Ner: "Стандарт тестүүдэд бэлдэх",
          Note: "Гадаадын орнуудад суралцахад дийлэнх тохиолдолд тус улсын хэлний түвшний оноог дунджаас дээгүүр авсан байх хэрэгтэй байдаг. Тэгэхээр үүнд бэлдэхийн тулд та:",
          Songoltuud: [
            "Ямар, ямар шалгалтууд өгөх хэрэгтэйг тодорхойлох, судлах.",
            "Мок тест хийж үзэх.",
            "Аль хэсгүүдэд алдаж байгаагаа харах.",
            "Итгэлтэй болтлоо бэлдээд л байх.",
          ],
        },
        {
          Ner: "Хичээлээс гадуурх үйл ажиллагаануудад оролцох",
          Note: "Гадаадын их сургуулиуд өргөдөл илгээгчдийн ахлах сургуульдаа болон төгссөний дараагаар нийгэмдээ хэр идэвхтэй, ямар үр нөлөө үзүүлсэн дээр анхаарал хандуулдаг байна. Тэгэхээр үүнд бэлдэхийн тулд та:",
          Songoltuud: [
            "Хичээлээс гадуурх үйл ажиллагааны талаарх манай нийтлэлийг унших.",
            "Өөрт тохирож буйг сонгох.",
            "Ямар боломжууд байгааг судлах.",
            "Боломжоороо сайн хийх.",
          ],
        },
        {
          Ner: "Олимпиад, тэмцээнүүдэд идэвхтэй оролцох.",
          Note: "Их сургуулиуд мөн адил академик амжилтуудыг чухалчилж, авч үздэг тул та үүний төлөө:",
          Songoltuud: [
            "Ямар хичээлийн олимпиадуудад оролцохоо тодорхойлох.",
            "Ямар төрлийн тэмцээнүүдэд оролцохоо тодорхойлох.",
            "Боломжуудыг судлах.",
            "Аль болох том хүрээнийхэд оролцох.",
            "Байр эзлэхийн тулд сайтар бэлдэх.",
          ],
        },
        {
          Ner: "Мэргэжлээ бодох.",
          Note: "Мэргэжлийн талаарх яриаг бодох нь эрт мэт санагдаж магадгүй ч энэ үед тодорхойлсноор та тус мэргэжлээр шилдэг гэх их сургуулиудын шаарддаг зүйлс, тэдний элсэгчдийн нийтлэг зүйлс г.м маш олон нарийн зүйлүүдийг ойлгон авч өөрийн хичээлээс гадуурх үйл ажиллагааг тодорхойлж болох юм. Тэгэхээр мэргэжлээ тодорхойлохын тулд та:",
          Songoltuud: [
            "Өөрийн сонирхлыг тодорхойлох.",
            "Сонирхлуудтай тань холбоотой мэргэжлүүдийг судлах.",
            "Тэдгээрийн ирээдүйн боломжуудыг харах.",
            "Боломжит хэдэн хувилбар сонгох.",
          ],
        },
      ],
    },
    {
      Angilal: "11-р анги",
      Note: "Их сургуулийн өргөдлөө бэлдэх хамгийн чухал цаг бол 11-р анги тул энэ хичээлийн жилийг үр бүтээл дүүрэн өнгөрүүлэхийг хүсэе!",
      Songoltuud: [
        {
          Ner: "Мэргэжлээ сонгох.",
          Note: "11 ангид сонгон суралцах мэргэжлээ сонгосноор олон давуу талуудтай. Хичээлээс гадуурх үйл ажиллагаа, яг юун дээрээ сайн байх эсэх гээд бүгдийг тодорхойлж болно. Тийм учир яг цорын ганц гэлтгүй хэд хэдэн сонголттой байсан нь зүйтэй.Тэгэхээр мэргэжлээ тодорхойлохын тулд та:",
          Songoltuud: [
            "Өөрийн сонирхлыг тодорхойлох.",
            "Сонирхлуудтай тань холбоотой мэргэжлүүдийг судлах.",
            "Тэдгээрийн ирээдүйн боломжуудыг харах.",
            "Боломжит хэдэн хувилбар сонгох.",
          ],
        },
        {
          Ner: "Дүнгээ сайжруулан түүнийгээ жигд барих",
          Note: "Ихэнх их сургуулиудын өргөдөлд ахлах ангийн буюу 9-12-р ангиудын голч оноонуудыг явуулах хэрэгтэй байдаг. Тэгэхээр дүнгээ жигд сайн барихын тулд та:",
          Songoltuud: [
            "Хичээлдээ идэвхтэй хамрагдаж, сайн тэмдэглэх.",
            "Дутуу хичээлүүдээ нөхөх",
            "Сайн ойлгодоггүй хичээлүүд дээрээ анхаарах.",
            "Хичээлээ тогтмол давтахад туслах цахим платформ олох.",
            "Өдөр бүрийн to-do лист бөглөж, хэвших.",
            "Хэрвээ хичээлүүдээ сонгож болдог бол мэргэжилтэйгээ холбоотой хичээлүүдийг сонгох",
          ],
        },
        {
          Ner: "Стандарт тестүүдэд бэлдэх",
          Note: "Гадаадын орнуудад суралцахад дийлэнх тохиолдолд тус улсын хэлний түвшний оноог дунджаас дээгүүр авсан байх хэрэгтэй байдаг. Тэгэхээр үүнд бэлдэхийн тулд та:",
          Songoltuud: [
            "Өгөх хэрэгтэй шалгалтуудаа тодорхойлж, тэдгээрт бэлддэг шилдэг аргуудад суралцах.",
            "Жинхэнэ шалгалтыг өгч буй мэт орчин бүрдүүлэн мок тест өгөх.",
            "Аль хэсгүүдэд алдаж байгаагаа тогтоож, түүн дээрээ тогтмол ажиллах.",
            "Итгэлтэй болтлоо бэлдээд л байх.",
          ],
        },
        {
          Ner: "Хичээлээс гадуурх үйл ажиллагаануудад идэвхтэй оролцох",
          Note: "Гадаадын их сургуулиуд өргөдөл илгээгчдийн ахлах сургуульдаа болон төгссөний дараагаар нийгэмдээ хэр идэвхтэй, ямар үр нөлөө үзүүлсэн дээр анхаарал хандуулдаг байна. Тэгэхээр үүнд бэлдэхийн тулд та:",
          Songoltuud: [
            "Хичээлээс гадуурх үйл ажиллагааны талаарх манай нийтлэлийг унших.",
            "Нийт хэдэн ширхгийг хийх эсэхээ тодорхойлох. (АНУ-ын их сургуулиудын хувьд дийлэнхдээ ихдээ 10 байдаг.)",
            "Боломжуудыг судлах.",
            "Өөрт тань боломжууд байгааг сайн судлах. (Оршин сууж буй оронд тань ямар үйл ажиллагаанууд хийх боломжтой вэ гэдгийг судалж, тодорхойлох.)",
            "Байр эзлэхийн тулд сайтар бэлдэх.",
            "Мэргэжилтэй тань холбоотой олдож буй бүх боломжуудаа ашиглах.",
            "Урт хугацаанд чанартай зүйлс хийх.",
            "Гадаадын орнуудад ч мөн боломжтой бол байгаа боломжуудыг ашиглах.",
          ],
        },
        {
          Ner: "Олимпиад, тэмцээнүүдэд идэвхтэй оролцох",
          Note: "Их сургуулиуд мөн адил академик амжилтуудыг чухалчилж, авч үздэг тул та үүний төлөө:",
          Songoltuud: [
            "Ямар хичээлийн олимпиадуудад оролцохоо тодорхойлох.",
            "Ямар төрлийн тэмцээнүүдэд оролцохоо тодорхойлох.",
            "Боломжуудыг судлах.",
            "Аль болох том хүрээнийхэд оролцох. (нийслэлийнх, улсынх, олон улсынх г.м)",
            "Байр эзлэхийн тулд сайтар бэлдэх.",
          ],
        },
        {
          Ner: "Их сургуулиудын жагсаалтаа гаргах",
          Note: "Их сургуулиудад өргөдлөө илгээх үйл явц нь маш их цаг зав шаарддаг тул 11 ангидаа өөрт тань тохирно хэмээн үзэж буй олон улсын сурагчид тэтгэлэг өгдөг их сургуулиудыг сайн судалж, лист гаргах. Ингэхдээ сурахыг хүсэж буй хэд хэдэн орнуудын их сургуулиудыг харахыг зөвлөж байна.",
          Songoltuud: [
            "Албан ёсны сайтуудаас харан судлах.",
            "Тэдгээр их сургуулиудын шаардлагуудыг мэдэж авах. (Өөрийн тань хийх боломжгүй зүйлсийг шаардаж байвал шууд хасах.)",
            "Мэргэжил тань байгаа эсэхийг шалгах",
            "Тэдгээр их сургуулиудын сурагчдын амьдралыг судлах. (YouTube, Redeet г.м)",
            "Эцэст нь листээ гаргаж дуусган тэдний өргөдөл хэзээ хаагддагийг тэмдэглэж авах.",
          ],
        },
        {
          Ner: "Эсээнүүдээ бичиж эхлэх",
          Note: "Их сургууль болгон өөрийн өргөдлийн шаардлагуудтай байдаг ба заримынх нь хамгийн чухал хэсэг бол эсээ байдаг. Та өөрийн өргөдөл илгээх их сургуулиудыг тодорхойлсноор аль аль нь эсээ шаарддагийг мэдэж аван эсээндээ яг юу тодотгож өгөх нь зүйтэйг тодорхойлоорой.",
          Songoltuud: [
            "Нийт хэдэн ширхэг, ямар төрлийн эсээ бичих шаардлагатайг тодорхойлох.",
            "Тэдгээр эсээнүүдэд юу тусгаж өгөх нь зөвийг судлан мэдэх.",
            "Эсээнүүдээ бичиж эхлэх.",
            "Эсээ бичихэд туслах шилдэг номуудыг унших.",
            "Эсээгээ зөвлөх нэг хүнд уншуулан зассаар байх.",
          ],
        },
      ],
    },
    {
      Angilal: "12-р анги",
      Note: "Төгсөх анги бол ахлах сургуулийн, магадгүй өсвөр үеийнхэн бидний амьдралыг тодорхойлох хамгийн чухал үе шат гэж үзэж болох тул энэ үед хүссэн зүйлдээ хүрэхийн тулд маш олон зүйлсийг хийх хэрэгтэй байдаг тул цагаа зөв зарцуулаарай!",
      Songoltuud: [
        {
          Ner: "Дүнгээ сайжруулан түүнийгээ жигд барих",
          Note: "Ихэнх их сургуулиудын өргөдөлд ахлах ангийн буюу 9-12-р ангиудын голч оноонуудыг явуулах хэрэгтэй байдаг. Тэгэхээр дүнгээ жигд сайн барихын тулд та:",
          Songoltuud: [
            "Хичээлдээ идэвхтэй хамрагдаж, сайн тэмдэглэх.",
            "Дутуу хичээлүүдээ нөхөх.",
            "Сайн ойлгодоггүй хичээлүүд дээрээ анхаарах.",
            "Хичээлээ тогтмол давтахад туслах цахим платформ олох.",
            "Өдөр бүрийн to-do лист бөглөж, хэвших.",
            "Хэрвээ хичээлүүдээ сонгож болдог бол мэргэжилтэйгээ холбоотой хичээлүүдийг сонгох.",
          ],
        },
        {
          Ner: "Стандарт тестүүдийн оноогоо бэлэн болгох",
          Note: "Хэрэв та өгөх шаардлагатай шалгалтуудаа өгөөгүй байгаа эсвэл илүү сайн оноо авахын тулд дахин шалгалт өгөх шаардлагатай бол өргөдөл илгээх хугацаанаасаа өмнө оноогоо авах.",
          Songoltuud: [
            "Өгөх хэрэгтэй шалгалтуудаа тодорхойлж, тэдгээрт бэлддэг шилдэг аргуудад суралцах.",
            "Жинхэнэ шалгалтыг өгч буй мэт орчин бүрдүүлэн мок тест өгөх.",
            "Суудлаа баталгаажуулж, шалгалтаа өгөх.",
          ],
        },
        {
          Ner: "Хичээлээс гадуурх үйл ажиллагаануудад идэвхтэй оролцох",
          Note: "Гадаадын их сургуулиуд өргөдөл илгээгчдийн ахлах сургуульдаа болон төгссөний дараагаар нийгэмдээ хэр идэвхтэй, ямар үр нөлөө үзүүлсэн дээр анхаарал хандуулдаг байна. Тэгэхээр үүнд бэлдэхийн тулд та:",
          Songoltuud: [
            "Хичээлээс гадуурх үйл ажиллагааны талаарх манай нийтлэлийг унших.",
            "Нийт хэдэн ширхгийг хийх эсэхээ тодорхойлох. (АНУ-ын их сургуулиудын хувьд дийлэнхдээ ихдээ 10 байдаг.)",
            "Өөрт тань боломжууд байгааг сайн судлах. (Оршин сууж буй оронд тань ямар үйл ажиллагаанууд хийх боломжтой вэ гэдгийг судалж, тодорхойлох.)",
            "Мэргэжилтэй тань холбоотой олдож буй бүх боломжуудаа ашиглах.",
            "Урт хугацаанд чанартай зүйлс хийх.",
            "Гадаадын орнуудад ч мөн боломжтой бол байгаа боломжуудыг ашиглах.",
          ],
        },
        {
          Ner: "Их сургуулиудын жагсаалтаа гаргаж дуусгах",
          Note: "Өөрт тань тохирно хэмээн үзэж буй олон улсын сурагчдад тэтгэлэг өгөх боломжтой их сургуулиудыг сайн судалж, лист гаргах. Ингэхдээ сурахыг хүсэж буй хэд хэдэн орнуудын их сургуулиудыг харахыг зөвлөж байна.",
          Songoltuud: [
            "Албан ёсны сайтуудаас харан судлах.",
            "Тэдгээр их сургуулиудын шаардлагуудыг мэдэж авах. (Өөрийн тань хийх боломжгүй зүйлсийг шаардаж байгаа эсэхийг судлах.)",
            "Мэргэжил тань байгаа эсэхийг шалгах.",
            "Тэдгээр их сургуулиудын сурагчдын амьдралыг судлах. (YouTube, Redeet г.м)",
            "Эцэст нь листээ гаргаж дуусган тэдний өргөдөл хэзээ хаагддагийг тэмдэглэж авах.",
          ],
        },
        {
          Ner: "Эсээнүүдээ бичих",
          Note: "Их сургууль болгон өөрийн өргөдлийн шаардлагуудтай байдаг ба заримынх нь хамгийн чухал хэсэг бол эсээ байдаг. Та өөрийн өргөдөл илгээх их сургуулиудыг тодорхойлсноор аль аль нь эсээ шаарддагийг мэдэж аван эсээндээ яг юу тодотгож өгөх нь зүйтэйг тодорхойлоорой.",
          Songoltuud: [
            "Нийт хэдэн ширхэг, ямар төрлийн эсээ бичих шаардлагатайг тодорхойлох.",
            "Тэдгээр эсээнүүдэд юу тусгаж өгөх нь зөвийг судлан мэдэх.",
            "Эсээнүүдээ хэзээ дуусгах ёстойгоо тодорхойлох.",
            "Эсээ бичихэд туслах шилдэг номуудыг унших.",
            "Эсээгээ зөвлөх нэг хүнд уншуулан зассаар эцсийн хувилбарыг гаргах.",
          ],
        },
        {
          Ner: "Зөвлөмж захиануудыг бэлтгэх",
          Note: "АНУ-ын их дээд сургуулиудын өргөдөлд хичээл ордог багш болон сургуулийн зөвлөгч багшаар зөвлөмж захиа бичүүлдэг байна. Үүнийг та хичээлийн жилийн эхэнд дуусгасан байх шаардлагатай.",
          Songoltuud: [
            "Нийт хэдэн ширхгийг бичүүлэх хэрэгтэйгээ тодорхойлох.",
            "Мэргэжилтэй тань холбоотой хичээлийн багш нартай холбоотой байж, захиагаа бичүүлж авах.",
            "Хэрэгтэй бол орчуулуулах.",
          ],
        },
        {
          Ner: "Их сургуулиуд руу өргөдлөө илгээх",
          Note: "Их сургууль бүрийн өргөдөл хүлээн авах хугацаа өөр байдаг тул сайн судалж, цагаа сайн баримтлаарай.",
          Songoltuud: [
            "Сурахыг хүссэн их дээд сургуулиудын жагсаалтыг эцэслэн гаргах.",
            "Эсээ болон шаардлагатай бичиг баримтыг багтаасан их дээд сургуулийн өргөдлийг бөглөж, явуулах.",
            "Их сургууль бүрээс хариугаа хүлээж авах.",
          ],
        },
        {
          Ner: "Санхүүгийн тусламж эрэх",
          Note: "",
          Songoltuud: [
            "Өргөдлөө илгээсний дараа  тусламж авах CSS профайл, эсвэл ISFAA бөглөж явуулах.",
            "Судалгаа хийж, тэтгэлгүүдэд  хамрагдах хүсэлт гаргах.",
            "Шаардлагатай бол оюутны зээлийг судлах.",
          ],
        },
        {
          Ner: "Ярилцлагад бэлдэх",
          Note: "Их сургуулиудаа судалж байх үед аль сургуулиуд ярилцлагын эрх өргөдөл илгээгчдэд олгодог талаар сайн судалсан байх шаардлагатай. Ингэснээр боломжит ярилцлагуудыг төлөвлөж, урилга ирсэний дараагаар бэлтгэл сайтай байж болно.",
          Songoltuud: [
            "Ярилцлагад асуугддаг асуултуудын төрлийг өөрийн болгосон байх.",
            "Чөлөөт цагаараа сайн бэлдээд л байх.",
            "Өөртөө итгэлтэй байх.",
          ],
        },
        {
          Ner: "Хариунуудаа хүлээн аван шийдвэрээ гаргах",
          Note: "Их сургууль болгоны өргөдөл илгээх хугацаа ондоо байдагтай адил хариу гарах хугацаанууд ч зөрөлддөг учир анхнаасаа сайн төлөвлөгөөтэй байх хэрэгтэй. Заавал сонголгүй завсар жил аван дахин өргөдлөө илгээх ч боломжтой шүү!",
          Songoltuud: [
            "Бүх хариунуудаа хүлээн авч дуусах.",
            "Бүх хувилбаруудаас өөрт хамгийн тохиромжтойг сонгон их сургуулиуддаа шуудан илгээх.",
          ],
        },
        {
          Ner: "Их сургуульдаа явахдаа бэлдэх",
          Note: "Суралцах сургуулиа сонгосны дараагаар хичээлийн дүнгээ сайн барьж, жилийн эцсийн дүнгийн хуулгаа их сургууль руугаа явуулан удахгүй очихдоо бэлдэх хэрэгтэй.",
          Songoltuud: [
            "Эрүүл мэндээ сайн бодох.",
            "Явтлаа цагийг дурсамж дүүрэн өнгөрүүлэх.",
          ],
        },
      ],
    },
    {
      Angilal: "Завсар жил",
      Note: "Завсар жил авах нь тэрхүү хугацаандаа өөрт хэрэгтэй бүхнийг хийх боломжтой хугацаа тул хэрвээ та завсар жил авсан эсвэл авахыг төлөвлөж байвал маш сайн төлөвлөгөөтэй байгаарай. Түүнчлэн энэхүү цаг хугацаагаар өөрийн үргэлж хийхийг хүсдэг байсан зүйлсээ итгэл дүүрэн эхлүүлж үзээрэй^^ Төгөлдөр хуур тоглох ч юм уу кк.",
      Songoltuud: [
        {
          Ner: "Төлөвлөгөө сайн хийх",
          Note: "Юун түрүүнд завсар жил авах болсон хамгийн том зорилгоо тодорхойлж, түүндээ хүрэхийн тулд хэрэгтэй бүхнийг хийх хэрэгтэй.",
          Songoltuud: [
            "Өөрт байгаа бүх боломжуудаа эрэх.",
            "Санхүүгээ төлөвлөх. (Хувийн орлоготой болох боломжуудыг судлах.)",
            "Нарийн төлөвлөгөө гарган  завсар жилээ итгэлтэйгээр эхлүүлэх.",
            "Ойр дотно хүмүүстэйгээ сайн холбоотой байж, өөрийгөө илүү олж мэдэх.",
            "Хоббитой болж, түүнийгээ хөгжүүлэх.",
            "Дадлага ажил, судалгааны ажил г.м өргөдөлд тань юу хэрэгтэй гэж үзэж буйгаа тодорхойлох.",
          ],
        },
        {
          Ner: "Өргөдлөө сайжруулах",
          Note: "",
          Songoltuud: [
            "Шаардлагатай бол стандарт тестүүдийн оноогоо ахиулах. (Суудлаа баталгаажуулж, сайтар бэлдэх.)",
            "Хичээлээс гадуурх үйл ажиллагаануудаа дэвшүүлэх.(Суралцах мэргэжилтэйгээ илүү холбож, чанартай үйл ажиллагаанууд хэрэгжүүлэх.)",
            "Их сургуулиудын жагсаалтаа дахин шинэчлэх. (Хэдэн их сургууль руу өргөдлөө илгээхээ тодорхойлж, судлагаагаа сайн хийх.)",
            "Эсээнүүдээ сайжруулан бичих. (Хувь хүнээ илүү тодотгон гаргахыг хичээх.)",
          ],
        },
        {
          Ner: "Их сургуулиуд руу өргөдлөө илгээх",
          Note: "Их сургууль бүрийн өргөдөл хүлээн авах хугацаа өөр байдаг тул сайн судалж, цагаа сайн баримтлаарай.",
          Songoltuud: [
            "Сурахыг хүссэн их дээд сургуулиудын жагсаалтыг эцэслэн гаргах.",
            "Эсээ болон шаардлагатай бичиг баримтыг багтаасан их дээд сургуулийн өргөдлийг бөглөж, явуулах.",
            "Их сургууль бүрээс хариугаа хүлээж авах.",
          ],
        },
        {
          Ner: "Санхүүгийн тусламж эрэх",
          Note: "",
          Songoltuud: [
            "Өргөдлөө илгээсний дараа  тусламж авах CSS профайл, эсвэл ISFAA бөглөж явуулах.",
            "Судалгаа хийж, тэтгэлгүүдэд  хамрагдах хүсэлт гаргах.",
            "Шаардлагатай бол оюутны зээлийг судлах.",
          ],
        },
        {
          Ner: "Ярилцлагад бэлдэх",
          Note: "Их сургуулиудаа судалж байх үед аль сургуулиуд ярилцлагын эрх өргөдөл илгээгчдэд олгодог талаар сайн судалсан байх шаардлагатай. Ингэснээр боломжит ярилцлагуудыг төлөвлөж, урилга ирсэний дараагаар бэлтгэл сайтай байж болно.",
          Songoltuud: [
            "Ярилцлагад асуугддаг асуултуудын төрлийг өөрийн болгосон байх.",
            "Чөлөөт цагаараа сайн бэлдээд л байх.",
            "Өөртөө итгэлтэй байх.",
          ],
        },
        {
          Ner: "Хариунуудаа хүлээн аван шийдвэрээ гаргах",
          Note: "Их сургууль болгоны өргөдөл илгээх хугацаа ондоо байдагтай адил хариу гарах хугацаанууд ч зөрөлддөг учир анхнаасаа сайн төлөвлөгөөтэй байх хэрэгтэй. Заавал сонголгүй завсар жил аван дахин өргөдлөө илгээх ч боломжтой шүү!",
          Songoltuud: [
            "Бүх хариунуудаа хүлээн авч дуусах.",
            "Бүх хувилбаруудаас өөрт хамгийн тохиромжтойг сонгон их сургуулиуддаа шуудан илгээх.",
          ],
        },
        {
          Ner: "Их сургуульдаа явахдаа бэлдэх",
          Note: "Суралцах сургуулиа сонгосны дараагаар удахгүй очихдоо бэлдэх хэрэгтэй.",
          Songoltuud: [
            "Эрүүл мэндээ сайн бодох.",
            "Явтлаа цагийг дурсам дүүрэн өнгөрүүлэх.",
          ],
        },
      ],
    },
  ];
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="flex flex-col items-center justify-center mt-16 ">
        <div className="font-semibold flex flex-row items-center justify-center mb-4">
          <div className="absolute">
            <svg
              width="268"
              height="102"
              viewBox="0 0 268 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M226.911 42.2033C227.325 42.354 227.783 42.1406 227.934 41.7267C228.084 41.3128 227.871 40.8551 227.457 40.7045C224.639 39.6787 223.186 36.5625 224.211 33.7442L224.231 33.6906C224.381 33.2767 224.168 32.8191 223.754 32.6684C223.34 32.5178 222.883 32.7312 222.732 33.1451C221.695 35.993 218.502 37.4451 215.654 36.4086C215.24 36.2579 214.782 36.4713 214.632 36.8852C214.481 37.2991 214.695 37.7568 215.109 37.9074L215.187 37.936C218.016 38.9657 219.475 42.0939 218.445 44.923C218.295 45.3369 218.508 45.7946 218.922 45.9452C219.336 46.0959 219.793 45.8824 219.944 45.4685C220.97 42.6503 224.093 41.1776 226.911 42.2033Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.9715 25.7442C72.4591 25.4849 72.6442 24.8794 72.385 24.3918C72.1257 23.9042 71.5202 23.719 71.0325 23.9783C67.7122 25.7438 63.5894 24.4833 61.824 21.163L61.7904 21.0998C61.5311 20.6122 60.9256 20.427 60.438 20.6863C59.9504 20.9456 59.7652 21.5511 60.0245 22.0387C61.8085 25.3939 60.4821 29.5881 57.1269 31.3721C56.6392 31.6314 56.4541 32.2369 56.7134 32.7245C56.9727 33.2122 57.5782 33.3973 58.0658 33.138L58.1584 33.0887C61.4916 31.3165 65.6304 32.5818 67.4026 35.915C67.6619 36.4026 68.2674 36.5877 68.7551 36.3285C69.2427 36.0692 69.4278 35.4637 69.1685 34.976C67.4031 31.6558 68.6512 27.5097 71.9715 25.7442Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.2909 84.1501C44.5777 84.983 45.4854 85.4258 46.3183 85.139C47.1512 84.8522 47.594 83.9444 47.3072 83.1115L46.3758 80.4066C44.9373 76.229 47.1578 71.6764 51.3354 70.2379L54.1481 69.2694C54.981 68.9826 55.4237 68.0749 55.1369 67.242C54.8501 66.4091 53.9424 65.9663 53.1095 66.2531L50.2968 67.2216C46.1192 68.6601 41.5666 66.4396 40.1281 62.262L39.1287 59.3594C38.8419 58.5265 37.9341 58.0837 37.1012 58.3705C36.2683 58.6573 35.8255 59.5651 36.1123 60.398L37.1118 63.3006C38.5502 67.4782 36.3298 72.0308 32.1522 73.4693L29.4076 74.4143C28.5747 74.7011 28.1319 75.6089 28.4187 76.4418C28.7055 77.2747 29.6133 77.7174 30.4462 77.4306L33.1908 76.4856C37.3684 75.0472 41.921 77.2676 43.3595 81.4452L44.2909 84.1501Z"
                fill="#4BAB4D"
              />
            </svg>
          </div>
          <div className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
            To Do List
          </div>
        </div>
        <div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform font-semibold">
          Cанал болгосон
        </div>
        <div className="flex flex-wrap w-full lg:flex-row justify-evenly m-4 transition-all ease-in-out duration-300 delay-0 transform">
          <div className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo bg-black text-white cursor-pointer border-black my-2">
            <div className="text-lg lg:text-2xl font-bold xl:text-3xl text text-center drop-shadow-lg">
              {sugested[bairshil].Angilal}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[70%] text-md lg:text-lg font-semibold text-justify">
          {sugested[bairshil].Note}
        </div>
      </div>
      <div className="font-semibold flex justify-center flex-col-reverse items-center w-full md:text-lg lg:text-xls my-8">
        <div className="font-bold hidden md:text-lg lg:text-xl rounded-xl bg-[#4BAB4D] cursor-pointer text-white p-2 mt-4">
          Өөрийн жагсаалт уруу нэмэх тун удахгүй боломжтой болно
        </div>

        <div className="w-full gap-16 flex flex-col items-center">
          {sugested[bairshil].Songoltuud.map((el) => {
            console.log(el.Angilal === "10 ба түүнээс доош");
            return (
              <div className="lg:w-[60%] w-full flex justify-center flex-col items-center">
                <div className="flex flex-row justify-between items-center gap-3 w-full">
                  <div className="peer flex items-center gap-3 group/item rounded-md px-2 hover:bg-[#4BAB4D] h-12 w-full transition delay-0 duration-300 ease-in">
                    <div class="relative flex items-center rounded-full cursor-pointer peer">
                      <input
                        type="checkbox"
                        class="before:content[''] peer relative h-8 w-8 lg:h-10 lg:w-10 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-gray-100/30 transition-all before:absolute before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4BAB4D] checked:bg-[#4BAB4D] dark:checked:bg-[#4BAB4D] checked:before:bg-[#4BAB4D] hover:scale-105 hover:before:opacity-0"
                      />
                      <div class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 lg:h-8"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="select-none group-hover/item:!text-white transition delay-0 duration-300 ease-in peer-has-[:checked]:text-[#4BAB4D] peer-has-[:checked]:line-through">
                      {el.Ner}
                    </div>
                  </div>
                  <div className="rounded-md p-1 hover:bg-red-50 dark:hover:bg-red-50/10 hover:text-red-500 peer-has-[:checked]:hidden transition delay-0 duration-300 ease-in">
                    <svg
                      className="h-8 w-8 lg:h-10 lg:w-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="text-justify text-sm lg:text-base lg:w-[98%] w-full">
                  {el.Note}
                </div>
                <ul class="flex flex-col justify-end text-start text-base -space-y-px w-full mt-2">
                  {el.Songoltuud.map((el) => {
                    return (
                      <li class="flex w-full items-center gap-x-2 p-3 bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                        <div class="w-full flex justify-between truncate">
                          <div class="me-3 flex-1 w-full truncate">{el}</div>
                          {/* <button
														type="button"
														class="flex items-center gap-x-2 text-gray-500 hover:text-[#4BAB4D] whitespace-nowrap dark:text-neutral-500 dark:hover:text-blue-500"
													>
														Нэмэх
													</button> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      {/* <Comment id={Number(location.pathname.split('/')[3])} /> */}
      <div className="w-full hidden mt-16 justify-center items-center flex-col rounded-lg bg-[#54545428] py-2">
        <div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
          <div className="text-xl md:text-2xl lg:text-3xl">
            Хийх зүйлсийн жагсаалттай холбоотой мэдээллүүд
          </div>
        </div>
        <div className="flex flex-row overflow-x-auto gap-4 p-2 w-full">
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
