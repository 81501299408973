import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import axios from "axios";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";

export const UploadAcad = () => {
  const mergejil = [];
  const [nameV, setNameV] = useState("");
  const [disV, setDisV] = useState("");
  const [typeV, setTypeV] = useState("");
  const [prV, setPrV] = useState([]);
  const [moV, setMoV] = useState("");
  const [schV, setSchV] = useState([]);
  const [plV, setPlV] = useState("");
  const [conV, setConV] = useState([]);
  const [loV, setLoV] = useState("");
  const [imgV, setImgV] = useState("");
  const [ageV, setAgeV] = useState("");
  const [yearV, setYearV] = useState("");
  const [selectedtype, setSelectedtype] = useState([]);
  const [gr, setGr] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null); // New state for image preview
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "Events/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `Acad/${imageUpload.name}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImgV(url);
        console.log("Uploaded Image URL:", url); // Log the URL
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageUpload(file);
      setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
    }
  };
  // console.log(prV.filter((item) => item.trim() !== "").join(" /N "));

  const dataRetriever = async () => {
    if (selectedtype === "none") {
      alert("Төрөл сонгоогүй байна!");
      return;
    } else {
      uploadFile();
      console.log(prV);

      if (imgV !== "") {
        await axios({
          url: `https://tmull-be.vercel.app/acad`,
          method: "POST",
          data: {
            na: nameV,
            img: imgV.split("Acad%2F")[1],
            di: disV,
            ty: typeV,
            pr: prV.filter((item) => item.trim() !== "").join(" /N "),
            mo: moV,
            sch: schV.filter((item) => item.trim() !== "").join(" /N "),
            pl: plV,
            con: conV.filter((item) => item.trim() !== "").join(" /N "),
            lo: loV,
            age: ageV,
            ca: selectedtype,
            gr: gr,
            year: yearV,
          },
        }).then((response) => {
          alert(
            response?.data === "successfully created new Acad"
              ? "successfully created new Acad"
              : "Error"
          );
          if (response?.data === "successfully created new Acad") {
            window.location.href = "/noots/uil_ajillagaa";
          }
        });
      }
    }
  };
  useEffect(() => {
    document.title = "Tmull ・ Upload Academy";
  }, []);
  const a = [
    {
      _id: "66b1e4dc87f76e72e8e3a4d7",
      mg: "tomujin.png?alt=media&token=c21a9e34-9ea3-4649-8f16-89315be8c025",
      na: "Tomujin Academy Mongolia",
      ty: "Их сургуулийн өргөдлийн үйл явцад бэлдэх сургалт",
      di: "Томүжин Академи нь Монгол оюуныг дэлхийд хүргэх аялалаа эхлүүлсний арав дахь жилийнхээ ирмэг дээр, арав дахь зуны хөтөлбөрийнхөө эхлэл дээр монгол орны өнцөг булан бүрт байгаа хүүхдүүдээ нэгэн зуны хөтөлбөрт нэгтгэн оруулахад зориулан энэ удаад илүү хүртээмжтэйгээр, илүү өвөрмөцөөр зуны хөтөлбөрөө Tomujin Summer Camp нэртэйгээр хэрэгжүүлэх гэж байна.",
      pr: "🏫 Harvard, Stanford, Cornell гэх мэт дэлхийн шилдэг их сургуулийн Mонгол болон гадаад оюутнуудаас зөвлөгөө авах боломж,/N  /N 🌟 Дэлхийн топ их сургуулиудруу өргөдлөө илгээхээр бэлтгэж буй зуу зуун хүүхдүүдээс бүрдэх шилдэг хүрээллэл,  /N  /N 🎯 АНУ-ын их сургуулиудруу өргөдлөө илгээхийн тулд заавал мэдэх ёстой суурь хичээлүүд, /N /N ✍️ Дэлхийн шилдэг их сургуулиудад тэнцэх эсэхэд чинь хамгийн том нөлөө үзүүлэх Personal Statement болон бусад эсээ бичлэгийн мастер хичээлүүд, /N /N 📑 SAT Math, SAT Reading & Writing, IELTS шалгалтуудын суурь хичээлүүд болон амжилттай өгөх арга барил техник, /N  /N 💫 Зуны хөтөлбөрөө дуусаад галзуу санаагаа бодит болгоход чинь туслах нийгэмдээ ээлтэй төслийн төлөвлөгөөний хичээлүүдийг санал болгож байна.",
      mo: "850'000₮",
      sch: "1-р ээлж: 06.17-06.23 (13-16 нас)/N 2-р ээлж: 06.24-06.30 (13-16 нас) /N 3-р ээлж: 07.01-07.07 (13-16 нас) /N 4-р ээлж: 07.15-07.21 (17-19 нас) /N 5-р ээлж: 07.22-07.28 (15-19 нас) /N 6-р ээлж: 07.29-08.04 (15-19 нас) /N 7-р ээлж: 08.05-08.11 (15-19 нас) /N 8-р ээлж: 08.12-08.18 (15-19 нас)",
      pl: "🚀 Harvard, MIT, Yale, Stanford гэх мэт ТОП-ууд руу 2015 оноос хойш зуу зуун сурагчдаа үерлүүлсээр 🌟",
      con: "📞 (976) 72020007 /N 💌 summer@tomujin.org",
      lo: "Прайм синеплекс зайсан 7 давхарт",
      age: "9-12р анги, Завсар жил авч буй сурагчид",
      cAt: "2024-08-06T08:32:44.239Z",
      __v: 0,
      dlike: [0],
      like: [0],
      ca: "Америк",
    },
  ];
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="w-full text-center text-3xl h-16">
        Бэлдэхэд туслах сургалтууд нэмэх талбар
      </div>

      <form>
        <div class="grid gap-6 mb-6 md:grid-cols-2 w-full">
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Сургалтын газрын нэр
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Tomujin Academy Mongolia"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Төрөл
            </div>
            <select
              name="type"
              id="type"
              value={gr}
              required
              onChange={(e) => setGr(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option disabled selected value={""}>
                -- сонголт сонгох --
              </option>
              <option value="1">Урлаг</option>
              <option value="2">Спорт</option>
              <option value="3">Академик</option>
              <option value="4">Хувь хүний хөгжил</option>
            </select>
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Юуны сургалт
            </div>
            <input
              onChange={(e) => setTypeV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Их сургуулийн өргөдлийн үйл явцад бэлдэх сургалт"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Хэзээ үүсгэн байгуулагдсан
            </div>
            <input
              onChange={(e) => setYearV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="2024"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Хаяг
            </div>
            <input
              onChange={(e) => setLoV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Прайм синеплекс зайсан 7 давхарт"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Хамрах хүрээ
            </div>
            <input
              onChange={(e) => setAgeV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="9-12р анги, Завсар жил авч буй сурагчид"
              required
            />
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Танилцуулга
            </div>
            <textarea
              onChange={(e) => setDisV(e.target.value)}
              type="text"
              className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Томүжин Академи нь Монгол оюуныг дэлхийд хүргэх ..."
              required
            ></textarea>
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Программууд
            </div>
            <textarea
              onChange={(e) => setPrV(e.target.value.split("\n"))}
              type="text"
              className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="🏫 Harvard, Stanford, Cornell гэх мэт дэлхийн шилдэг их сургуулийн Mонгол болон гадаад оюутнуудаас зөвлөгөө авах боломж ..."
              required
            ></textarea>
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Үргэлжлэх хугацаа
            </div>
            <textarea
              onChange={(e) => setSchV(e.target.value.split("\n"))}
              type="text"
              className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="1-р ээлж: 06.17-06.23 (13-16 нас)
2-р ээлж: 06.24-06.30 (13-16 нас) ..."
              required
            ></textarea>
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Холбогдох боломжууд
            </div>
            <textarea
              onChange={(e) => setConV(e.target.value.split("\n"))}
              type="text"
              className="bg-gray-50 max-h-96 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="📞 (976) 72020007
💌 summer@tomujin.org ..."
              required
            ></textarea>
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Дундаж төлбөр
            </div>
            <input
              onChange={(e) => setMoV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Их сургуулийн өргөдлийн үйл явцад бэлдэх сургалт"
              required
            />
          </div>

          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нэмэлт мэдээлэл
            </div>
            <input
              onChange={(e) => setPlV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="🚀 Harvard, MIT, Yale, Stanford гэх мэт ТОП-ууд руу 2015 оноос хойш зуу зуун сурагчдаа үерлүүлсээр 🌟"
              required
            />
          </div>
          <div>
            <div
              for="country"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Хамааралтай Улс (1 болон түүнээс дээш)
            </div>
            <select
              name="type"
              id="type"
              value={selectedtype}
              required
              multiple
              onChange={(e) =>
                setSelectedtype(
                  [...e.target.selectedOptions].map((option) => option.value)
                )
              }
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option value="Монгол">Монгол</option>
              <option value="АНУ">АНУ</option>
              <option value="Хятад">Хятад</option>
              <option value="Япон">Япон</option>
              <option value="Солонгос">Солонгос</option>
              <option value="Орос">Орос</option>
              <option value="Герман">Герман</option>
              <option value="Австрали">Австрали</option>
              <option value="Канад">Канад</option>
              <option value="Их Британи">Их Британи</option>
              <option value="Франц">Франц</option>
              <option value="Энэтхэг">Энэтхэг</option>
              <option value="Итали">Итали</option>
              <option value="Турк">Турк</option>
              <option value="Сингапур">Сингапур</option>
              <option value="Шинэ Зеланд">Шинэ Зеланд</option>
              <option value="Малайз">Малайз</option>
              <option value="Швед">Швед</option>
              <option value="Швейцарь">Швейцарь</option>
              <option value="Финлянд">Финлянд</option>
              <option value="Нидерланд">Нидерланд</option>
              <option value="Дани">Дани</option>
              <option value="Норвеги">Норвеги</option>
              <option value="Бельги">Бельги</option>
              <option value="Австри">Австри</option>
              <option value="Ирланд">Ирланд</option>
              <option value="Испани">Испани</option>
              <option value="Бразил">Бразил</option>
              <option value="Өмнөд Африк">Өмнөд Африк</option>
              <option value="Арабын Нэгдсэн Эмирт Улс">
                Арабын Нэгдсэн Эмирт Улс
              </option>
              <option value="Саудын Араб">Саудын Араб</option>
              <option value="Тайланд">Тайланд</option>
              <option value="Индонез">Индонез</option>
              <option value="Вьетнам">Вьетнам</option>
              <option value="Филиппин">Филиппин</option>
              <option value="Пакистан">Пакистан</option>
              <option value="Бангладеш">Бангладеш</option>
              <option value="Шри Ланка">Шри Ланка</option>
              <option value="Египет">Египет</option>
              <option value="Кени">Кени</option>
              <option value="Нигери">Нигери</option>
              <option value="Мексик">Мексик</option>
              <option value="Аргентин">Аргентин</option>
              <option value="Чили">Чили</option>
              <option value="Колумб">Колумб</option>
              <option value="Перу">Перу</option>
              <option value="Венесуэл">Венесуэл</option>
              <option value="Украйн">Украйн</option>
              <option value="Польш">Польш</option>
              <option value="Чех">Чех</option>
              <option value="Унгар">Унгар</option>
              <option value="Португал">Португал</option>
              <option value="Грек">Грек</option>
              <option value="Израиль">Израиль</option>
              <option value="Иран">Иран</option>
              <option value="Ирак">Ирак</option>
              <option value="Афганистан">Афганистан</option>
              <option value="Казахстан">Казахстан</option>
              <option value="Узбекистан">Узбекистан</option>
              <option value="Киргизстан">Киргизстан</option>
              <option value="Тажикстан">Тажикстан</option>
              <option value="Армени">Армени</option>
              <option value="Гүрж">Гүрж</option>
              <option value="Азербайжан">Азербайжан</option>
              <option value="Беларусь">Беларусь</option>
              <option value="Латви">Латви</option>
              <option value="Литва">Литва</option>
              <option value="Эстони">Эстони</option>
              <option value="Румын">Румын</option>
              <option value="Болгар">Болгар</option>
              <option value="Серби">Серби</option>
              <option value="Хорват">Хорват</option>
              <option value="Босни ба Херцеговина">Босни ба Херцеговина</option>
              <option value="Словени">Словени</option>
              <option value="Словак">Словак</option>
              <option value="Север Македони">Север Македони</option>
              <option value="Албани">Албани</option>
              <option value="Кипр">Кипр</option>
              <option value="Исланд">Исланд</option>
              <option value="Мальта">Мальта</option>
              <option value="Люксембург">Люксембург</option>
              <option value="Лихтенштейн">Лихтенштейн</option>
              <option value="Монако">Монако</option>
              <option value="Андорра">Андорра</option>
              <option value="Сан-Марино">Сан-Марино</option>
              <option value="Ватикан">Ватикан</option>
              <option value="Катар">Катар</option>
              <option value="Кувейт">Кувейт</option>
              <option value="Оман">Оман</option>
              <option value="Бахрейн">Бахрейн</option>
              <option value="Ливан">Ливан</option>
            </select>
          </div>
        </div>
        <div class="max-w-sm">
          <form>
            <div class="block">
              <input
                type="file"
                onChange={handleImageChange}
                class="block w-full text-sm text-gray-500
        file:me-4 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-semibold
        file:bg-green-600 file:text-white
        hover:file:bg-green-700
        file:disabled:opacity-50 file:disabled:pointer-events-none
        dark:text-neutral-500
        dark:file:bg-green-600
        dark:hover:file:bg-green-500
      "
              />
            </div>
          </form>
        </div>
        <p class="text-xs text- text-gray-700 dark:text-gray-300 mt-2">
          SVG, PNG, JPG or GIF (MIN. 1920x1080px)
        </p>
        <div>
          Preview
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Image Preview"
              className="w-full h-72 md:h-96 lg:w-[50%] lg:h-96 object-cover rounded-lg"
            />
          )}
        </div>
        {/* <button
          class="block w-full text-sm text-white
       me-4 py-2 px-4
       rounded-lg border-0 font-semibold
   bg-green-600 
        hover:bg-green-700
        dark:bg-green-600
        dark:hover:bg-green-500
        mt-4
      "
          onClick={uploadFile}
          type="button"
        >
          {" "}
          Upload Image
        </button> */}
        {/* <img src={imgV} alt="" className="w-full lg:h-[80vh] object-cover" /> */}
        {/* <div className="flex flex-wrap gap-2">
        {imageUrls.map((url) => {
          return <img className="w-96 h-60 object-cover" src={url} />;
        })}
      </div> */}
        <button
          className="block w-full text-sm text-white me-4 py-2 px-4 rounded-lg border-0 font-semibold bg-green-600 hover:bg-green-700 dark:bg-green-600 dark:hover:bg-green-500 mt-4"
          type="button" // Set type to "button" to prevent default form submit behavior
          onClick={(e) => {
            e.preventDefault(); // Prevent form submission (reloading)
            console.log(
              nameV === "",
              previewUrl === "",
              disV === "",
              typeV === "",
              prV === "",
              moV === "",
              schV === "",
              selectedtype === "",
              plV === "",
              conV === "",
              loV === "",
              ageV === ""
            );

            if (
              (nameV === "",
              previewUrl === "",
              disV === "",
              typeV === "",
              prV === "",
              moV === "",
              schV === "",
              selectedtype === "",
              plV === "",
              conV === "",
              loV === "",
              ageV === "")
            ) {
              alert("Please fill in all fields");
            } else {
              dataRetriever();
            }
          }}
        >
          Done
        </button>
      </form>
    </div>
  );
};
